import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import GetApiCall from "../../helpers/GetApi";
import GetSeoData from "../../helpers/GetSeoData";
import "./Faq.css";
export default function Faq() {
    const [Faq, setFaq] = useState([]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        GetApiCall.getRequest("GetFAQS").then((results) => {
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setFaq(obj.data)
                }
            });
        });
    }, [])
    return (
        <div className="blank-section px-lg-3">
            <GetSeoData type="Faq" id={null} />
            <div className="container-fluid">
                <div className="row section-padding py-4 disclaimer-box">
                <div className="heading-style mb-3">
                <h1 className="main-heading mb-0">
                FAQ's
                </h1>
                <h3 className="mb-0">We have answered few of the commonly asked questions</h3>
              </div>
                    
                    <div className="col-md-12">
                        {Faq?.map((data) => {
                            return (
                                <Collapse
                                    items={[
                                        {
                                            key: data.fld_id,
                                            label: <h6 className="mb-0">{data.fld_question}</h6>,
                                            children: data.fld_answer,
                                        },
                                    ]}
                                >
                                </Collapse>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AboutUsSection from "../components/AboutUsSection/AboutUsSection";
import BestSeller from "../components/BestSeller/BestSeller";
import CategorySectionGrid from "../components/Category/CategorySectionGrid/CategorySectionGrid";
import CategoryWithBanner from "../components/Category/CategoryWithBanner/CategoryWithBanner";
import FooterPolicy from "../components/FooterPolicy/FooterPolicy";
import HomeMainBanner from "../components/HomeMainBanner/HomeMainBanner";
import ParallaxSection from "../components/ParallaxSection/ParallaxSection";
import { store } from "../context/store";
import PostApiCall from "../helpers/Api";
import GetApiCall from "../helpers/GetApi";
import GetSeoData from "../helpers/GetSeoData";

export default function Home() {
  const [homePageSetup, setHomePageSetup] = useState([]);
  // const [bestSeller, setBestSeller] = useState([]);
  // const [category1Data, setCategory1Data] = useState([]);
  const [category2Data, setCategory2Data] = useState([]);
  // const [category3Data, setCategory3Data] = useState([]);
  const { category4Data, setCategory4Data, category3Data, setCategory3Data } = useContext(store);
  const [mainBanner, setMainBanner] = useState([]);
  const [mainRightBanner, setMainRightBanner] = useState([]);
  // const [homepageBanner2, setHomepageBanner2] = useState([]);
  // const [homepageBanner3, setHomepageBanner3] = useState([]);
  const [parallax1Banner, setParallax1Banner] = useState([]);
  const [parallax2Banner, setParallax2Banner] = useState([]);
  const [productCatList, setProductCatList] = useState([]);
  const [categoryBanner, setCategoryBanner] = useState([]);
  useEffect(() => {
    GetApiCall.getRequest("GetHomepageSetup").then((resultdes) =>
      resultdes.json().then((obj) => {
        setHomePageSetup(obj.data);
        var Order = localStorage.getItem("OrderData");
        var Orderdetails = JSON.parse(Order);
        var orderid = 0;
        if (localStorage.getItem("OrderData") !== null) {
          orderid = Orderdetails[0].order_id
        }
        obj.data
          .filter((value) => value.fld_section_name == "Category2")
          .map((filtereddata) => {
            PostApiCall.postRequest(
              {
                sectionname: filtereddata.fld_section_name,
                itemchoice: filtereddata.fld_item_choice,
                orderid: orderid
              },
              "GetWebsiteHomePageItems"
            ).then((results) => {
              results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                  // ;
                  setCategory2Data(obj.data);
                }
              });
            });
          });
        obj.data
          .filter((value) => value.fld_section_name == "Category3")
          .map((filtereddata) => {
            PostApiCall.postRequest(
              {
                sectionname: filtereddata.fld_section_name,
                itemchoice: filtereddata.fld_item_choice,
                orderid: orderid
              },
              "GetWebsiteHomePageItems"
            ).then((results) => {
              results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                  setCategory3Data(obj.data);
                }
              });
            });
          });
        obj.data
          .filter((value) => value.fld_section_name == "Category4")
          .map((filtereddata) => {
            PostApiCall.postRequest(
              {
                sectionname: filtereddata.fld_section_name,
                itemchoice: filtereddata.fld_item_choice,
                orderid: orderid
              },
              "GetWebsiteHomePageItems"
            ).then((results) => {
              results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                  setCategory4Data(obj.data);
                }
              });
            });
          });
      })
    );
    getBanners();
  }, []);

  useEffect(() => {
    PostApiCall.postRequest({}, "GetFeaturedProductCategories").then(
      (results) =>
        results.json().then((obj1) => {
          if (results.status == 200 || results.status == 201) {
            setProductCatList(obj1?.data)
          }
        })
    );
  }, [])
  const getBanners = () => {
    GetApiCall.getRequest("GetBannersV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          var filterBanner = obj1.data.filter(
            (item) => item.location === "Main"
          );
          var Parallax1Banner = obj1.data.filter(
            (item) => item.location === "Parallax"
          );
          var Parallax2Banner = obj1.data.filter(
            (item) => item.location === "Parallax2"
          );
          var RightMainBanner = obj1.data.filter(
            (item) => item.location === "MainRight"
          );
          var CatBanner = obj1.data.filter(
            (item) => item.location === "CategoryBanner"
          );
          setParallax1Banner(Parallax1Banner)
          setParallax2Banner(Parallax2Banner)
          setMainBanner(filterBanner);
          setMainRightBanner(RightMainBanner)
          setCategoryBanner(CatBanner)
        }
      })
    );
  };
  return (
    <>
      <GetSeoData type="Home" id={null} />
      <HomeMainBanner
        banners={mainBanner}
        rightBanner={mainRightBanner} />
      <AboutUsSection />
      
      {category3Data.length > 0 && homePageSetup
        ?.filter(
          (data) =>
            data.fld_section_name === "Category3" && data.fld_status == "Active"
        )
        .map((section) => {
          return (
            <div className=" mt-2 heading-style mb-0">
              <div className="d-flex text-center text-lg-start justify-content-center justify-content-lg-between align-items-lg-end px-lg-4 pb-2">
                <div>
                  <h2 className="main-heading">{section.fld_headline1}</h2>
                  <h3 className="mb-0">{section.fld_headline2}</h3>
                </div>
                {/* <Link to='ct/beverages/1/1' className="theme-btn d-none d-lg-block">
                  View All
                </Link> */}
                <a href="ct/beverages/1/1" className="theme-btn d-none d-lg-block">View All</a>
              </div>
              <CategorySectionGrid
                categoryData={category3Data}
                banners={categoryBanner}
              />
              <div className="text-center mb-4 pb-2 mt-2 d-block d-lg-none">
                {/* <Link to="ct/beverages/1/1" className="theme-btn">
                  View All
                </Link> */}
                <a href="ct/beverages/1/1" className="theme-btn">View All</a>
              </div>
            </div>
          );
        })}
      {/* {homePageSetup
        ?.filter(
          (data) =>
            data.fld_section_name === "Featured Products" &&
            data.fld_status == "Active"
        )
        .map((section) => {
          return (
            <div className="text-center my-4 heading-style">
              <h2>{section.fld_headline1}</h2>
              <h3>{section.fld_headline2}</h3>
              <BestSeller
                className="best-seller-card"
                categoryData={bestSeller} />
            </div>
          );
        })} */}
      <ParallaxSection banners={parallax1Banner} />
      {category4Data.length > 0 &&homePageSetup
        ?.filter(
          (data) =>
            data.fld_section_name === "Category4" && data.fld_status == "Active"
        )
        .map((section) => {
          return (
            <div className=" my-4 heading-style">
              <div className="d-flex text-center text-lg-start justify-content-center justify-content-lg-between align-items-lg-end px-lg-4 pb-4">
                <div>
                  <h2 className="main-heading">{section.fld_headline1}</h2>
                  <h3 className="mb-0">{section.fld_headline2}</h3>
                </div>
                <a href="ct/snacks/1/2" className="theme-btn d-none d-lg-block">View All</a>
              </div>
              <BestSeller
                className="col-md-6 col-lg-3 px-lg-2 mb-4 mb-lg-0"
                categoryData={category4Data}
              />
              <div className="text-center mb-4 pb-2 mt-2 d-block d-lg-none">
                <a href="ct/snacks/1/2" className="theme-btn">View All</a>
              </div>
            </div>
          );
        })}

      {homePageSetup
        ?.filter(
          (data) =>
            data.fld_section_name === "Category2" && data.fld_status == "Active"
        )
        .map((section) => {
          return (
            <div className="mt-4 mb-lg-4 heading-style mb-0">
              <CategoryWithBanner />
            </div>
          );
        })}
      <ParallaxSection banners={parallax2Banner} />
      <FooterPolicy />
    </>
  );
}

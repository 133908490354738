import { Collapse, Image } from "antd";
import { useEffect } from "react";
import asset10 from "../../assets/Images/gallery-distributers/asset-10.webp";
import asset2 from "../../assets/Images/gallery-distributers/asset-2.webp";
import asset3 from "../../assets/Images/gallery-distributers/asset-3.webp";
import asset4 from "../../assets/Images/gallery-distributers/asset-4.webp";
import asset5 from "../../assets/Images/gallery-distributers/asset-5.webp";
import asset6 from "../../assets/Images/gallery-distributers/asset-6.webp";
import asset7 from "../../assets/Images/gallery-distributers/asset-7.webp";
import asset8 from "../../assets/Images/gallery-distributers/asset-8.webp";
import asset9 from "../../assets/Images/gallery-distributers/asset-9.webp";
import GetSeoData from "../../helpers/GetSeoData";
import DistributorForm from "../DistributorForm/DistributorForm";
import DistributorContent from "./DistributorContent";
import "./DistributorsPage.css";

export default function DistributorsPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const itemsOne = [
    {
      key: "1",
      label: "Kotagiri",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Nilgiris</p>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: "Chennai",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Arakonam</p>
            <p>Egmore</p>
            <p>Mountroad</p>
            <p>Royapet</p>
            <p>Tripican</p>
            <p>Saidapet</p>
            <p>Mylapore</p>
            <p>Mandaveli</p>
            <p>Santhom</p>
            <p>T.Nagar</p>
            <p>Arumbakkam</p>
            <p>Aminjikarai</p>
            <p>Nanadhanam</p>
            <p>Alwarpet</p>
            <p>Nungam</p>
            <p> Pakkanm </p>
            <p>Kodampakkam </p>
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: "Cuddalore",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Chidhambaram</p>
            <p>Panruti</p>
            <p>Cuddalore</p>
            <p>Kurinjipadi</p>
            <p>Kattumannarkoil</p>
            <p>Chidambaram</p>
          </div>
        </>
      ),
    },
    {
      key: "4",
      label: "Coimbatore",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Sulur</p>
            <p>Coimbatore North</p>
            <p>Pollachi</p>
            <p>Udhagamandalam</p>
            <p>Coimbatore south</p>
          </div>
        </>
      ),
    },
    {
      key: "5",
      label: "Cuddalore",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Chidhambaram</p>
            <p>Panruti</p>
            <p>Cuddalore</p>
            <p>Kurinjipadi</p>
            <p>Kattumannarkoil</p>
            <p>Chidambaram</p>
          </div>
        </>
      ),
    },
    {
      key: "6",
      label: "Dharmapuri",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Virudhachalam</p>
            <p>Cuddalore</p>
            <p>Pappireddipatti</p>
          </div>
        </>
      ),
    },
    {
      key: "7",
      label: "Dindigul",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Kodaikanal</p>
            <p>Dindugul</p>
            <p>Nilakottai</p>
            <p>Vedasandur</p>
            <p>Kodaikanal</p>
            <p>Attur</p>
          </div>
        </>
      ),
    },
  ];
  const itemsTwo = [
    {
      key: "1",
      label: "Erode",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>sathiyamangalam</p>
            <p>Gopichetipalayam</p>
            <p>Perundhurai</p>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: "Kanchipuram",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Kanchipuram</p>
            <p>Thirukalukundram</p>
            <p>Kanchipuram</p>
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: "Karur",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>kulithalai</p>
            <p>Karur</p>
          </div>
        </>
      ),
    },
    {
      key: "4",
      label: "Kanyakumari",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Valunkumaparai</p>
            <p>Ramanputhur</p>
            <p>Nagercoil (meenakshipuram)</p>
            <p>Sengamputhur</p>
            <p>Samythoppu</p>
            <p>Therkuthamaraikulam</p>
            <p>Kollamkodu Beach </p>
          </div>
        </>
      ),
    },
    {
      key: "5",
      label: "Krishnagiri",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Krishnarayapuram</p>
            <p>Houser</p>
          </div>
        </>
      ),
    },
    {
      key: "6",
      label: "Nagapattinam",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Mayiladuthurai</p>
            <p>Nagapattinam</p>
            <p>Vedaranyam</p>
            <p>Tharangambadi</p>
          </div>
        </>
      ),
    },
    {
      key: "7",
      label: "Namakkal",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Namakkal</p>
          </div>
        </>
      ),
    },
  ];
  const itemsThree = [
    {
      key: "1",
      label: "Puthukottai",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Aranthangi</p>
            <p>Pudukottai (CT)</p>
            <p>Alangudi</p>
            <p>Gandarvakkottai</p>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: "Ramnad",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Keelakarai</p>
            <p>Kamuthi</p>
            <p>Ramanad</p>
            <p>Mudukulathur</p>
            <p>Arupukottai</p>
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: "Salem",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Sangakiri</p>
            <p>Yerkadu</p>
            <p>Mecherry</p>
            <p>Mettur</p>
          </div>
        </>
      ),
    },
    {
      key: "4",
      label: "Sivagangai",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Karaikudi</p>
            <p>Devakottai</p>
          </div>
        </>
      ),
    },
    {
      key: "5",
      label: "Tanjore",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Ayyampettai</p>
            <p>Thiruvayaru</p>
            <p>Thanjavur</p>
            <p>Karaikudi</p>
            <p>Devakottai</p>
            <p>Pattukottai</p>
            <p>Thiruvidamarudur</p>
          </div>
        </>
      ),
    },
    {
      key: "6",
      label: "Tenkasi",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Sankarankovil</p>
            <p>Vadakarai</p>
            <p>Achamputhur</p>
            <p>Senthamaram</p>
            <p>Veerasigamani</p>
            <p>Thirumalai koil</p>
            <p>Pambuli</p>
            <p>Sambavar(Vadakarai)</p>
          </div>
        </>
      ),
    },
    {
      key: "7",
      label: "Theni",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Periyakulam</p>
            <p>Andipatti</p>
          </div>
        </>
      ),
    },
  ];
  const itemsFour = [
    {
      key: "1",
      label: "Thirunelveli",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Ervadi</p>
            <p>Thevarkulam</p>
            <p>Manur</p>
            <p>Ramayanpatti</p>
            <p>KTC Nagar</p>
            <p>Palayankottai</p>
            <p>Thirunelveli Town </p>
            <p>Pettai</p>
            <p>Tirunelveli</p>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: "Thirupur",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Thirupur</p>
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: "Thiruvallur",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Ponneri</p>
            <p>Thiruthani</p>
          </div>
        </>
      ),
    },
    {
      key: "4",
      label: "Thiruvarur",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Neendamangalam</p>
            <p>Thiruthuraipoondi</p>
            <p>Nanilam</p>
            <p>Valangaiman</p>
          </div>
        </>
      ),
    },
    {
      key: "5",
      label: "Trichy",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Thuvakudi</p>
            <p>Thuraiyur</p>
            <p>Lalgudi</p>
            <p>Thiruverumpur</p>
            <p>Srirangam</p>
            <p>Thuraiyur</p>
          </div>
        </>
      ),
    },
    {
      key: "6",
      label: "Tuticorin",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>kovilpatti</p>
            <p>kaayalpatinum</p>
            <p>Vilathikulam</p>
            <p>Tiruchendur</p>
          </div>
        </>
      ),
    },
    {
      key: "7",
      label: "Vellore",
      children: (
        <>
          <b>Town/Taluk</b>
          <div>
            <p>Katpadi</p>
            <p>Katpadi Outer </p>
            <p>Gudiyatham</p>
            <p>Pernampet</p>
            <p>Pallikonda</p>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <GetSeoData type="Distributors" id={null} />
      <div className="px-lg-3 my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="heading-style mb-3">
              <h1 className="main-heading mb-0">Become a Distributor</h1>
              <h3 className="mb-0">
                Partner with Us: Super Stockist, Dealership, Distributorship
                Opportunities
              </h3>
            </div>
            <DistributorContent />
            <div className="col-12 distributors-form distributors-form-box py-4 mb-5">
              <div className="heading-style mb-3">
                <h2 className="main-heading mb-0">
                  Distributor Registration Form
                </h2>
                {/* <h3 className="mb-0">sub</h3> */}
              </div>
              
                <DistributorForm />
              
            </div>
            <div className="heading-style mb-3">
              <h2 className="main-heading mb-0">Distributor Locations</h2>
              <h3 className="mb-0">
                Our Distributors are Currently Available in Following Locations
              </h3>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="distributors-acoordion">
                <Collapse accordion items={itemsOne} />
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="distributors-acoordion">
                <Collapse accordion items={itemsTwo} />
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="distributors-acoordion">
                <Collapse accordion items={itemsThree} />
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="distributors-acoordion">
                <Collapse accordion items={itemsFour} />
              </div>
            </div>
            {/* <div className="col-12">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="table-color">
                    <tr>
                      <th scope="col">District</th>
                      <th scope="col">Town</th>
                      <th scope="col">Taluk</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row" className="table-katagiri">
                        Kotagiri
                      </td>
                      <td></td>
                      <td>Nilgiris</td>
                    </tr>
                    <tr>
                      <td rowspan="16" className="table-info text-white">
                        Chennai
                      </td>
                      <td>Arakonam</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Egmore</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Mountroad</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Royapet</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Tripican</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Saidapet</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Mylapore</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Mandaveli</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Santhom</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>T.Nagar</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Arumbakkam</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Aminjikarai</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Nanadhanam</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Alwarpet</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Nungam Pakkanm</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Kodampakkam</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td rowspan="7" className="coimbatore">
                        Coimbatore
                      </td>
                    </tr>
                    <tr>
                      <td>udhagamandalam</td>
                      <td>Sulur</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Coimbatore North</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Pollachi</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>udhagamandalam</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Coimbatore south</td>
                    </tr>
                    <tr className="mpty">
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td rowspan="4" className="Cuddalore  text-white">
                        Cuddalore
                      </td>
                    </tr>

                    <tr>
                      <td>Chidhambaram</td>
                      <td>Kurinjipadi</td>
                    </tr>

                    <tr>
                      <td>Panruti</td>
                      <td>Kattumannarkoil</td>
                    </tr>

                    <tr>
                      <td>Cuddalore</td>
                      <td>Chidambaram</td>
                    </tr>

                    <tr>
                      <td rowspan="5" className="dharmapuri text-white">
                        Dharmapuri
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Panruti</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Virudhachalam</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Cuddalore</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Pappireddipatti</td>
                    </tr>

                    <tr>
                      <td rowspan="5" className="dindigul text-white">
                        Dindigul
                      </td>
                    </tr>

                    <tr>
                      <td>Kodaikanal</td>
                      <td>Nilakottai</td>
                    </tr>

                    <tr>
                      <td>Dindugul</td>
                      <td>Vedasandur</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Kodaikanal</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Attur</td>
                    </tr>

                    <tr>
                      <td rowspan="3" className="Erode text-white">
                        Erode
                      </td>
                    </tr>

                    <tr>
                      <td>sathiyamangalam</td>
                      <td>Gopichetipalayam</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Perundhurai</td>
                    </tr>

                    <tr>
                      <td rowspan="3" className="Kanchipuram text-white">
                        Kanchipuram
                      </td>
                    </tr>

                    <tr>
                      <td>Kanchipuram</td>
                      <td>Kanchipuram</td>
                    </tr>

                    <tr>
                      <td>Thirukalukundram</td>
                      <td>Thirukalukundram</td>
                    </tr>

                    <tr>
                      <td className="karur text-white">Karur</td>
                      <td>kulithalai</td>
                      <td>Karur</td>
                    </tr>

                    <tr>
                      <td rowspan="8" className="Kanchipuram text-white">
                        Kanyakumari
                      </td>
                    </tr>

                    <tr>
                      <td>Valunkumaparai</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Ramanputhur</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Nagercoil(meenakshipuram)</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Sengamputhur</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Samythoppu</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Therkuthamaraikulam</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Kollamkodu Beach</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td className="karur text-white">Karur</td>
                      <td>kulithalai</td>
                      <td>Karur</td>
                    </tr>

                    <tr>
                      <td rowspan="3" className="Krishnagiri text-white">
                        Krishnagiri
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Krishnarayapuram</td>
                    </tr>

                    <tr>
                      <td>Houser</td>
                      <td>Houser</td>
                    </tr>

                    <tr>
                      <td rowspan="5" className="Nagapattinam text-white">
                        Nagapattinam
                      </td>
                    </tr>

                    <tr>
                      <td>Tharangambadi</td>
                      <td>Mayiladuthurai</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Nagapattinam</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Vedaranyam</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Tharangambadi</td>
                    </tr>

                    <tr>
                      <td className="Namakkal text-white">Namakkal</td>
                      <td>Namakkal</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td
                        rowspan="3"
                        className="dharmapuri text-white
">
                        Puthukottai
                      </td>
                    </tr>

                    <tr>
                      <td>Aranthangi</td>
                      <td>Alangudi</td>
                    </tr>

                    <tr>
                      <td>Pudukottai (CT)</td>
                      <td>Gandarvakkottai</td>
                    </tr>

                    <tr>
                      <td
                        rowspan="5"
                        className="Ramnad text-white
">
                        Ramnad
                      </td>
                    </tr>

                    <tr>
                      <td>Keelakarai</td>
                      <td>Kamuthi</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Ramanad</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Mudukulathur</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Arupukottai</td>
                    </tr>

                    <tr>
                      <td
                        rowspan="5"
                        className="Kanchipuram text-white

">
                        Salem
                      </td>
                    </tr>

                    <tr>
                      <td>Sangakiri</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Yerkadu</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Mecherry</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Mettur</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td
                        rowspan="3"
                        className="Kanchipuram text-white
">
                        Sivagangai
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Karaikudi</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Devakottai</td>
                    </tr>

                    <tr>
                      <td rowspan="7" className="tanjore text-white">
                        Tanjore
                      </td>
                    </tr>

                    <tr>
                      <td>Ayyampettai</td>
                      <td>Thanjavur</td>
                    </tr>

                    <tr>
                      <td>Thiruvayaru</td>
                      <td>Karaikudi</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Devakottai</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Pattukottai</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Thiruvaiyaru</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Thiruvidamarudur</td>
                    </tr>

                    <tr>
                      <td rowspan="9" className="karur text-white">
                        Tenkasi
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Sankarankovil</td>
                    </tr>

                    <tr>
                      <td>Vadakarai</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Achamputhur</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Senthamaram</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Veerasigamani</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Thirumalai koil</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Pambuli</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Sambavar(Vadakarai)</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td rowspan="3" className="Ramnad text-white">
                        Theni
                      </td>
                    </tr>

                    <tr>
                      <td>Periyakulam</td>
                      <td>Andipatti</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Periyakulam</td>
                    </tr>

                    <tr>
                      <td
                        rowspan="9"
                        className="Kanchipuram text-white
">
                        Thirunelveli
                      </td>
                    </tr>

                    <tr>
                      <td>Ervadi</td>
                      <td>Tirunelveli</td>
                    </tr>

                    <tr>
                      <td>Thevarkulam</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Manur</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Ramayanpatti</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>KTC Nagar</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Palayankottai</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Thirunelveli Town</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Pettai</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td className="Namakkal text-white">Thirupur</td>
                      <td>Thirupur</td>
                      <td>Thirupur</td>
                    </tr>

                    <tr>
                      <td
                        rowspan="3"
                        className="dharmapuri text-white
">
                        Thiruvallur
                      </td>
                    </tr>

                    <tr>
                      <td>Ponneri</td>
                      <td>Thiruthani</td>
                    </tr>

                    <tr>
                      <td>Thiruthani</td>
                      <td>Ponneri</td>
                    </tr>

                    <tr>
                      <td rowspan="5" className="Ramnad text-white">
                        Thiruvarur
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Neendamangalam</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Thiruthuraipoondi</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Nanilam</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Valangaiman</td>
                    </tr>

                    <tr>
                      <td rowspan="5" className="Cuddalore text-white">
                        Trichy
                      </td>
                    </tr>

                    <tr>
                      <td>Thuvakudi</td>
                      <td>Lalgudi</td>
                    </tr>

                    <tr>
                      <td>Thuraiyur</td>
                      <td>Thiruverumpur</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Srirangam</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>Thuraiyur</td>
                    </tr>

                    <tr>
                      <td rowspan="3" className="tanjore text-white">
                        Tuticorin
                      </td>
                    </tr>

                    <tr>
                      <td>kovilpatti</td>
                      <td>Vilathikulam</td>
                    </tr>

                    <tr>
                      <td>kaayalpatinum</td>
                      <td>Tiruchendur</td>
                    </tr>

                    <tr>
                      <td rowspan="5" className="Namakkal text-white">
                        Vellore
                      </td>
                      <td>Katpadi</td>
                      <td>Katpadi</td>
                    </tr>

                    <tr>
                      <td>Katpadi Outer</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Gudiyatham</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Pernampet</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Pallikonda</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

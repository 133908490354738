import React from "react";
import ContactForm from "./ContactForm";

export default function ConsumerContent() {
  return (
    <>
      <h5>Customer Feedback / Complaints / Suggestions:</h5>
      <p>
        We value your feedback, complaints, and suggestions as they help us
        improve our products and services to better meet your needs. Please feel
        free to share your thoughts with us using the form below.
      </p>
      <ContactForm />
      <p className="mt-3">
        If you prefer to reach out to us directly, you can contact our customer
        support team: Phone: <a href="tel:+919345946584"> +91 - 93459 46584, </a>
        Email: <a href="mailto:support@rubyfood.com"> support@rubyfood.com </a>
        Our customer support team is available from Monday – Saturday between
        9:30 am to 6:00 pm to assist you.
      </p>
    </>
  );
}

import "./AboutUsSection.css"
// import christmasfive from "../../assets/Images/christmas/christmas-five.png"
// import christmasone from "../../assets/Images/christmas/christmas-one.png"

export default function AboutUsSection() {
  return (
    <div className="px-lg-4 mt-2 pt-1">
      <div className="container-fluid px-lg-3">
        <div className="row aboutus-section py-4  position-relative">
          <div className="col-lg-10 mx-auto text-center">
            <h1 className="main-heading">Enjoy the taste of South Indian Beverages</h1>
            <p>
              From our humble beginnings in 1962, Ruby Foods has become a
              cherished name, delighting taste buds with our authentic South
              Indian beverages. With a focus on quality and taste, we
              continue to expand our reach across Tamil Nadu, Kerala, and
              beyond.
            </p>
          </div>
          {/* <img src={christmasfive} className=" christmas-five" />
          <img src={christmasone} className=" christmas-one" /> */}

        </div>
      </div>
    </div>
  );
}

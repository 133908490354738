import { Drawer } from "antd";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi2";
import { store } from "../../context/store";
import AddToCartQty from "../AddToCartQty/AddToCartQty";
import AddToCart from "../Helpers/AddToCart";
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronLeft />
    </div>
  );
}
export default function RelatedProducts({ itemdata, isInCart }) {
  const [variantImage, setVariantImage] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [productId, setProductId] = useState(null);
  const [variantName, setVariantName] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [variantList, setVariantList] = useState(null);
  const [mrp, setMrp] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [youSave, setYouSave] = useState(null);
  const [savePer, setSavePer] = useState(null);
  const [stock, setStock] = useState(null);
  const [weight, setWeight] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [itemType, setItemType] = useState("");
  const [isOpen, setIsOpen] = useState(false)
  const [open, setOpen] = useState(false);
  const { cartItems, storeStatus } = useContext(store)
  // const [modalSide, setModalSide] = useState("")
  function handleClose() {
    setIsOpen(false);
  }
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });
    try {
      setItemType(itemdata.item_type);
      setItemName(itemdata.fld_variantname);
      setVariantList(itemdata.variant_list);
      setMrp(itemdata.fld_mrp);
      setDiscountPrice(itemdata.fld_website_discount_price);
      setSavePer(itemdata.you_save_per);
      setYouSave(itemdata.you_save_amount);
      setStock(itemdata.fld_stock_in_hand);
      setVariantId(itemdata.fld_variantid);
      setVariantName(itemdata.fld_variantname);
      setWeight(itemdata.weight);
      setCategoryName(itemdata.fld_categoryname);
      setVariantImage(itemdata.VariantImage);
      setProductId(itemdata.fld_productid);
    } catch {
    }
  }, []);

  return (
    <>
      <div className="px-2">
        <div className="item">
          <div className="product-box related-product-box">
            <div className="p-images blog-images position-relative">
              <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${itemdata.fld_itemname.replace(/\s/g, "-")}-${itemdata.fld_variantname.replace(
                    /\s/g,
                    "-"
                  )}/${variantId}`}>
                <img
                  src={variantImage}
                  alt={itemdata.fld_variantname.replace(/\s/g, "-")} className="img-fluid mb-2"></img>
                {itemdata.fld_discount_percent > 0 ? (
                  <span className="offer-label">
                    {itemdata.fld_discount_percent}%
                  </span>
                ) : (
                  ""
                )}
                <span
                  className={itemType == null ? "d-none" : "veg-nonveg-icon"}>
                  <img
                    // src={imageSrc}
                    alt={itemType}
                  />
                </span>
              </a>
            </div>

            <div className="content-box position-relative">
              {/* <span style={{ color: "#00000075" }}>{categoryName}</span> */}

              <p className="product-title2">
                <a
                  href={`${process.env.PUBLIC_URL
                    }/productdetail/${itemdata.fld_itemname.replace(/\s/g, "-")}-${itemdata.fld_variantname.replace(
                      /\s/g,
                      "-"
                    )}/${variantId}`}>
                  {itemdata.fld_itemname}
                </a>
              </p>
              <p onClick={() => {
              }}>
                {itemdata.fld_variantname}
              </p>
              {storeStatus == "Active" &&
                <div className="d-flex mt-2 justify-content-between align-items-end">
                  <p className="price mb-0 fs-5 disc-price">
                    <i className="fas fa-rupee-sign"></i> ₹{" "}
                    {parseFloat(discountPrice).toFixed(0)}
                    {mrp > discountPrice && <del className="ms-2">₹ {mrp}</del>}
                  </p>
                  {isInCart ? (
                    <AddToCartQty
                      itemid={variantId}
                      quantity={isInCart.fld_quantity}
                      cartlineid={isInCart.fld_cart_dtl_id}
                    />
                  ) :
                    <>
                      <button className="theme-btn border-0 py-1 px-2">
                        <AddToCart itemid={variantId} />
                      </button>
                    </>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <Modal show={isOpen} centered onHide={() => handleClose()}>
        <Modal.Header closeButton className="border-0 pb-0">
          <h5 className="mb-0">{itemName}</h5>
        </Modal.Header>
        <Modal.Body className="">
          {/* {itemdata.variant_list.split("#")?.map((dat) => {
            return (
              <div className="variant-modal-card">
                <div className="d-flex align-items-center">
                  <img src={
                    dat.split("$")[1]
                      .split(",")[7]
                      .split("~")[0]} className="img-fluid" />
                  <p>{dat.split("$")[1].split(",")[0]}</p>
                </div>
                <p className="ms-lg-5 ps-lg-4">
                  ₹{dat.split("$")[1].split(",")[3]} {dat.split("$")[1].split(",")[2] > dat.split("$")[1].split(",")[3] && <del>₹{dat.split("$")[1].split(",")[2]}</del>}
                </p>
                <span className="theme-btn cursor-pointer">
                  <AddToCart itemid={dat.split("$")[1].split(",")[1]} />
                </span>
              </div>
            )
          })} */}

        </Modal.Body>
      </Modal>
      <Drawer
        className="variant-drawer-mobile"
        title={itemName}
        onClose={onClose}
        open={open}
        placement="bottom">
        {/* {itemdata.variant_list.split("#")?.map((dat) => {
          const InCart = cartItems.find((item) => {
            if (item.fld_variantid == dat.split("$")[1].split(",")[1]) {
              return item
            }
          });
          return (
            <div className="variant-modal-card">
              <div className="d-flex align-items-center">
                <img src={
                  dat.split("$")[1]
                    .split(",")[7]
                    .split("~")[0]} className="img-fluid" />
                <p>{dat.split("$")[1].split(",")[0]}</p>
              </div>
              <p className="ms-lg-5 ps-lg-4">
                ₹{dat.split("$")[1].split(",")[3]} {dat.split("$")[1].split(",")[2] > dat.split("$")[1].split(",")[3] && <del>₹{dat.split("$")[1].split(",")[2]}</del>}
              </p>
              <span className="theme-btn cursor-pointer">
                {InCart ? (
                  <AddToCartQty
                    itemid={variantId}
                    quantity={InCart.fld_quantity}
                    cartlineid={InCart.fld_cart_dtl_id}
                  />
                ) :
                  <>
                    <AddToCart itemid={dat.split("$")[1].split(",")[1]} />
                  </>
                }
              </span>
            </div>
          )
        })} */}
      </Drawer>
    </>
  );
}

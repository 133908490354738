import { useEffect, useState } from "react";
// import VerifiedIcon from "../assets/img/VerifiedIcon.svg";
import "./ProductReviews.css";
import PostApiCall from "../../helpers/Api";
import Slider from "react-slick";
import LoginModal from "../Login/LoginModal";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import { useHistory, useLocation, Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import VerifiedIcon from "../../assets/Images/VerifiedIcon.svg"

function NextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={className} style={{ ...style }} onClick={onClick}>
			<HiOutlineChevronRight />
		</div>
	);
}

function PrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={className} style={{ ...style }} onClick={onClick}>
			<HiOutlineChevronLeft />
		</div>
	);
}

// this component renders all the reviews for a product
export default function ProductReviews({ variantid, variantimage }) {
	const [reviewData, setReviewData] = useState([]) // all reviews
	const [loading, setLoading] = useState(true)
	const [averageRating, setAverageRating] = useState(null)
	const [openModal, setOpenModal] = useState(false)
	const [customerId, setCustomerId] = useState(null)
	const [isDisabled, setIsDisabled] = useState(false)
	const history = useHistory()
	const [modalSide, setModalSide] = useState("")

	useEffect(() => {
		const logDetails = JSON.parse(localStorage.getItem('CustomerData'))
		if (logDetails && logDetails.length) {
			setCustomerId(logDetails[0].fld_customerid)
		}

		PostApiCall.postRequest(
			{
				whereClause: `where fld_variant_id=${variantid} and fld_status='active' order by fld_review_id desc`,
				recordCount: "Top 5 *",
			},
			"GetReviews"
		).then((results) =>
			results.json().then((obj1) => {
				if (results.status == 200 || results.status == 201) {
					setAverageRating(obj1.data[0]?.avg_rating)
					setLoading(false)
					setReviewData(obj1.data)


					if (obj1.data.length != 0) {
						if (obj1.data.some(customer => customer.fld_customer_id === logDetails[0].fld_customerid)) {
							setIsDisabled(true);
						}
					}
				}
			})
		);
	}, [])



	const handleChangeModalSide = (e) => {
		setModalSide(e)
	}

	const reviewSlider = {
		dots: false,
		infinite: false,
		autoplay: false,
		arrows: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		initialSlide: 1,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const handleToggleModal = () => {
		setOpenModal(false)
	}


	return (
		<>
			<LoginModal isOpen={openModal} handleToggleModal={handleToggleModal} handleChangeModalSide={handleChangeModalSide} />
			<section
				className="section-padding product-reviews mb-4"
				style={{ background: "#fff" }}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<div className="section-title mt-4 text-left">
								<h2 className="text-left section-title-category-section">Ratings and Reviews</h2>
								<div className="title-divider"></div>
							</div>
						</div>
					</div>
					<div className="row">
						<div
							className="col-md-6 col-lg-4 product-reviews"
							style={{
								background: "#fff!important",
							}}>
							<div className="overall-ratings">
								<img
									src={variantimage}
									alt=""
									className="img-fluid"
								/>
								<div className="d-flex flex-column w-100">
									<div className="ratings">
										<strong className="ratings-exact">
											{averageRating ? averageRating : "_"}
										</strong>
										<span className="ratings-upto">/5</span>
									</div>
									<div className="ratings-verified">
										<strong className="ratings-static">Overall Rating</strong>
										<span className="verified-upto">
											{reviewData.length > 0 ? reviewData.length : 0} verified ratings
										</span>
									</div>

									{/* {isDisabled == false && ()} */}

									<div className="">
										<button button
											className="theme-btn border-0"
											onClick={() => {
												var login = localStorage.getItem("CustomerData");
												var logindetails = JSON.parse(login);
												if (logindetails) {
													history.push(`/writereview/${variantid}`)
												} else {
													setOpenModal(true);
												}
											}}>
											Write Review
										</button>
									</div>


								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-8 arrow-section">
							<div>
								{/* products reviews */}
								{reviewData.length > 0 ?
									<Slider {...reviewSlider}>
										{reviewData.map((data) => {
											return (
												<>
													<div
														key={data.fld_review_id}
														className="review-card"
														style={{ background: "#fff!important" }}>

														<div className="star-ratings">
															<section className="css-rwwnk2">
																{data.fld_rating <= 2 ? (
																	<span className="css-1at2way">
																		{data.fld_rating}
																		<i className="css-w0v4um">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="13"
																				height="14">
																				<title>star</title>
																				<path
																					fill="#FFF"
																					d="M2.555 11.878c-.05 0-.1-.025-.15-.05a.277.277 0 01-.1-.275l1.098-4.092L.109 4.79c-.1-.05-.125-.175-.1-.275a.24.24 0 01.225-.174l4.242-.225L5.998.15c.05-.075.15-.15.25-.15s.2.075.225.15l1.522 3.967 4.242.225c.1 0 .2.075.225.174.025.1 0 .2-.075.275L9.093 7.46l1.098 4.092c.025.1 0 .2-.1.275-.075.05-.2.075-.274 0L6.248 9.532l-3.569 2.296c-.05.05-.075.05-.124.05z"></path>
																			</svg>
																		</i>
																	</span>
																) : (
																	<span className="css-1at2wjx">
																		{data.fld_rating}
																		<i className="css-w0v4um">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="13"
																				height="14">
																				<title>star</title>
																				<path
																					fill="#FFF"
																					d="M2.555 11.878c-.05 0-.1-.025-.15-.05a.277.277 0 01-.1-.275l1.098-4.092L.109 4.79c-.1-.05-.125-.175-.1-.275a.24.24 0 01.225-.174l4.242-.225L5.998.15c.05-.075.15-.15.25-.15s.2.075.225.15l1.522 3.967 4.242.225c.1 0 .2.075.225.174.025.1 0 .2-.075.275L9.093 7.46l1.098 4.092c.025.1 0 .2-.1.275-.075.05-.2.075-.274 0L6.248 9.532l-3.569 2.296c-.05.05-.075.05-.124.05z"></path>
																			</svg>
																		</i>
																	</span>
																)}
															</section>
														</div>
														<section className="css-1v6g5ho">
															<h4 className="css-tm4hnq">
																{data.fld_headline}
															</h4>
															<p className="css-1n0nrdk">
																{data.fld_review_description}
															</p>
														</section>
														<div className="userInfoSection">
															<div className="UserInformationProductReviews">
																<span className="userNameReviews">
																	{data.fld_name}
																</span>

																{/* <Link to={{
																	pathname: `/writereview/${data.fld_variant_id}`,
																	state: { reviewId: data.fld_review_id }
																}}>
																<MdEdit />
																</Link> */}
																<span className="verified-buyers-icon">
																	<img
																		src={VerifiedIcon}
																		height="23%"
																		width="23%"></img>
																</span>
															</div>
														</div>
													</div>
												</>
											);
										})}
									</Slider>
									:
									<div className="w-100 text-center mt-5">No Reviews Available</div>}
							</div>
						</div>
					</div>
					{/* {loading || reviewData.length &&
						<div
							className="css-1rjb1jp"
						// style={{
						// 	display:
						// 		loading == true ||
						// 			reviewData.length == 0
						// 			? "none"
						// 			: "block",
						// }}
						>
						</div>
					} */}
				</div >
			</section >
		</>
	);
}


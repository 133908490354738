import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import PostApiCall from "../../helpers/Api";
import "./ProductReviews.css";
export default function Container({ variantId, variantImage, variantName }) {
	const [rating, setRating] = useState(null)
	const [hover, setHover] = useState(null)
	const [headline, setHeadline] = useState(null)
	const [reviewDescription, setReviewDescription] = useState(null)
	const location = useLocation()
	const reviewId = location.state && location.state.reviewId;
	useEffect(() => {
		getReviewById()
	}, [])

	function getReviewById() {
		if (reviewId != undefined || reviewId != null) {
			PostApiCall.postRequest(
				{
					whereClause: `where fld_review_id=${reviewId} `,
					recordCount: "*",
				},
				"GetReviews"
			).then((results) =>
				results.json().then((obj1) => {
					if (results.status == 200 || results.status == 201) {
						setRating(obj1.data[0].fld_rating)
						setHeadline(obj1.data[0].fld_headline)
						setReviewDescription(obj1.data[0].fld_review_description)
					}
				})
			);
		}
	}

	function onPostReview() {
		if (rating != null) {
			if (headline != null) {
				if (reviewDescription != null) {
					Notiflix.Loading.Circle();
					let loginDetails = localStorage.getItem("CustomerData");
					let customerDetails = JSON.parse(loginDetails);
					PostApiCall.postRequest(
						{
							userId: customerDetails[0].fld_customerid,
							variantId: variantId,
							reviewid: reviewId ? reviewId : null,
							rating: rating,
							headline: headline,
							reviewDescription: reviewDescription,
						},
						"AddProductReview"
					).then((result) =>
						result.json().then((obj) => {
							if (result.status == 200 || result.status == 201) {
								Notiflix.Loading.Remove();
								Notiflix.Notify.Success(
									"Your review has been submitted successfully"
								);
								window.history.back();
							} else {
								Notiflix.Loading.Remove();
								Notiflix.Notify.Failure(obj.data);
							}
						})
					);
				} else {
					Notiflix.Notify.Failure("Please enter Description for your review");
				}
			} else {
				Notiflix.Notify.Failure("Please enter Headline for your review");
			}
		} else {
			Notiflix.Notify.Failure("Please give a rating to this product");
		}
	}

	return (
		<>
			<div className="container-fluid px-lg-5">
				<div className="row">
					<div className="col-lg-6 col-12">
						<div className="mt-4 mb-2 pb-2">
							<h4 className="">
								Write a Review
							</h4>
						</div>
						<div className="productForReview">
							<div className="product-reviewImage">
								<img
									src={variantImage}
									height="80px"
									width="80px"></img>
								<div className="variantNameReview mt-2">
									{variantName}
								</div>
							</div>
						</div>
						<hr />
						<div>
							<div className="over-all-rating">
								<div>
									<h4>Overall rating</h4>
								</div>
								<div
									style={{
										display: rating != null ? "block" : "none",
									}}
									className="container-clear"
									onClick={() => {
										setRating(null)
									}}>
									clear
								</div>
							</div>
							<div>
								{[...Array(5)].map((star, i) => {
									const ratingValue = i + 1;

									return (
										<label className="star-review">
											<input
												type="radio"
												value={rating}
												onClick={() => {
													setRating(ratingValue)
												}}
											/>
											<FaStar
												size={35}
												className="star"
												color={
													ratingValue <= (hover || rating)
														? "#FFC107"
														: "#e4e5e9"
												}
												onMouseEnter={() => {
													setHover(ratingValue)
												}}
												onMouseLeave={() => {
													setHover(null)
												}}
											/>
										</label>
									);
								})}
							</div>
						</div>
						<hr />

						<div>
							<div className="over-all-rating">
								<div>
									<h4>Add Headline</h4>
								</div>
							</div>
							<div className="headline">
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										id="formGroupExampleInput"
										placeholder="What's the most important to know?"
										value={headline}
										onChange={(headline) => {
											setHeadline(headline.target.value)
										}}
									/>
								</div>
							</div>
						</div>
						<hr />

						<div>
							<div className="over-all-rating">
								<div>
									<h4>Add a written review</h4>
								</div>
							</div>
							<div className="headline">
								<div className="form-group">
									<textarea
										className="form-control"
										id="exampleFormControlTextarea1"
										rows="5"
										placeholder="What did you like or dislike? What did you use this product for?"
										value={reviewDescription}
										onChange={(reviewDescription) => {
											setReviewDescription(reviewDescription.target.value)
										}}
									/>
								</div>
							</div>
						</div>

						<div className="submit-review text-start">
							<button
								className="theme-btn border-0 mt-3"
								onClick={onPostReview}>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}


import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { AiFillInstagram } from "react-icons/ai";
import { BiSolidUser } from "react-icons/bi";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaPinterestP,
  FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import { IoMdMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { MdShoppingCart } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import GetApiCall from "../../helpers/GetApi";
import LoginModal from "../Login/LoginModal";
import ChangePassword from "../PasswordModal/ChangePassword";
import SocialIcons from "../Wrapper Components/SocialIcons";
import "./MainNavabar.css";
import LogoutModal from "../Helpers/LogoutModal";

export default function MainNavabar() {
  const {
    setcart,
    setcartamount,
    setClientData,
    clientData,
    cart,
    logo,
    setLogo,
    vertical,
    setVertical,
    loggedIn,
    setLoggedIn,
    setSiteHeader,
    setCartItems,
    setStoreStatus,
    siteHeader,
    storeStatus,
  } = useContext(store);
  const history = useHistory();
  const [myAccount, setMyAccount] = useState(false);
  const [loginModalStatus, setLoginModalStatus] = useState(false);
  const [openChangePwd, setOpenChangePwd] = useState(false);
  const [modalSide, setModalSide] = useState("");
  const [showCanvas, setShowCanvas] = useState(false);
  const [canLogout, setCanLogout] = useState(false);

  const getCustomerDetails = () => {
    var loginDetails = JSON.parse(localStorage.getItem("CustomerData"));
    if (loginDetails !== null) {
      setLoggedIn(true);
      getCartdetails();
    }
  };
  useEffect(() => {
    getCustomerDetails();
    GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setLogo(JSON.parse(obj1.data[0].logo).logo[0].url);
          setClientData(obj1.data);
          setSiteHeader(
            JSON.parse(obj1.data[0]?.site_header)?.menu[0]?.subMenu
          );
          setStoreStatus(obj1.data[0].storeStatus);
        }
      })
    );
    PostApiCall.postRequest(
      {
        catCount: "*",
      },
      "GetProductVertical"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setVertical(obj1.data);
        }
      })
    );
  }, []);

  // this function to be called after 2 seconds of logout click
  const onLogout = () => {
    if (localStorage.getItem("CustomerData") !== null) {
      localStorage.removeItem("CustomerData");
      localStorage.removeItem("OrderData");
      setcart(0);
      setcartamount(0);
      history.push("/");
      setLoggedIn(false);
      setCanLogout(false);
      Notiflix.Loading.Remove();
    } else {
      Notiflix.Notify.Failure("Something went wrong");
    }
  };

  const handleShowDropdown = () => {
    setMyAccount(true);
  };

  const handleHideDropdown = () => {
    setMyAccount(false);
  };

  const handleToggleModal = () => {
    setLoginModalStatus(false);
  };
  const handleChangeModalSide = (e) => {
    setModalSide(e);
  };

  const handleTogglePwdModal = () => {
    setOpenChangePwd(false);
  };
  const handleProfileClick = () => {
    history.push("/customerprofile");
  };
  const changePssword = () => {
    setOpenChangePwd(true);
  };
  const handleOpenCart = () => {
    // if (loggedIn) {
    history.push("/cart");
    // } else {
    //   setModalSide("Log");
    //   setLoginModalStatus(true);
    // }
  };

  const getCartdetails = () => {
    Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          setCartItems(res1.data);
          Notiflix.Loading.Remove();
          setcart(res1?.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          // setcartamount(res1.data[0]?.fld_cart_amount_preview);
        }
      });
  };

  const clearItemDetails = () => {
    localStorage.removeItem("itemdetails");
  };

  const handleShowCanvas = () => {
    setShowCanvas(true);
  };
  const handleCloseCanvas = () => {
    setShowCanvas(false);
  };
  const handleToggleLogout = () => {
    setCanLogout(false);
  };
  return (
    <>
      <LoginModal
        isOpen={loginModalStatus}
        handleToggleModal={handleToggleModal}
        modalSide={modalSide}
        handleChangeModalSide={handleChangeModalSide}
      />
      <LogoutModal
        show={canLogout}
        onHide={handleToggleLogout}
        onLogout={onLogout}
      />
      <ChangePassword
        isOpen={openChangePwd}
        handdleToggleModal={handleTogglePwdModal}
      />

      <Navbar expand="lg" className="main-navbar px-lg-3">
        <Container fluid>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg d-lg-none">
            <HiMiniBars3CenterLeft onClick={handleShowCanvas} />
          </Navbar.Toggle>
          <Navbar.Brand href="/" className="me-auto">
            <img src={logo} className="img-fluid nav-logo" />
          </Navbar.Brand>
          <Navbar.Offcanvas
            show={showCanvas}
            onHide={handleCloseCanvas}
            className="d-lg-none main-navbar-offcanvas"
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Link to="/" onClick={handleCloseCanvas}>
                <img src={logo} className="img-fluid nav-logo" />
              </Link>
            </Offcanvas.Header>
            <Offcanvas.Body className="pt-0">
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {/* {vertical?.map((data) => {
                  return <Nav.Link key={data.fld_verticleid} href="#action1">{data.fld_name}</Nav.Link>;
                })} */}
                <Link to="" className="nav-link" onClick={handleCloseCanvas}>
                  Home
                </Link>
                <Link
                  to="/aboutus"
                  className="nav-link"
                  onClick={handleCloseCanvas}
                >
                  About Us
                </Link>

                {siteHeader?.map((data, index) => {
                  return (
                    <li key={index}>
                      <NavDropdown
                        title="Products"
                        id={data.id}
                        renderMenuOnMount={true}
                      >
                        {data.subcategories?.map((subcat) => {
                          return (
                            <Link
                              to={`/ct${subcat.path}`}
                              className="w-100"
                              onClick={handleCloseCanvas}
                            >
                              <NavDropdown.Item
                                key={subcat.id}
                                href={`/ct${subcat.path}`}
                              >
                                {subcat.label1}
                              </NavDropdown.Item>
                            </Link>
                          );
                        })}
                      </NavDropdown>
                    </li>
                  );
                })}
                <Link
                  to="/distributor"
                  className="nav-link"
                  onClick={handleCloseCanvas}
                >
                  Distributors
                </Link>
                <Link
                  to="/faq"
                  className="nav-link"
                  onClick={handleCloseCanvas}
                >
                  FAQ's
                </Link>
                <Link
                  to="/careers"
                  className="nav-link"
                  onClick={handleCloseCanvas}
                >
                  Careers
                </Link>
                <Link
                  to="/gallery"
                  className="nav-link"
                  onClick={handleCloseCanvas}
                >
                  Gallery
                </Link>
                <Link
                  to="/contact-us"
                  className="nav-link"
                  onClick={handleCloseCanvas}
                >
                  Contact Us
                </Link>
              </Nav>
              <div className="d-flex contact-links my-2">
                <IoCall />
                <a href={`tel:${clientData[0]?.mobile}`}>
                  {clientData[0]?.mobile}
                </a>
              </div>
              <div className="d-flex contact-links mb-2">
                <IoMdMail />
                <a href={`mailto:${clientData[0]?.email}`}>
                  {clientData[0]?.email}
                </a>
              </div>
              <p className="mb-0 fw-bold mt-3">Follow us on</p>
              <ul className="contact-links d-flex mt-2">
                <SocialIcons
                  link={clientData[0]?.facebook}
                  target="_blank"
                  logo={<FaFacebookF />}
                />
                <SocialIcons
                  link={clientData[0]?.instagram}
                  target="_blank"
                  logo={<AiFillInstagram />}
                />
                <SocialIcons
                  link={clientData[0]?.twitter}
                  target="_blank"
                  logo={<FaXTwitter />}
                />
                <SocialIcons
                  link={clientData[0]?.linkedin}
                  target="_blank"
                  logo={<FaLinkedinIn />}
                />
                <SocialIcons
                  link={clientData[0]?.pintrest}
                  target="_blank"
                  logo={<FaPinterestP />}
                />
                <SocialIcons
                  link={clientData[0]?.youtube}
                  target="_blank"
                  logo={<FaYoutube />}
                />
              </ul>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          {storeStatus == "Active" && (
            <ul className="main-nav-right-links">
              <li>
                {/* dropdown for desktop */}
                <Dropdown
                  onMouseEnter={handleShowDropdown}
                  onMouseLeave={handleHideDropdown}
                  show={myAccount}
                  className="login-dropdown d-none d-lg-block"
                >
                  <Dropdown.Toggle className="login-icon" id="dropdown-basic">
                    <BiSolidUser />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {loggedIn === false && (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            setModalSide("Log");
                            setLoginModalStatus(true);
                            clearItemDetails();
                          }}
                        >
                          Login
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setModalSide("Reg");
                            setLoginModalStatus(true);
                            clearItemDetails();
                          }}
                        >
                          Sign Up
                        </Dropdown.Item>
                      </>
                    )}
                    {loggedIn === true && (
                      <>
                        <Dropdown.Item onClick={handleProfileClick}>
                          Profile
                        </Dropdown.Item>
                        <Dropdown.Item onClick={changePssword}>
                          Change Password
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setCanLogout(true)}>
                          Logout
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                {/* Dropdown for mobile */}
                <Dropdown className="login-dropdown d-block d-lg-none">
                  <Dropdown.Toggle className="login-icon" id="dropdown-basic">
                    <BiSolidUser />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {loggedIn === false && (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            setModalSide("Log");
                            setLoginModalStatus(true);
                            clearItemDetails();
                          }}
                        >
                          Login
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setModalSide("Reg");
                            setLoginModalStatus(true);
                            clearItemDetails();
                          }}
                        >
                          Sign Up
                        </Dropdown.Item>
                      </>
                    )}
                    {loggedIn === true && (
                      <>
                        <Dropdown.Item onClick={handleProfileClick}>
                          Profile
                        </Dropdown.Item>
                        <Dropdown.Item onClick={changePssword}>
                          Change Password
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setCanLogout(true)}>
                          Logout
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="cursor-pointer" onClick={handleOpenCart}>
                <MdShoppingCart />
                <span>{cart} Items</span>
              </li>
            </ul>
          )}
        </Container>
      </Navbar>
    </>
  );
}

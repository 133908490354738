import Notiflix from 'notiflix';
import { useEffect, useState } from "react";
import { TfiReload } from "react-icons/tfi";
import PostApiCall from "../../helpers/Api";
import GetSeoData from '../../helpers/GetSeoData';
import "./CareersPage.css";


export default function CareersPage() {

  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [position, setPosition] = useState("")
  const [message, setMessage] = useState("")
  const [id, setId] = useState(null)
  const type = "Career"
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\d{10}$/
  const [captcha, setCaptcha] = useState("")
  const [enteredCaptcha, setEnteredCaptcha] = useState("")

  useEffect(() => {
    generateRandomCaptcha();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])

  const generateRandomCaptcha = () => {
    const newCaptcha = Math.random().toString(36).slice(2, 7).toUpperCase();
    setCaptcha(newCaptcha);
  };

  function isEmailValid(email) {
    return emailRegex.test(email)
  }
  function isPhoneValid(num) {
    return phoneRegex.test(num)
  }
  function isCaptchaValid(enteredCaptcha) {
    return (captcha == enteredCaptcha)
  }

  function clearForm() {
    setFullName("")
    setEmail("")
    setMobile("")
    setMessage("")
    setEnteredCaptcha("")
    setPosition("")
  }

  function onValidateForm() {
    if (fullName == "") {
      Notiflix.Notify.Failure("Name is required.")
      return
    }
    if (email == "") {
      Notiflix.Notify.Failure("Email is required.")
      return
    } else if (
      !isEmailValid(email)
    ) {
      Notiflix.Notify.Failure("Invalid Email Address")
      return
    }
    if (mobile == "") {
      Notiflix.Notify.Failure("Mobile Number is required.")
      return
    } else if (!isPhoneValid(mobile)) {
      Notiflix.Notify.Failure("Invalid Mobile Number")
      return
    }
    if (position == "") {
      Notiflix.Notify.Failure("Job position is required.")
      return
    }
    if (message == "") {
      Notiflix.Notify.Failure("Message is required.")
      return
    }
    if (!isCaptchaValid(enteredCaptcha)) {
      Notiflix.Notify.Failure("Invalid Captcha")
      return
    }
    onPost();
  }
  function RubyFoodCareerEnquiry() {
    return (
      '<html><head><meta charset="utf-8"><title>Ruby Food Career</title></head>' +
      '<body style="font-family: sans-serif; background-color: #8080800d">' + '<div style="border: 1px dotted #ee1824">' + '<div style="padding: 20px">' + '<div style="text-align: center;padding: 10px 0px 0px 0px;margin: 0px 10px 0px 10px;"><img style="width: 130px" src="https://store.bechofy.in/images/rubyfood_com_user/ProductImages/932501-logo-1.webp"/></div>'
      + '<p style="text-align: left;padding-bottom: 0;margin-bottom: 7px;margin-top: 0;padding-top: 0;font-size: 15px;color: #000;">Dear Ruby Food,</p><p style="font-weight: 100;padding-top: 0;padding-bottom: 0;margin-top: 0;margin-bottom: 10px;font-size: 15px;color: #000;">'
      + 'We would like to notify you that a new <b>job application</b> has been submitted through your website. The specifics are as follows.</p>' + '<table style="border-collapse: collapse;border-radius: 10px;margin-top: 15px;margin-bottom: 15px;">' + `<tr><td style="border: 1px solid black;padding: 10px 15px;font-size: 15px;color: #000;">Full Name</td><td style="border: 1px solid black;padding: 10px 15px;font-size: 15px;color: #000;">${fullName}</td></tr>` + `<tr><td style="border: 1px solid black;padding: 10px 15px;font-size: 15px;color: #000;">Mobile Number</td><td style="border: 1px solid black;padding: 10px 15px;font-size: 15px;color: #000;">${mobile}</td></tr>` + `<tr><td style="border: 1px solid black;padding: 10px 15px;font-size: 15px;color: #000;">Email</td><td style="border: 1px solid black;padding: 10px 15px;font-size: 15px;color: #000;">${email}</td></tr>` + `<tr>
      <td style="border: 1px solid black;padding: 10px 15px;font-size: 15px;color: #000;">Job Position</td><td style="border: 1px solid black;padding: 10px 15px;font-size: 15px;color: #000;">${position}</td></tr>` +
      `<tr>
      <td style="border: 1px solid black;padding: 10px 15px;font-size: 15px;color: #000;">Message</td><td style="border: 1px solid black;padding: 10px 15px;font-size: 15px;color: #000;">${message}</td></tr>` +
      '</table>' + '<p style="font-weight: 100;padding-top: 0;padding-bottom: 0;margin-top: 10px;margin-bottom: 5px;font-size: 15px;color: #000;"><b>Best Regards,</b></p>' + '<p style="font-weight: 100;padding-top: 0;padding-bottom: 0;margin-top: 5px;margin-bottom: 0;font-size: 15px;color: #000;"><b>Ruby Food Products Pvt Ltd</b></p>' + '<p style="font-weight: 100;padding-top: 0;padding-bottom: 0;margin-top: 0px;margin-bottom: 0;font-size: 15px;color: #000;">A-S-2 INDUSTRIAL ESTATE, K.PUDUR MADURAI,</p>' + '<p style="font-weight: 100;padding-top: 0;padding-bottom: 0;margin-top: 5px;margin-bottom: 3px;font-size: 15px;color: #000;">TAMILNADU - 625 007, INDIA.</p>' + '<p style="font-weight: 100;padding-top: 0;padding-bottom: 0;margin-top: 0;margin-bottom: 0;font-size: 15px;color: #000;"><b>Email: </b><a style="text-decoration: none" href="mailto:sales@rubyfood.com">sales@rubyfood.com</a></p>' +
      '<div style="font-weight: 100;padding-top: 0;padding-bottom: 0;margin-top: 5px; margin-bottom: 5px;font-size: 15px;color: #000;display: flex;"><div style="margin-right: 3px; font-size: 15px; color: #000"><b>Mobile: </b></div>' + '<div style="font-size: 15px; color: #000;"><p style="margin-bottom: 0; padding-bottom: 0; margin-top: 0"><a style="font-size: 15px;color: #000;text-decoration: none;margin-bottom: 0;padding-bottom: 0;" href="tel:9940160145">+91- 99401 60145</a></p>' + '<p style="margin-bottom: 0; padding-bottom: 0; margin-top: 0"><a style="font-size: 15px;color: #000;text-decoration: none;margin-top: 2px;padding-top: 0;" href="tel:9361804366"> +91 - 93618 04366</a></p></div></div>' + '<p style="font-weight: 100;color: #000;font-size: 15px;margin-bottom: 0;padding-bottom: 0;margin-top: 0;"><b>website: </b>https://rubyfood.com/</p></div></div>' + '</body></html>'
    );
  }

  function onPost() {
    const htmlMail = RubyFoodCareerEnquiry();
    Notiflix.Loading.Dots();
    PostApiCall.postRequest({
      mail: htmlMail,
      id: id,
      name: fullName,
      mobile: mobile,
      email: email,
      altmobile: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      product: "",
      mfd: "",
      batchno: "",
      storecity: "",
      storename: "",
      image: "",
      area: "",
      issue: "",
      type: "New Career Enquiry from Ruby Food Website",
      companyname: "",
      country: "",
      message: message,
      position: position,
      turnover: "",
      businesstype: "",
      bussinessYear: "",
      altmobile: "",
      productname: "",
      mfd: "",
      batchno: "",
      storecity: "",

    }, "AddEnquiry").then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.Remove();
          clearForm();
          generateRandomCaptcha()
          Notiflix.Notify.Success("Your form has been submitted successfully.");
        } else {
          Notiflix.Notify.Failure("Something went wrong!")
        }
      })
    );
  }

  return (
    <>
      <GetSeoData type="Careers" id={null} />
      <div className="px-lg-3 my-4">
        <div className="container-fluid">
          <div className="row">

            <div className="col-12">
              <h1 className="text-center main-heading mb-0">Join Our Team</h1>
            </div>
            <div className="col-lg-6">
              <h3>
                Career Opportunities:
              </h3>
              <p>
                We are passionate about producing high-quality healthy drinks. With a focus
                on quality, innovation, and customer satisfaction, we've built a reputation for
                excellence in the industry. Our people, our values and how we work together
                set us apart and make Ruby Foods a special place in itself. . We're dedicated to
                helping our employees grow and succeed. Whether you're just starting your
                career or looking to take the next step, Ruby Food Products Private Limited
                offers opportunities for learning, skill development, and advancement.
              </p>
              <p>
                If you're enthusiastic, dedicated, and ready to make a difference in the food
                industry, then we invite you to explore career opportunities with us.
              </p>
              <p>
                Please send your updated CV to our Human Resources team.
              </p>
              <p>
                Email: <a href="mailto:recruiter@rubyfood.in"> recruiter@rubyfood.in</a>
              </p>
              <p>
                Phone: <a href="tel:+9197915 12484"> +91 97915 12484</a>
              </p>
              <p>
                We look forward to welcoming you to our team and embarking on a journey of
                growth and success together!
              </p>
            </div>
            <div className="col-lg-6 mx-auto distributors-form mt-4">
              <div className="row py-4 px-2">
                <div className="form-group col-lg-6 pb-4">
                  {/* <label className="pb-2">Full Name *</label> */}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name *"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="form-group col-lg-6 pb-4">
                  {/* <label className="pb-2">Email Id *</label> */}
                  <input
                    type="email"
                    className="form-control"
                    placeholder=" Email Id *"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group col-lg-6 pb-4">
                  {/* <label className="pb-2">Mobile No *</label> */}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile Number*"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
                <div className="form-group col-lg-6 pb-4">
                  {/* <label className="pb-2">Company Name *</label> */}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job Position *"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </div>

                <div className="form-group col-lg-12 pb-4 ">
                  {/* <label className="pb-2">Message *</label> */}
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={5}
                    className="form-control"
                    placeholder="Message *"
                  />
                </div>
                <div className="form-group col-lg-6 mb-4 captcha-box pb-0">
                  {/* <label className="pb-2">Country *</label> */}
                  <input
                    type="text"
                    value={captcha}
                    disabled
                    className="form-control"
                    placeholder="Country *"
                  />
                  <div onClick={() => generateRandomCaptcha()}>
                    <TfiReload />
                  </div>
                </div>
                <div className="form-group col-lg-6 mb-4">
                  {/* <label className="pb-2">Country *</label> */}
                  <input
                    type="text"
                    value={enteredCaptcha}
                    onChange={(e) => setEnteredCaptcha(e.target.value.toUpperCase())}
                    className="form-control"
                    placeholder="Enter Captcha *"
                  />
                </div>

                <div className="col-12 ">
                  <button
                    className="theme-btn border-0"
                    onClick={() => onValidateForm()}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import Notiflix from "notiflix";
import PostApiCall from "../../helpers/Api";
import GetApiCall from "../../helpers/GetApi";



export default function EditAddress() {
	const [name, setName] = useState(null);
	const [title, setTitle] = useState(null);
	const [mobile, setMobile] = useState(null);
	const [area, setArea] = useState(null);
	const [flatNo, setFlatNo] = useState(null);
	const [landmark, setLandmark] = useState(null);
	const [countryData, setCountryData] = useState([]);
	const [cityData, setCityData] = useState([]);
	const [stateData, setStateData] = useState([]);
	const [countryId, setCountryId] = useState(0);
	const [stateId, setStateId] = useState(0);
	const [cityId, setCityId] = useState(0);
	const [country, setCountry] = useState(null);
	const [state, setState] = useState(null);
	const [city, setCity] = useState(null);
	const [pincode, setPincode] = useState(null);
	const [addressId, setAddressId] = useState(null);
	const [numRegex] = useState(/^[0-9]*$/);
	const [gstNumber, setGstNumber] = useState(null);

	useEffect(() => {
		Notiflix.Loading.Init({
			svgColor: "#0f3d74",
		});
		if (localStorage.getItem("AddressData") != null) {
			const addressData = JSON.parse(localStorage.getItem("AddressData"))
			setName(addressData.fld_contact_name)
			setTitle(addressData.fld_address_type)
			setMobile(addressData.fld_contact_number)
			setArea(addressData.fld_address_2)
			setFlatNo(addressData.fld_address_1)
			setLandmark(addressData.fld_landmark)
			setCountry(addressData.fld_country)
			setState(addressData.fld_state)
			setCity(addressData.fld_city)
			setPincode(addressData.fld_pincode)


			GetApiCall.getRequest("GetCountry").then((resultdes) =>
				resultdes.json().then((obj) => {
					setCountryData(obj.data)
					if (obj.data.length != 0) {
						setCountryId(
							obj.data.filter(
								(value) => value.label == addressData.CountryName
							)[0].value
						)
					}

					PostApiCall.postRequest(
						{
							countryid: obj.data.filter(
								(value) => value.label == addressData.CountryName
							)[0].value,
						},
						"GetState"
					).then((results) =>
						results.json().then((objstate) => {
							if (results.status == 200 || results.status == 201) {
								if (objstate.data.length != 0) {
									setStateId(
										objstate.data.filter(
											(value) => value.label == addressData.StateName
										)[0].value
									)
									setStateData(objstate.data)
									setState(objstate.data[0].value)
								}

								PostApiCall.postRequest(
									{
										stateid: objstate.data.filter(
											(value) => value.label == addressData.StateName
										)[0].value,
									},
									"GetCity"
								).then((resultscity) =>
									resultscity.json().then((objcity) => {
										if (resultscity.status == 200 || resultscity.status == 201) {
											if (objcity.data.length != 0) {
												setCityId(objcity.data.filter(
													(value) => value.label == addressData.CityName
												)[0].value)
												setCityData(objcity.data)
												setCity(objcity.data[0].value)
											}
										}
									})
								);
							}
						})
					);
				}))
		}
	}, [])
	function onChangeCountry(country) {
		// //console.log(typeof(country.target.value));

		//  //console.log(countryData[parseInt(country.target.value) +1])
		setCountryId(country.target.value)
		setCountry(countryData[parseInt(country.target.value) + 1].label)
		//  this.props.setcountry(countryData[country.target.value - 1].label);

		PostApiCall.postRequest(
			{
				countryid: country.target.value,
				Country:
					countryData[parseInt(country.target.value) + 1].label,
			},
			"GetState"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//  this.props.setstate(obj.data[0].label)
					// //console.log(obj);
					setStateId(obj.data[0].value)
					setState(obj.data[0].label)

					PostApiCall.postRequest(
						{
							stateid: obj.data[0].value,
						},
						"GetCity"
					).then((resultscity) =>
						resultscity.json().then((objcity) => {
							if (resultscity.status == 200 || resultscity.status == 201) {
								setStateData(objcity.data)
								setStateData(obj.data)
								if (objcity.data.length > 0) {
									// this.props.setcity(objcity.data[0].label)
									setCityId(objcity.data[0].value)
									setCity(objcity.data[0].label)
								}
								Notiflix.Loading.Remove();
							}
						})
					);
				}
			})
		);
	}

	function onChangeState(state) {
		//  this.props.setstate(state.target.value)
		setStateId(state.target.value)

		for (var i = 0; i < Object.keys(stateData).length; i++) {
			if (stateData[i].value == state.target.value) {
				// this.props.setstate();
				setState(stateData[i].label)
			}
		}

		PostApiCall.postRequest(
			{
				stateid: state.target.value,
			},
			"GetCity"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					if (obj.data.length > 0) {
						// this.props.setcity(obj.data[0].label)
						setCityData(obj.data)
						setCityId(obj.data[0].value)
						setCity(obj.data[0].label)
					}
					Notiflix.Loading.Remove();
					// this.props.cityData(obj.data)
				}
			})
		);
	}

	function onChangeCity(city) {
		// this.props.setcity(city.target.value)
		setCityId(city.target.value)

		for (var i = 0; i < Object.keys(cityData).length; i++) {
			if (cityData[i].value == city.target.value) {
				setCity(cityData[i].label)
				// this.props.setcity(cityData[i].label);
			}
		}
	}

	function onSaveData() {
		if (title != "") {
			if (name != "") {
				if (mobile != "") {
					if (mobile.length == 10) {
						if (flatNo != "") {
							if (area != "") {
								if (country != "") {
									if (state != "") {
										if (city != "") {
											if (pincode != "") {
												if (pincode.length == 6) {
													onPost();
												} else {
													Notiflix.Notify.failure("Please enter valid pincode");
												}
											} else {
												Notiflix.Notify.failure("Please enter pincode");
											}
										} else {
											Notiflix.Notify.failure("Please enter city");
										}
									} else {
										Notiflix.Notify.failure("Please enter state");
									}
								} else {
									Notiflix.Notify.failure("Please select country");
								}
							} else {
								Notiflix.Notify.failure(
									"Please enter Area/Colony/Street/Sector/Village"
								);
							}
						} else {
							Notiflix.Notify.failure(
								"Please enter Flat/House/Company/Building/Apartment No."
							);
						}
					} else {
						Notiflix.Notify.failure("Please enter valid mobile number");
					}
				} else {
					Notiflix.Notify.failure("Please enter mobile number");
				}
			} else {
				Notiflix.Notify.failure("Please enter your Full Name");
			}
		} else {
			Notiflix.Notify.failure("Please select Address Title");
		}
	}

	function onPost() {
		Notiflix.Loading.Circle();
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		var addressData = JSON.parse(localStorage.getItem("AddressData"));
		////console.log(addressData.fld_address_id);

		PostApiCall.postRequest(
			{
				CUSTOMERID: logindetails[0].fld_customerid,
				addressid: addressData.fld_address_id,
				title: title,
				name: name,
				mobileNo: mobile,
				area: area,
				flatno: flatNo,
				landmark: landmark,
				gstNumber: gstNumber,
				country: countryId,
				state: stateId,
				city: cityId,
				pincode: pincode,
				action: "Update",
				// longitude: longitude,
				// latitude: latitude,
			},
			"AddCustomerAddress"
		).then((result) =>
			result.json().then((obj) => {
				if (result.status == 200 || result.status == 201) {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("Address updated successfully ");
					localStorage.removeItem("AddressData");
					window.location.href = "/customerprofile";
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj.data);
				}
			})
		);
	}

	return (
		<React.Fragment>
			<div className="page-content">
				<Container
					fluid
					style={{ backgroundColor: "#F5F5F5", paddingLeft: "none" }}
				>
					<div className="row">
						<div className="col-12">
							<div className="row addressbookformmobile">
								<Card className="mt-3">
									<Card.Body>
										<Card.Title className="sidebar-heading">
											<h5>Edit Address</h5>
											<div className="title-divider"></div>
										</Card.Title>
										<form>
											<div className="col-12">
												<div className="row">
													<div className="col-md-12 center">
														<div className="form-group">
															<label for="validationCustom01">
																Address Title<span className="mandatory">*</span>
															</label>
															<br />
															<label className="radio-inline">
																<input
																	type="radio"
																	name="optradio"
																	checked={
																		title === "Office"
																			? true
																			: false
																	}
																	onChange={() => {
																		setTitle("Office")
																	}}
																/>{" "}
																Office
															</label>
															<label
																className="radio-inline"
																style={{ marginLeft: "10px" }}
															>
																<input
																	type="radio"
																	name="optradio"
																	checked={
																		title === "Home" ? true : false
																	}
																	onChange={() => {
																		setTitle("Home")
																	}}
																/>{" "}
																Home
															</label>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group mb-3">
															<label for="validationCustom01">
																Full Name<span className="mandatory">*</span>
															</label>
															<input
																type="text"
																className="form-control"
																value={name}
																onChange={(text) => {
																	setName(text.target.value)
																}}
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group mb-3">
															<label for="validationCustom01">
																Mobile Number<span className="mandatory">*</span>
															</label>
															<input
																type="text"
																id="CategoryName"
																className="form-control"
																value={mobile}
																onChange={(mobile) => {
																	if (
																		numRegex.test(
																			mobile.target.value
																		) &&
																		mobile.target.value.length <= 10
																	) {
																		setMobile(mobile.target.value)
																	}
																}}
															/>
														</div>
													</div>
													<div className="col-lg-3 md-12">
														<div className="form-group mb-3">
															<label for="validationCustom01">
																Flat/House/Building No.
																<span className="mandatory">*</span>
															</label>
															<input
																type="text"
																className="form-control"
																value={flatNo}
																onChange={(text) => {
																	setFlatNo(text.target.value)
																}}
															/>
														</div>
													</div>
													<div className="col-lg-3 md-12">
														<div className="form-group mb-3">
															<label for="validationCustom01">
																Area/Street/Sector/Village
																<span className="mandatory">*</span>
															</label>
															<input
																type="text"
																className="form-control"
																value={area}
																onChange={(text) => {
																	setArea(text.target.value)
																}}
															/>
														</div>
													</div>
													<div className="col-lg-3 md-12">
														<div className="form-group mb-3">
															<label for="validationCustom01">Landmark (optional)</label>
															<input
																type="text"
																className="form-control"
																value={landmark}
																onChange={(text) => {
																	setLandmark(text.target.value)
																}}
															/>
														</div>
													</div>
													<div className="col-lg-3 md-12">
														<div className="form-group mb-3">
															<label for="validationCustom01">
																GST Number (optional)
															</label>
															<input
																type="text"
																className="form-control"
																value={gstNumber}
																onChange={(text) => {
																	setGstNumber(text.target.value)
																}}
															/>
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-group mb-3">
															<label for="validationCustom01">
																Country<span className="mandatory">*</span>
															</label>
															<select
																className="custom-select my-1 mr-sm-2"
																id="inlineFormCustomSelectPref"
																name="UserType"
																value={countryId}
																onChange={() => { onChangeCountry() }}
															>
																{countryData.map((schedule) => (
																	<option
																		key={schedule.label}
																		value={schedule.value}
																	>
																		{schedule.label}
																	</option>
																))}
															</select>
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-group mb-3">
															<label for="validationCustom01">
																State<span className="mandatory">*</span>
															</label>
															<select
																className="custom-select my-1 mr-sm-2"
																id="inlineFormCustomSelectPref"
																name="UserType"
																value={stateId}
																onChange={onChangeState}
															>
																{stateData.map((schedule) => (
																	<option
																		key={schedule.label}
																		value={schedule.value}
																	>
																		{schedule.label}
																	</option>
																))}
															</select>
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-group mb-3">
															<label for="validationCustom01">
																City<span className="mandatory">*</span>
															</label>
															<select
																className="custom-select my-1 mr-sm-2"
																id="inlineFormCustomSelectPref"
																name="UserType"
																value={cityId}
																onChange={onChangeCity}
															>
																{cityData.map((schedule) => (
																	<option
																		key={schedule.label}
																		value={schedule.value}
																	>
																		{schedule.label}
																	</option>
																))}
															</select>
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-group mb-3">
															<label for="validationCustom01">
																Pincode<span className="mandatory">*</span>
															</label>
															<input
																type="text"
																id="CategoryName"
																className="form-control my-1 mr-sm-2"
																value={pincode}
																onChange={(pincode) => {
																	if (pincode.target.value.length <= 6) {
																		setPincode(pincode.target.value)
																	}
																}}
															/>
														</div>
													</div>
													<div className={"col-sm-12 col-lg-4"}>
														<button
															type="button"
															style={{ float: "right", marginTop: "5px" }}
															className="btn align-items-center place-order"
															onClick={onSaveData}
														>
															Update Address {"  "}{" "}
															<i
																className="fa fa-bookmark"
																aria-hidden="true"
															></i>
														</button>
													</div>
												</div>
											</div>
										</form>
									</Card.Body>
								</Card>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</React.Fragment>
	);

}


import { Image } from "antd";
import { useEffect } from "react";
import asset10 from "../../assets/Images/gallery-distributers/asset-10.webp";
import asset2 from "../../assets/Images/gallery-distributers/asset-2.webp";
import asset3 from "../../assets/Images/gallery-distributers/asset-3.webp";
import asset4 from "../../assets/Images/gallery-distributers/asset-4.webp";
import asset5 from "../../assets/Images/gallery-distributers/asset-5.webp";
import asset6 from "../../assets/Images/gallery-distributers/asset-6.webp";
import asset7 from "../../assets/Images/gallery-distributers/asset-7.webp";
import asset8 from "../../assets/Images/gallery-distributers/asset-8.webp";
import asset9 from "../../assets/Images/gallery-distributers/asset-9.webp";
import "./GalleryPage.css";
import GetSeoData from "../../helpers/GetSeoData";

export default function GalleryPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <GetSeoData type="Gallery" id={null} />
      <div className="px-lg-3 my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="heading-style mb-3">
              <h1 className="main-heading mb-0">Gallery</h1>
              <h3 className="mb-0">Glimpses of our product distribution</h3>
            </div>

            <Image.PreviewGroup>
              <div className="col-6 col-lg-4 mb-4">
                <Image src={asset2} className="img-fluid" />
              </div>
              <div className="col-6 col-lg-4 mb-4">
                <Image src={asset3} className="img-fluid" />
              </div>
              <div className="col-6 col-lg-4 mb-4">
                <Image src={asset4} className="img-fluid" />
              </div>
              <div className="col-6 col-lg-4 mb-4">
                <Image src={asset5} className="img-fluid" />
              </div>
              <div className="col-6 col-lg-4 mb-4">
                <Image src={asset6} className="img-fluid" />
              </div>
              <div className="col-6 col-lg-4 mb-4">
                <Image src={asset7} className="img-fluid" />
              </div>
              <div className="col-6 col-lg-4 mb-4">
                <Image src={asset8} className="img-fluid" />
              </div>
              <div className="col-6 col-lg-4 mb-4">
                <Image src={asset9} className="img-fluid" />
              </div>
              <div className="col-6 col-lg-4 mb-4">
                <Image src={asset10} className="img-fluid" />
              </div>
            </Image.PreviewGroup>

            
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import ContactForm from "./ContactForm";

export default function InternshipContent() {
  return (
    <a>
      <h5>Internships & Placements:</h5>
      <p>
        We are excited to offer opportunities for students and professionals to
        gain valuable experience and contribute to our team. Whether you're
        seeking an internship to kick start your career or a placement to
        further develop your skills, we have opportunities available for you.
        Participants will have the opportunity to work alongside experienced
        professionals, engage in meaningful projects, and gain insights into our
        industry. We offer a supportive environment where you can learn, grow,
        and make a real impact.
      </p>
      {/* <ContactForm /> */}
      <p className="mt-3">
        If you have any questions or need further assistance regarding our
        Internships & Placements program, please contact our Human Resources
        team.
      </p>
      <p>
        <strong>Email: </strong> <a href="mailto:recruiter@rubyfood.in"> recruiter@rubyfood.in, </a>
      </p>
      <p>
        {/* <strong>Phone: </strong><a href="tel:+919751699950"> +91 - 97516 99950 </a> */}
        <strong className="mt-3">Phone: </strong><a href="tel:+91 97915 12484"> +91 - 97915 12484 </a>
      </p>
      <p>
        We look forward to receiving your application and welcoming you to our
        team!
      </p>
    </a>
  );
}

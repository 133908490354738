import React, { useEffect, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import moment from "moment";
import Notiflix from "notiflix";
import PostApiCall from "../../helpers/Api";
import { DatePicker } from "antd";

export default function EditProfile({ toggleCanvas, customerData }) {
  const [Name, setName] = useState("");
  const [profileData, setProfileData] = useState([]);
  const [Email, setEmail] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [Gender, setGender] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [GenderData] = useState([
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ]);
  const [Image, setImage] = useState("");
  const [NumRegex] = useState(/^[0-9]*$/);
  const [EmailRegex] = useState(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const [GSTIN, setGSTIN] = useState(null);
  let dob;

  useEffect(() => {
    if (customerData) {
      setName(customerData[0].fld_name)
      setEmail(customerData[0].fld_email_address)
      setMobileNo(customerData[0].fld_phone_number)
      setGender(customerData[0].fld_gender)
      setDateOfBirth(customerData[0].fld_dob)
      // setDateOfBirth(moment(customerData[0].fld_dob).toDate());
    }
  }, [])


  const datePickerStyle = {
    ".ant-picker-cell-inner": {
      pointerEvents: "none",
    },
  };

  const onDobChange = (date, dateString) => {
    setDateOfBirth(dateString)
  }

  function onUpdateData() {
    if (Name != "") {
      if (Email != "") {
        if (EmailRegex.test(Email)) {
          if (MobileNo != null) {
            if (MobileNo.length == 10) {
              if (Gender != "") {
                if (DateOfBirth != null) {
                  if (Image != null) {
                    onPostUpdate();
                  } else {
                    Notiflix.Notify.Failure("Please select an Image.");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter Date of Birth.");
                }
              } else {
                Notiflix.Notify.Failure("Please enter Gender.");
              }
            } else {
              Notiflix.Notify.Failure("Please enter valid Mobile Number.");
            }
          } else {
            Notiflix.Notify.Failure("Please enter Mobile Number.");
          }
        } else {
          Notiflix.Notify.Failure("Please enter valid E-mail.");
        }
      } else {
        Notiflix.Notify.Failure("Please enter email.");
      }
    } else {
      Notiflix.Notify.Failure("Please enter your Name.");
    }
  }

  function onPostUpdate() {
    Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var details = JSON.parse(login);

    PostApiCall.postRequest(
      {
        customerid: details[0].fld_customerid,
        name: Name,
        email: Email,
        mobile: MobileNo,
        gender: Gender,
        dob: DateOfBirth,
        presentaddress: null,
        presentcountry: null,
        presentstate: null,
        presentcity: null,
        presentpincode: null,
        status: null,
        updatedon: moment().format("lll"),
        updatedby:
          localStorage.getItem("CustomerData") == null
            ? null
            : details[0].fld_customerid,
        landmark: null,
        // maritalstatus: MarriedStatus,
        gstin: GSTIN,
      },
      "UpdateCustomerV1"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Profile Information Successfully Updated");
          toggleCanvas()
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  }

  return (
    <>{console.log(DateOfBirth)}
      <div className="page-content blank-section mb-lg-5 pb-lg-5">
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <Card.Body className="cardbackground">
                    <div className="section-title mt-3">
                      <h2 className="section-title-category-section main-heading">
                        My Profile
                      </h2>
                    </div>
                    <Row>
                      <Col lg="12" xs="12" md="12">
                        <div className="form mb-4">
                          <div className="row profile-form">
                            <div className="col col-12">
                              <div className="row">
                                <div className="col-lg-12 col-md-9 col-sm-12 col-xm-12">
                                  <div className="row">
                                    <div className="col-lg-4 col-md-12 col-sm-12 col-xm-12 mb-3">
                                      <div className="form-group">
                                        <label for="CategoryName" className="my-1">
                                          Name
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          id="CategoryName"
                                          className="form-control my-1"
                                          name="Name"
                                          value={Name}
                                          onChange={(text) => {
                                            setName(text.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12 col-xm-12 mb-3">
                                      <div className="form-group">
                                        <label for="CategoryName" className="my-1">
                                          Email
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          type="email"
                                          id="CategoryName"
                                          className="form-control my-1"
                                          name="Email"
                                          value={Email}
                                          onChange={(text) => {
                                            setEmail(text.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 col-xm-12 mb-3">
                                      <div className="form-group">
                                        <label for="CategoryName" className="my-1">
                                          Mobile no
                                          <span className="mandatory">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          id="CategoryName"
                                          className="form-control my-1"
                                          value={MobileNo}
                                          onChange={(mobile) => {
                                            if (
                                              NumRegex.test(
                                                mobile.target.value
                                              ) &&
                                              mobile.target.value.length <= 10
                                            ) {
                                              setMobileNo(mobile.target.value);
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12 mb-3">
                                      <div className="form-group">
                                        <label
                                          className="my-1 mr-2"
                                          for="inlineFormCustomSelectPref">
                                          Gender
                                          <span className="mandatory">*</span>
                                        </label>
                                        <select
                                          className="custom-select my-1 mr-sm-2 form-select"
                                          id="inlineFormCustomSelectPref"
                                          value={Gender}
                                          onChange={(text) => {
                                            setGender(text.target.value);
                                          }}>
                                          <option>Select Gender</option>
                                          {GenderData.map((gender) => (
                                            <option
                                              key={gender.value}
                                              value={gender.value}>
                                              {gender.label}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12 mb-3">
                                      <div className="form-group">
                                        <label for="CategoryName" className="my-1">
                                          Date of Birth
                                          <span className="mandatory">*</span>
                                        </label>
                                        <div>
                                          <DatePicker
                                            onChange={onDobChange}
                                            format={"DD/MM/YYYY"}
                                            // defaultValue={moment()}
                                            needConfirm
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12 col-md-4 col-sm-12 col-xm-12 mb-3">
                                  <div className="form-group">
                                    <label className="my-1" for="CategoryName">GSTIN</label>
                                    <input
                                      type="text"
                                      id="CategoryName"
                                      className="form-control my-1"
                                      name="gstin"
                                      value={GSTIN}
                                      onChange={(text) => {
                                        setGSTIN(text.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="theme-btn border-0"
                                    onClick={() => {
                                      onUpdateData();
                                    }}>
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

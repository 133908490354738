import React, { useState, useEffect } from "react";
import PostApiCall from "../../helpers/Api";
import { TfiReload } from "react-icons/tfi";
import Notiflix from "notiflix";

export default function ContactForm() {
  const [fname, setfName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [altMobile, setAltMobile] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [email, setEmail] = useState("");
  const [productName, setProductName] = useState("");
  const [mfd, setMfd] = useState("");
  const [batchNo, setBatchNo] = useState("");
  const [storeCity, setStoreCity] = useState("");
  const [storeName, setStoreName] = useState("");
  const [area, setArea] = useState("");
  const [issue, setIssue] = useState("");
  const [imgData, setImgData] = useState("");
  const [id, setId] = useState(null);
  const [captcha, setCaptcha] = useState("");
  const [enteredCaptcha, setEnteredCaptcha] = useState("");
  const [ftpPath, setFtpPath] = useState("");
  const imgApiUrl = "https://api.bechofy.in/AddImage";

  useEffect(() => {
    generateRandomCaptcha();
  }, []);

  const generateRandomCaptcha = () => {
    const newCaptcha = Math.random().toString(36).slice(2, 7).toUpperCase();
    setCaptcha(newCaptcha);
  };
  function isCaptchaValid(enteredCaptcha) {
    return (captcha == enteredCaptcha)
  }

  const clearForm = () => {
    setfName("");
    setLastName("");
    setAddress("");
    setMobile("");
    setAltMobile("");
    setEmail("");
    setCity("");
    setState("");
    setPincode("");
    setProductName("");
    setMfd("");
    setStoreCity("");
    setStoreName("");
    setIssue("");
    setArea("");
    setImgData("");
  };

  const validateForm = () => {
    if (fname == "") {
      Notiflix.Notify.Failure("Please enter the first name");
      return;
    }
    if (lastName == "") {
      Notiflix.Notify.Failure("Please enter the last name");
      return;
    }
    if (mobile == "") {
      Notiflix.Notify.Failure("PLease enter the mobile number");
      return;
    }
    if (altMobile == "") {
      Notiflix.Notify.Failure("Please enter an alternate mobile number");
      return;
    }
    if (email == "") {
      Notiflix.Notify.Failure("Please enter the email address");
      return;
    }
    if (address == "") {
      Notiflix.Notify.Failure("Please enter the address");
      return;
    }
    if (city == "") {
      Notiflix.Notify.Failure("Please enter the city");
      return;
    }
    if (state == "") {
      Notiflix.Notify.Failure("Please enter the state");
      return;
    }
    if (pincode == "") {
      Notiflix.Notify.Failure("Please enter the pincode");
      return;
    }
    if (productName == "") {
      Notiflix.Notify.Failure("Please enter the product name");
      return;
    }
    if (mfd == "") {
      Notiflix.Notify.Failure("Please enter the MFD");
      return;
    }
    if (batchNo == "") {
      Notiflix.Notify.Failure("Please enter the batch no.");
      return;
    }
    if (storeCity == "") {
      Notiflix.Notify.Failure("Please enter the city ");
      return;
    }
    if (storeName == "") {
      Notiflix.Notify.Failure("Please enter the store name ");
      return;
    }
    if (area == "") {
      Notiflix.Notify.Failure("Please enter the area ");
      return;
    }
    if (issue == "") {
      Notiflix.Notify.Failure("Please enter the issue ");
      return;
    }
    if (!isCaptchaValid(enteredCaptcha)) {
      Notiflix.Notify.Failure("Invalid Captcha")
      return
    }
    onUploadImage();
  };
  // function to - get the selected image data in case of an upload
  const handleSelectPhoto = (e) => {
    e.preventDefault();
    if (e.target.files[0].size < 2000000) {
      const file = e.target.files[0];
      setImgData(file);
    } else {
      Notiflix.Notify.Failure("File too large, upload file less than 300 kb.");
    }
  };

  function RubyFoodContactEnquiry() {
    return (
      '<html><head><meta charset="utf-8"><title>Ruby Food Contact</title></head>' +
      '<body style="font-fam ily: sans-serif">' +
      '<div style="border: 1px dotted #ee1824;background-color: #8080800d;padding: 20px;">' +
      '<div style="text-align: center;padding: 10px 0px 0px 0px;margin: 0px 10px 0px 10px;">' +
      '<img style="width: 130px" src="https://store.bechofy.in/images/rubyfood_com_user/ProductImages/932501-logo-1.webp"/>' +
      "</div>" +
      '<h4 style="text-align: left;padding-bottom: 0;margin-bottom: 7px;margin-top:0;padding-top:0;font-size:15px;color:#000;text-transform: capitalize">Dear Ruby Food, </h4>' +
      '<table><tr> <td style="text-align: justify;font-size:15px;color:#000"> You have received an enquiry from the website. Below are the details:</td></tr>' +
      '<tr><td style="padding-top: 7px;font-size:15px;color:#000;text-transform: capitalize"><b>Name: </b>' +
      fname + " " +
      lastName +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000;'><b>Phone / Mobile: </b>" +
      mobile +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000;text-transform: capitalize'><b>City Name: </b>" +
      city +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000;text-transform: capitalize'><b>State Name: </b>" +
      state +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000'><b>Address: </b>" +
      address +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000;'><b>Alternate Mobile Number: </b>" +
      altMobile +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000;text-transform: capitalize'><b>Product Name: </b>" +
      productName +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000'><b>MFD: </b>" +
      mfd +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000'><b>Batch No: </b>" +
      batchNo +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000'><b>Store City : </b>" +
      storeCity +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000'><b>Store Name : </b>" +
      storeName +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000'><b>Area : </b>" +
      area +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000'><b>Issue : </b>" +
      issue +
      "</td></tr></table>" + '<p style="font-size:15px;color:#000;text-transform:capitalize;margin-top:10px;margin-bottom: 0;"><b style="font-size:15px;color:#000;text-transform:capitalize">Thank you</b></p>' +
      "</div></body></html>"
    );
  }

  const handleSubmitForm = (imageData) => {
    const htmlMail = RubyFoodContactEnquiry();
    PostApiCall.postRequest(
      {
        mail: htmlMail,
        id: id,
        name: fname + " " + lastName,
        mobile: mobile,
        email: email,
        altmobile: altMobile,
        address: address,
        city: city,
        state: state,
        pincode: pincode,
        product: productName,
        mfd: mfd,
        batchno: batchNo,
        storecity: storeCity,
        storename: storeName,
        image: imageData ? imageData : "",
        area: area,
        issue: issue,
        type: "New Enquiry from Ruby Food Website",
        companyname: "",
        country: "",
        message: "",
        position: "",
        turnover: "",
        businesstype: "",
        bussinessYear: "",
        altmobile: "",
        productname: "",
        mfd: "",
        batchno: "",
        storecity: "",
      },
      "AddEnquiry"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.Remove();
          clearForm();
          generateRandomCaptcha();
          Notiflix.Notify.Success("Form Submitted Successfully.");
        } else {
          Notiflix.Notify.Failure("Something went wrong!");
        }
      })
    );
  };

  const onUploadImage = () => {
    Notiflix.Loading.Dots("Please wait...");
    if (imgData) {
      const form = new FormData();
      form.append("file", imgData);
      form.append("foldername", "Contact");
      form.append(
        "filename",
        Math.random() * 10000 + "-" + imgData.name.trim().replace(/\s/g, "-")
      );
      fetch(imgApiUrl, {
        method: "POST",
        body: form,
        headers: {
          schema: process.env.REACT_SCHEMA || "rubyfood_com_user",
        },
      }).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            handleSubmitForm(obj.image);
          } else {
            Notiflix.Notify.Failure("Something went wrong!");
          }
        })
      );
    } else {
      handleSubmitForm();
      Notiflix.Loading.Remove();

      // Notiflix.Notify.Success("Testimonial successfully Added.");
      // window.location.href = "/review-list";
    }
  };

  return (
    <div className="distributors-form">
      <div className="row mb-5">
        <div className="col-lg-4 mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="First Name*"
            value={fname}
            onChange={(e) => setfName(e.target.value)}
          />
        </div>

        <div className="col-lg-4 mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Last Name*"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="col-lg-4 mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Mobile Number*"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>
        <div className="col-lg-4 mb-3">
          <input
            type="text"
            className="form-control"
            value={altMobile}
            onChange={(e) => setAltMobile(e.target.value)}
            placeholder="Alternate Mobile Number*"
          />
        </div>
        <div className="col-lg-4 mb-3 me-lg-4">
          <input
            type="text"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email*"
          />
        </div>
        <div className="col-lg-6 mb-3">
          <textarea
            type="text"
            className="form-control h-100"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Address*"
          />
        </div>
        <div className="col-lg-6 mb-3">
          <div className="row">
            <div className="col-lg-12 mb-3">
              <input
                type="text"
                className="form-control"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="City*"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <input
                type="text"
                className="form-control"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="State*"
              />
            </div>
            <div className="col-lg-12 mb-3 mb-lg-0">
              <input
                type="text"
                className="form-control"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                placeholder="Pincode*"
              />
            </div>
          </div>
        </div>

        <label className="mb-2" for="inputPassword">
          WHAT WOULD YOU LIKE TO DO?
        </label>
        <div className="col-lg-3 mb-3">
          <input
            type="text"
            className="form-control"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            placeholder="Product Name*"
          />
        </div>
        <div className="col-lg-3 mb-3">
          <input
            type="text"
            className="form-control"
            value={mfd}
            onChange={(e) => setMfd(e.target.value)}
            placeholder="MFD*"
          />
        </div>
        <div className="col-lg-3 mb-3">
          <input
            type="text"
            className="form-control"
            value={batchNo}
            onChange={(e) => {
              setBatchNo(e.target.value);
            }}
            placeholder="Batch No*"
          />
        </div>
        <div className="col-lg-3">
          <input
            type="text"
            className="form-control"
            value={storeCity}
            onChange={(e) => setStoreCity(e.target.value)}
            placeholder="City*"
          />
        </div>
        <div className="col-lg-6 mb-3">
          <input
            type="text"
            className="form-control"
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
            placeholder="Store Name*"
          />
        </div>

        <div className="col-12 mb-3">
          <lable>
            Please Upload A Picture If Applicable <b>(Max Size 2MB)</b>
          </lable>
          <input
            type="file"
            className="form-control w-auto"
            placeholder="Please Upload A Picture If Applicable*"
            onChange={handleSelectPhoto}
          />
        </div>
        <div className="col-12 mb-3">
          <textarea
            rows="4"
            type="text"
            className="form-control"
            value={area}
            onChange={(e) => setArea(e.target.value)}
            placeholder="Area*"
          />
        </div>
        <div className="col-12 mb-3">
          <label>Issue:</label>
          <textarea
            rows="4"
            cols="50"
            className="form-control"
            placeholder="(The More Specific The Feedback, The Easier It Is For Us To Act Upon. For
						E.G. Product Taste, Color, Breakage, Smell, Issue With Packaging Etc.)"
            value={issue}
            onChange={(e) => {
              setIssue(e.target.value);
            }}
          />
        </div>
        <div className="form-group col-lg-3 mb-4 captcha-box pb-0">
          <input
            type="text"
            value={captcha}
            disabled
            className="form-control"
          />
          <div onClick={() => generateRandomCaptcha()}>
            <TfiReload />
          </div>
        </div>
        <div className="form-group col-lg-3 mb-4">
          <input
            type="text"
            value={enteredCaptcha}
            onChange={(e) => setEnteredCaptcha(e.target.value.toUpperCase())}
            className="form-control"
            placeholder="Enter Captcha *"
          />
        </div>

        <div className="col-12 ">
          <button className="theme-btn border-0" onClick={() => validateForm()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

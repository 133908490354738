import React from "react";
import { Tab, Nav } from "react-bootstrap";
import ConsumerContent from "./ConsumerContent";
import InternshipContent from "./InternshipContent";
import IndustrialVisitContent from "./IndustrialVisitContent";
import "./ContactUs.css";
export default function ContactTabs() {
  return (
    <div className="contact-form-tab">
      <Tab.Container id="left-tabs-example" defaultActiveKey="consumer">
        <Nav
          variant="pills"
          className="d-fle flex-wrap justify-content-start gap-3 mb-3">
          <Nav.Item>
            <Nav.Link eventKey="consumer">Consumer Feedback</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="internship-placement">
              Internships/Placements
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="industrial-visit">Industrial Visit</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="consumer">
            <ConsumerContent />
          </Tab.Pane>
          <Tab.Pane eventKey="internship-placement">
            <InternshipContent />
          </Tab.Pane>
          <Tab.Pane eventKey="industrial-visit">
            <IndustrialVisitContent />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./TopBar.css";
import PostApiCall from "../../helpers/Api";
export default function TopBar() {
  const [keyStrings, setKeyStrings] = useState([]);
  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetKeyStrings"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setKeyStrings(obj1.data);
        }
      })
    );
  }, []);
  return (
    <>
      <div className="container-fluid top-bar">
        <div className="row">
          <div className="col-12 text-center py-2">
            <p className="mb-0 d-none d-lg-block">
              Sip and savor your way to satisfaction with our irresistible treats!
            </p>
            <marquee className="d-block d-lg-none">
              Sip and savor your way to satisfaction with our irresistible treats!
            </marquee>
          </div>
        </div>
      </div>
    </>
  );
}

import { useContext, useState } from "react";
import { FaFacebookF, FaLinkedinIn, FaPinterestP, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { store } from "../../context/store";
import SocialIcons from "../Wrapper Components/SocialIcons";

import { NavDropdown } from "react-bootstrap";
import { AiFillInstagram } from "react-icons/ai";
import "./MainNavabar.css";
export default function MainNavabarMenu() {
  const { vertical, siteHeader, clientData } = useContext(store);
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  return (
    <div className="px-lg-3 border-top border-bottom">
      <div className="container-fluid d-none d-lg-block">
        <div className="row">
          <div className="col-9">
            <ul className="d-flex main-nav-menu-links py-2">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/aboutus">About Us</Link>
              </li>

              {siteHeader?.map((data, index) => {
                return (
                  <li key={index}>
                    <NavDropdown
                      // title={data.label}
                      title="Products"
                      id={data.id}
                      renderMenuOnMount={true}
                      show={show}
                      onMouseEnter={showDropdown}
                      onMouseLeave={hideDropdown}>
                      {data.subcategories?.map((subcat) => {
                        return (
                          <Link to={`/ct${subcat.path}`} className="w-100">
                            <NavDropdown.Item
                              key={subcat.id}
                              href={`/ct${subcat.path}`}>
                              {subcat.label1}
                            </NavDropdown.Item>
                          </Link>
                        );
                      })}
                    </NavDropdown>
                  </li>
                );
              })}
              <li>
                <Link to="/distributor">Distributors</Link>
              </li>
              {/* <li>
                <Link to="/">Story</Link>
              </li> */}
              <li>
                <Link to="/faq">FAQ's</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              {/* <li>
                <NavDropdown
                  title="Kids"
                  id="offcanvasNavbarDropdown-expand-sm">
                  {vertical?.map((data, index) => {
                    return (
                      <NavDropdown.Item key={index}
                        href={`/ct/${data.selected_cat_name
                          ?.toLowerCase()
                          .replace(/\s/g, "-")}/${data.fld_verticleid}/${data?.selected_cat_id
                          }`}>
                        {data.fld_name}
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>
              </li> */}
            </ul>
          </div>
          <div className="col-3">
            <ul className="main-nav-menu-sm-links d-flex justify-content-end py-2">
              <SocialIcons
                link={clientData[0]?.facebook}
                target="_blank"
                logo={<FaFacebookF />}
              />
              <SocialIcons
                link={clientData[0]?.instagram}
                target="_blank"
                logo={<AiFillInstagram />}
              />
              <SocialIcons
                link={clientData[0]?.twitter}
                target="_blank"
                logo={<FaXTwitter />}
              />
              <SocialIcons
                link={clientData[0]?.linkedin}
                target="_blank"
                logo={<FaLinkedinIn />}
              />
              <SocialIcons
                link={clientData[0]?.pintrest}
                target="_blank"
                logo={<FaPinterestP />}
              />
              <SocialIcons
                link={clientData[0]?.youtube}
                target="_blank"
                logo={<FaYoutube />}
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

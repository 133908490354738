import React from "react";
import PageNotFoundImage from "../../assets/Images/pagenotfound/404.jpg";
import { Link } from "react-router-dom";
// import Header from "../Header";
// import Footer from "../Footer";
const PageNotFound = () => {
	return (
		<>
			{/* <Header /> */}
			<div>
				<div className="container">
					<div className="row marginbtm-240">
						<div className="col-md-12">
							<div className="four-o-four mt-4">
								<div className="d-flex justify-content-center">
									<img
										src={PageNotFoundImage}
										className="paymentFail img-fluid h-50"
										alt="Page not found"
										height="550px"
						 				width="550px"
									></img>
								</div>
								<div
									className="center-block mt-3"
									style={{ display: "grid", justifyContent: "center", textAlign: "center" }}
								>
									<p>The page you are looking for is not found.</p>
									<h1>Something's missing.</h1>
									<div className="d-flex justify-content-center">
										{/* <Link to="/" className="butn"> */}

										{/* btn name chnage by laxmi  -----------------------------*/}
										<Link to="/" className="thm-btn itm-btns mt-4 w-auto">
											Go to homepage
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Footer /> */}
		</>
	);
};
export default PageNotFound;

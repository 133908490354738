import { useEffect, useState } from "react";
import "./DistributorForm.css";
import PostApiCall from "../../helpers/Api";
import Notiflix from "notiflix";
import { TfiReload } from "react-icons/tfi";

export default function DistributorForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [companyname, setcompanyname] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [turnover, setTurnOver] = useState("");
  const [bussinessType, setBussinesType] = useState("");
  const [msg, setMsg] = useState("");
  const [id, setiD] = useState(null);
  // const [type, setType] = useState("")
  const url = window.location.pathname;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\d{10}$/;
  const [captcha, setCaptcha] = useState("");
  const [enteredCaptcha, setEnteredCaptcha] = useState("");
  const [bussinessYear, setBussinessYear] = useState("");
  const imgApiUrl = "https://api.bechofy.in/AddImage";

  useEffect(() => {
    generateRandomCaptcha();
  }, []);

  // const getUrlType = () => {
  //   if (url == "/contact-us") {
  //     setType("Contact")
  //   } if (url == "/distributor") {
  //     setType("Distributor")
  //   }
  // }
  const generateRandomCaptcha = () => {
    const newCaptcha = Math.random().toString(36).slice(2, 7).toUpperCase();
    setCaptcha(newCaptcha);
  };

  function isEmailValid(email) {
    return emailRegex.test(email);
  }
  function isPhoneValid(num) {
    return phoneRegex.test(num);
  }

  function isCaptchaValid(enteredCaptcha) {
    return captcha == enteredCaptcha;
  }

  function validateForm() {
    if (name == "") {
      Notiflix.Notify.Failure("Name is required");
      return;
    }
    if (companyname == "") {
      Notiflix.Notify.Failure("Company Name is required");
      return;
    }
    if (email == "") {
      Notiflix.Notify.Failure("Email is required");
      return;
    } else if (!isEmailValid(email)) {
      Notiflix.Notify.Failure("Invalid Email Address");
      return;
    }
    if (mobileNo == "") {
      Notiflix.Notify.Failure("Mobile Number is required");
      return;
    } else if (!isPhoneValid(mobileNo)) {
      Notiflix.Notify.Failure("Invalid Mobile Number");
      return;
    }
    if (city == "") {
      Notiflix.Notify.Failure("City is required");
      return;
    }
    if (state == "") {
      Notiflix.Notify.Failure("State is required");
      return;
    }
    if (pinCode == "") {
      Notiflix.Notify.Failure("Pincode is required");
      return;
    }
    if (msg == "") {
      Notiflix.Notify.Failure("Location and other details are required");
      return;
    }
    if (turnover == "") {
      Notiflix.Notify.Failure("Annual turnover detail is required");
      return;
    }
    if (bussinessType == "") {
      Notiflix.Notify.Failure("Please select a business type");
      return;
    }
    if (bussinessYear == "") {
      Notiflix.Notify.Failure("Pleaase enter the business start year");
      return;
    }
    if (!isCaptchaValid(enteredCaptcha)) {
      Notiflix.Notify.Failure("Invalid Captcha");
      return;
    }
    onPost();
  }

  function RubyFoodCareerEnquiry() {
    return (
      '<html><head><meta charset="utf-8"><title>Ruby Food Distributor</title></head>' +
      '<body style="font-fam ily: sans-serif">' +
      '<div style="border: 1px dotted #ee1824;background-color: #8080800d;padding: 20px;">' +
      '<div style="text-align: center;padding: 10px 0px 0px 0px;margin: 0px 10px 0px 10px;">' +
      '<img style="width: 130px" src="https://store.bechofy.in/images/rubyfood_com_user/ProductImages/932501-logo-1.webp"/>' +
      "</div>" +
      '<h4 style="text-align: left;padding-bottom: 0;margin-bottom: 7px;margin-top:0;padding-top:0;font-size:15px;color:#000;text-transform: capitalize">Dear Ruby Food, </h4>' +
      '<table><tr> <td style="text-align: justify;font-size:15px;color:#000"> You have received an enquiry from the website. The details are as follows.</td></tr>' +
      '<tr><td style="padding-top: 7px;font-size:15px;color:#000;text-transform: capitalize"><b>Name: </b>' +
      name +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000;'><b>Email ID: </b>" +
      email +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000;'><b>Phone / Mobile: </b>" +
      mobileNo +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000;'><b>Company Name: </b>" +
      companyname +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000;'><b>City Name: </b>" +
      city +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000;'><b>State Name: </b>" +
      state +
      "</td></tr>" +
      "<tr><td style='font-size:15px;color:#000;'><b>Pincode: </b>" +
      pinCode +
      "</td></tr>" +
      '<tr><td style="font-size: 15px; color:#000;"><b>Location and other details: </b>' +
      msg +
      "</td></tr>" +
      '<tr><td style="font-size: 15px; color:#000;"><b>Previous Year Turnover: </b>' +
      turnover +
      "</td></tr>" +
      '<tr><td style="font-size:15px;color:#000;"><b>Business Type: </b>' +
      bussinessType +
      "</td></tr>" +
      '<tr><td style="font-size:15px;color:#000;"><b>Business Start Year: </b>' +
      bussinessYear +
      "</td></tr></table>" + '<p style="font-size:15px;color:#000;text-transform:capitalize;margin-top:10px;margin-bottom: 0;"><b style="font-size:15px;color:#000;text-transform:capitalize">Thank you</b></p>' +
      "</div></body></html>"
    );
  }

  const clearForm = () => {
    setName("");
    setMobileNo("");
    setEmail("");
    setCity("");
    setState("");
    setPinCode("");
    setcompanyname("");
    setMsg("");
    setTurnOver("");
    setBussinesType("");
    setBussinessYear("");
    setEnteredCaptcha("");
  };

  function onPost() {
    const htmlMail = RubyFoodCareerEnquiry();
    Notiflix.Loading.Dots();
    PostApiCall.postRequest(
      {
        mail: htmlMail,
        id: id,
        name: name,
        mobile: mobileNo,
        email: email,
        altmobile: "",
        address: "",
        city: city,
        state: state,
        pincode: pinCode,
        product: "",
        mfd: "",
        batchno: "",
        storecity: "",
        storename: "",
        image: "",
        area: "",
        issue: "",
        type: "New Distributor Enquiry from Ruby Food Website",
        companyname: companyname,
        country: "",
        message: msg,
        position: "",
        turnover: turnover,
        businesstype: bussinessType,
        bussinessYear: bussinessYear,
        altmobile: "",
        productname: "",
        mfd: "",
        batchno: "",
        storecity: "",
      },
      "AddEnquiry"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.Remove();
          clearForm();
          generateRandomCaptcha();
          Notiflix.Notify.Success("Form Submitted Successfully.");
        } else {
          Notiflix.Notify.Failure("Something went wrong!");
        }
      })
    );
  }

  return (

    <div className="row distributors-form">
      <div className="form-group col-lg-6 pb-4">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="form-control"
          placeholder="Full Name *"
        />
      </div>
      <div className="form-group col-lg-6 pb-4">
        <input
          type="name"
          value={companyname}
          onChange={(e) => setcompanyname(e.target.value)}
          className="form-control"
          placeholder="Company Name *"
        />
      </div>
      <div className="form-group col-lg-6 pb-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-control"
          placeholder="Email Id *"
        />
      </div>
      <div className="form-group col-lg-6 pb-4">
        <input
          type="text"
          value={mobileNo}
          onChange={(e) => setMobileNo(e.target.value)}
          className="form-control"
          placeholder="Mobile Number*"
        />
      </div>

      <div className="form-group col-lg-4 pb-4">
        <input
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          className="form-control"
          placeholder="City *"
        />
      </div>
      <div className="form-group col-lg-4 pb-4">
        <input
          type="text"
          value={state}
          onChange={(e) => setState(e.target.value)}
          className="form-control"
          placeholder="State *"
        />
      </div>
      <div className="form-group col-lg-4 pb-4">
        <input
          type="text"
          value={pinCode}
          onChange={(e) => setPinCode(e.target.value)}
          className="form-control"
          placeholder="Pincode *"
        />
      </div>

      <div className="form-group col-lg-12 pb-4 ">
        <textarea
          rows={5}
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
          className="form-control"
          placeholder="Location and other details *"
        />
      </div>
      <div className="form-group col-lg-4 col-md-6 pb-4">
        <input
          type="text"
          value={turnover}
          onChange={(e) => setTurnOver(e.target.value)}
          className="form-control"
          placeholder="Annual Turnover For Last Financial Year *"
        />
      </div>
      <div className="form-group col-lg-4 col-md-6 pb-4">
        <select
          value={bussinessType}
          onChange={(e) => {
            setBussinesType(e.target.value);
          }}
          class="form-select">
          <option value="" selected disabled={bussinessType != ""}>
            Business Type*
          </option>
          <option value="Dealer">Dealer</option>
          <option value="Super Stockiest">Super Stockiest</option>
        </select>
      </div>
      <div className="form-group col-lg-4 col-md-6 pb-4">
        <input
          type="text"
          value={bussinessYear}
          onChange={(e) => setBussinessYear(e.target.value)}
          className="form-control"
          placeholder="Year of start of business* *"
        />
      </div>
      <div className="form-group col-lg-6 mb-4 captcha-box pb-0">
        <input
          type="text"
          value={captcha}
          disabled
          className="form-control"
        />
        <div onClick={() => generateRandomCaptcha()}>
          <TfiReload />
        </div>
      </div>
      <div className="form-group col-lg-6 mb-4">
        <input
          type="text"
          value={enteredCaptcha}
          onChange={(e) => setEnteredCaptcha(e.target.value.toUpperCase())}
          className="form-control"
          placeholder="Enter Captcha *"
        />
      </div>

      <div className="col-12 ">
        <button className="theme-btn border-0" onClick={() => validateForm()}>
          Submit
        </button>
      </div>
    </div>

  );
}

import { Input } from "antd";
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { FaListUl, FaLockOpen, FaShare, FaUserEdit } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { GiReceiveMoney } from "react-icons/gi";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import { IoIosNotifications } from "react-icons/io";
import { LuIndianRupee } from "react-icons/lu";
import { MdEdit } from "react-icons/md";
import Slider from "react-slick";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import ProductCard from "../ProductCard/ProductCard";
import AddressBookForm from "./AddressBookForm";
import CustomerOrderList from "./CustomerOrderList";
import "./CustomerProfilePage.css";
import ChangePassword from "../PasswordModal/ChangePassword";
import maleAvatar from "../../assets/Images/maleAvatar.jpg";
import femaleAvatar from "../../assets/Images/femaleAvatar.jpg";
import { Link, useHistory } from "react-router-dom";
import EditProfile from "./EditProfile";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronLeft />
    </div>
  );
}
export default function CustomerProfilePage() {
  const [apiData, setApiData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [showCanvas, setShowCanvas] = useState(false);
  const [tempAddData, setTempAddData] = useState([]);
  const { totalOrders, setcart, setcartamount, setLoggedIn } =
    useContext(store);
  const [openChangePwd, setOpenChangePwd] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [tempCustomerData, setTempCustomerData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    GetProductVertical();
  }, []);
  const GetProductVertical = () => {
    PostApiCall.postRequest(
      {
        sectionname: "Featured Products",
      },
      "GetWebsiteHomePageItems"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setApiData(obj1.data);
        }
      })
    );
  };

  useEffect(() => {
    if (showCanvas != true) {
      getCustomerAddress();
    }
  }, [showCanvas]);

  useEffect(() => {
    if (editAddress != true) {
      GetcustomerDatabyid();
    }
  }, [editAddress]);
  const handleToggleCanvas = () => {
    setShowCanvas(false);
  };

  const GetcustomerDatabyid = () => {
    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);
    Notiflix.Loading.Circle();
    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${CustomerDetails[0].fld_customerid}`,
      },
      "Get_customerData_by_id"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setCustomerData(obj.data);
          Notiflix.Loading.Remove();
        }
      })
    );
  };

  const handleTogglePwdModal = () => {
    setOpenChangePwd(false);
  };
  const handleToggleCustomerProfile = () => {
    setEditAddress(false);
  };

  const onLogout = () => {
    if (localStorage.getItem("CustomerData") !== null) {
      localStorage.removeItem("CustomerData");
      localStorage.removeItem("OrderData");
      setcart(0);
      setcartamount(0);
      history.push("/");
      setLoggedIn(false);
      Notiflix.Loading.Remove();
    } else {
      Notiflix.Notify.Failure("Something went wrong");
    }
  };

  function getCustomerAddress() {
    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);
    Notiflix.Loading.Circle();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_customerid=${CustomerDetails[0].fld_customerid}`,
      },
      "GetCustomerAddress"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setAddressData(obj.data);
          Notiflix.Loading.Remove();
        }
      })
    );
  }

  const productsliderdemo = {
    dots: false,
    infinite: false,
    autoplay: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <ChangePassword
        isOpen={openChangePwd}
        handdleToggleModal={handleTogglePwdModal}
      />
      <section className="section-spacing-x section-spacing-x-mobile py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <div className="profile-card h-100 d-flex flex-column">
                {customerData[0]?.fld_created_on && (
                  <div className="d-flex pb-0 justify-content-end">
                    <p className="mb-0">
                      {" "}
                      <spam className="fw-bold">Customer Since:</spam>{" "}
                      {customerData[0]?.fld_created_on}
                    </p>
                  </div>
                )}
                <div className="d-flex pt-0">
                  <div className="img">
                    <img
                      // src="https://store.bechofy.in/images/Beads_Beauty/ProductImages/215493-No_Image_Available-2.webp"
                      src={
                        customerData.fld_gender == "female"
                          ? femaleAvatar
                          : maleAvatar
                      }
                      className="img-fluid"
                    />
                    {/* <div className="fields">	
									<lable>Date of Birth</lable>
									<p>01-01-2000</p>
									</div> */}
                  </div>
                  <div className="content">
                    <div className="fields">
                      <lable>Name</lable>
                      <p>{customerData[0]?.fld_name}</p>
                    </div>
                    {customerData[0]?.fld_dateofbirth && (
                      <div className="fields">
                        <lable>Date of Birth</lable>
                        <p>{customerData[0]?.fld_name}</p>
                      </div>
                    )}
                    <div className="fields">
                      <lable>Mobile</lable>
                      <p>{customerData[0]?.fld_phone_number}</p>
                    </div>
                    <div className="fields">
                      <lable>Email</lable>
                      <p>{customerData[0]?.fld_email_address}</p>
                    </div>
                  </div>
                </div>
                <div className="btns mt-auto">
                  <button
                    onClick={() => {
                      setEditAddress(true);
                      setTempCustomerData(customerData);
                    }}
                  >
                    <FaUserEdit /> Edit
                  </button>
                  <button onClick={() => setOpenChangePwd(true)}>
                    <FaLockOpen /> Change Password
                  </button>
                  <button
                    onClick={() => {
                      onLogout();
                    }}
                  >
                    <FiLogOut /> Logout
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row h-100">
                <div className="col-lg-3 my-4 my-md-0">
                  <div className="four-card">
                    <div className="head">
                      <div className="icon">
                        <FaListUl />
                      </div>
                      Orders
                    </div>
                    <div className="fields">
                      <lable>No. of Orders:</lable>
                      <p>{totalOrders ? totalOrders.total_order : 0}</p>
                    </div>
                    {totalOrders && (
                      <div className="fields">
                        <lable>Last Ordered on:</lable>
                        <p>{totalOrders.fld_order_date}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="four-card">
                    <div className="head">
                      <div className="icon">
                        <GiReceiveMoney />
                      </div>
                      My Savings
                    </div>
                    <div className="fields">
                      <lable>Spent: </lable>
                      <p>
                        <LuIndianRupee />{" "}
                        {totalOrders
                          ? parseFloat(totalOrders?.TotalSalesValue).toFixed(2)
                          : 0}
                      </p>
                    </div>
                    <div className="fields">
                      <lable>Saved: </lable>
                      <p>
                        <LuIndianRupee />{" "}
                        {totalOrders
                          ? parseFloat(totalOrders?.total_discount).toFixed(2)
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3">
									<div className="four-card">
										<div className="head">
											<div className="icon">
												<FaShare />
											</div>
											Share (3/10)
										</div>
										<div className="my-3">
											<Input placeholder="Name" />
										</div>
										<div className="mb-3">
											<Input placeholder="Mobile" />
										</div>
										<div className="mb-3">
											<Input placeholder="Email" />
										</div>
										<button className="theme-btn border-0 px-2 py-1">
											Submit
										</button>
									</div>
								</div> */}
                {/* <div className="col-lg-3">
									<div className="four-card">
										<div className="head">
											<div className="icon">
												<IoIosNotifications />
											</div>
											Notification
										</div>
										<div className="notification-card">
											<p>Explore our world of</p>
											<p>Explore our world of</p>
											<p>Explore our world of</p>
											<p>Explore our world of</p>
											<p>Explore our world of</p>
											<p>Explore our world of</p>
											<p>Explore our world of</p>
											<p>Explore our world of</p>
										</div>
									</div>
								</div> */}
              </div>
            </div>
            <CustomerOrderList />

            {/* Address Listing */}
            <div className="col-12 mb-4">
              <div className="d-flex justify-content-between mb-4 align-items-end">
                <h2 className="mb-0">Address</h2>
                <button
                  onClick={() => {
                    setShowCanvas(true);
                  }}
                  className="theme-btn border-0"
                >
                  Add Address
                </button>
              </div>

              {addressData.length ? (
                <Slider {...productsliderdemo} className="add-address-slider">
                  {addressData.map((address, index) => {
                    return (
                      <div className="px-3 h-100">
                        <div
                          key={index}
                          className="address-card position-relative h-100"
                        >
                          <p>
                            {address.fld_address_1}, {address.fld_address_2},{" "}
                            <br />
                            {address.CityName}, {address.StateName} <br />{" "}
                            {address.fld_pincode}, {address.CountryName}
                          </p>
                          <p>{address.fld_landmark}</p>
                          <span
                            onClick={() => {
                              setTempAddData(address);
                              setShowCanvas(true);
                            }}
                          >
                            <MdEdit />
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              ) : (
                <p className="text-center my-lg-5 py-lg-5">No Address Found</p>
              )}
            </div>

            {/* FBI listing */}
            <div className="col-12 mb-4">
              <h2 className="mb-4">Frequently Bought Items</h2>
              <Slider {...productsliderdemo}>
                {apiData.map((val) => {
                  return (
                    <div key={val.fld_variantid} className="px-3">
                      <ProductCard itemdata={val} />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <Offcanvas
        show={showCanvas}
        onHide={handleToggleCanvas}
        className="address-offcanvas"
        id="offcanvasNavbar-expand-lg"
        aria-labelledby="offcanvasNavbarLabel-expand-sm"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <h4 className="section-title-category-section mt-0">
            Add New Address
          </h4>
          {/* <img src={logo} className="img-fluid nav-logo" /> */}
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <AddressBookForm
            toggleCanvas={handleToggleCanvas}
            address={tempAddData}
          />
        </Offcanvas.Body>
      </Offcanvas>

      {/* Canvas to edit profile data */}
      <Offcanvas
        show={editAddress}
        onHide={handleToggleCustomerProfile}
        className="address-offcanvas"
        id="offcanvasNavbar-expand-lg"
        aria-labelledby="offcanvasNavbarLabel-expand-sm"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <h4 className="section-title-category-section mt-0">Edit Profile</h4>
          {/* <img src={logo} className="img-fluid nav-logo" /> */}
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <EditProfile
            toggleCanvas={handleToggleCustomerProfile}
            customerData={tempCustomerData}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

import { Collapse } from "antd";
import axios from "axios";
import parse from "html-react-parser";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import { BiSolidOffer } from "react-icons/bi";
import { BsCreditCard2BackFill } from "react-icons/bs";
import { CiViewList } from "react-icons/ci";
import { FaHome } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GiSuitcase } from "react-icons/gi";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { IoIosArrowDown, IoIosArrowUp, IoMdCart } from "react-icons/io";
import { IoCheckmarkDone, IoLocationSharp } from "react-icons/io5";
import { MdEditLocationAlt } from "react-icons/md";
import { RiCoupon2Fill, RiCoupon2Line, RiEBike2Line } from "react-icons/ri";
import { useHistory } from 'react-router-dom';
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import GetApiCall from "../../helpers/GetApi";
import GetSeoData from "../../helpers/GetSeoData";
import AddToCartQty from "../AddToCartQty/AddToCartQty";
import AddressBookForm from "../CustomerDashboard/AddressBookForm";

import "./Cart.css";
export default function Cart() {
  const calculateDates = () => {
    const today = new Date();
    const dates = [];
    for (let i = 0; i < 5; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);
      dates.push(date);
    }
    return dates;
  };
  // const [cartData, setCartData] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [productCart, setProductCart] = useState([]);
  const [check, setCheck] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [tempDelState, setTempDelState] = useState(null)
  const [billingAddress, setBillingAddress] = useState(null);
  const [COD, setCOD] = useState("No");
  const [cartID, setCartID] = useState(null);
  const [couponCard, setCouponCard] = useState(false);
  const [offerData, setOfferData] = useState([]);
  const [couponCode, setCouponCode] = useState(null);
  const [couponId, setCouponId] = useState(null);
  const [offerErrorCode, setOfferErrorCode] = useState("");
  const [offerErrorMessage, setOfferErrorMessage] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [showCouponMoreInformation, setShowCouponMoreInformation] =
    useState("");
  const [enteredCouponCode, setEnteredCouponCode] = useState(null);
  const [couponErrorCodeTextBox, setCouponErrorCodeTextBox] = useState(null);
  const [offerErrorMessageTextBox, setOfferErrorMessageTextBox] = useState("");
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState([]);
  const [addInstructions, setAddInstructions] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [dates, setDates] = useState(calculateDates());
  // const [cartAmount, setCartAmount] = useState(0);
  const [showCanvas, setShowCanvas] = useState(false);
  const [stickyTop, setStickyTop] = useState(false);
  const [paymentTrxNum, setPaymentTrxNum] = useState("");
  const [addIstructIndex, setAddIstructIndex] = useState(null);
  const [timeSlots] = useState([
    { label: "09:00 AM - 12:00 PM", value: "9am-12pm" },
    { label: "03:00 PM - 06:00 PM", value: "3pm-6pm" },
  ]);
  const [canDeliver, setCanDeliver] = useState(true)
  const [deliveryErrorMsg, setDeliveryErrorMsg] = useState("")

  const history = useHistory();
  const { logo, cart, setcart, cartamount, setcartamount, clientData, cartData, setCartData, storeStatus } = useContext(store);
  const handleCloseCanvas = () => {
    setShowCanvas(false)
  }
  const handleToggle = () => {
    setCouponCard(false);
    setCouponErrorCodeTextBox(null);
    setOfferErrorMessage("");
    setEnteredCouponCode(null);
  };

  useEffect(() => {
    Notiflix.Loading.Circle();
    getCartdetails();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    GetApiCall.getRequest("GetOfferList").then((resultdes) =>
      resultdes.json().then((obj) => {
        setOfferData(
          obj.data.filter((value) => value.fld_showonwebsite == "Active")
        );
      })
    );
  }, []);
  useEffect(() => {
    if (showCanvas != true) {
      getCustomerAddress();
    }
  }, [showCanvas])

  const getCustomerAddress = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    if (login != null || login != undefined) {

      PostApiCall.postRequest(
        {
          whereClause: `where fld_customerid=${logindetails[0].fld_customerid}`,
        },
        "GetCustomerAddress"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            setAddressData(obj.data);
            Notiflix.Loading.Remove();
          }
        })
      )
    }

  }

  /////////////////////////////////////// handlers //////////////////////////////////////////////////////////////////
  const getCartdetails = () => {
    Notiflix.Loading.Circle();
    getCartwebsite();
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      toggleVisible()
    });
  }, [])

  const getCartwebsite = () => {
    Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    ).then((result) => {
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          setcart(obj?.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          // setcartamount(
          //   obj.data.length == 0 ? 0 : obj.data[0].fld_cart_amount_preview
          // );
          if (obj.data.length > 0) {
            // const filteredCoupons = offerData.filter(
            //   (coupon) => coupon.fld_offerid !== obj.data[0]?.fld_coupon_id
            // );

            // Use find instead of filter to get a single coupon
            // const selectedCoupon = offerData.find(
            //   (coupon) => coupon.fld_offerid === obj.data[0]?.fld_coupon_id
            // );

            // Update state with the new coupons array
            // const hasSelectedCoupon = !!selectedCoupon;
            // setOfferData(
            //   (hasSelectedCoupon ? [selectedCoupon] : []).concat(
            //     filteredCoupons.map((coupon, index) => ({
            //       ...coupon,
            //       selectedCoupon: hasSelectedCoupon && index === 0,
            //     }))
            //   )
            // );
            setCartData(obj.data);
            setCartID(obj.data[0].fld_cart_id);
            setCouponId(obj.data[0].fld_offerid);
            setCouponCode(obj.data[0].fld_code);
            setcartamount(obj.data[0].fld_cart_amount);
            setPaymentTrxNum(obj.data[0].fld_payment_trx_num);
            Notiflix.Loading.Remove();
          } else {
            Notiflix.Loading.Remove();
          }
        }
      });
    });
  };

  const removeCoupon = (cartid, couponid) => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    Notiflix.Loading.Circle();
    PostApiCall.postRequest(
      {
        orderid: cartid,
        couponid: couponid,
        stateid: localStorage.getItem("ChangedPincode"),
        customerid:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
        status: "remove",
      },
      "ApplyOffer"
    ).then((result) =>
      result.json().then((obj3) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Coupon removed successfully");
          setOfferErrorCode("");
          setOfferErrorMessage("");
          getCartdetails();
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj3.data);
        }
      })
    );
  };

  const handleConfirmAddress = () => {
    if (shippingAddress == "" || shippingAddress == null) {
      Notiflix.Notify.Failure("Please select your shipping address")
      return
    } if (billingAddress == "" || billingAddress == null) {
      Notiflix.Notify.Failure("Please select your billing address")
      return
    }
    handlePlaceOrder()
  }

  const handlePlaceOrder = () => {
    // Notiflix.Loading.Circle();
    const det = localStorage.getItem("CustomerData");
    const details = JSON.parse(det);
    PostApiCall.postRequest(
      {
        cartid: cartID,
        billingaddress: billingAddress,
        shippingaddress: shippingAddress,
        deliverydate: selectedDate,
        deliverytime: selectedTimeSlot,
      },
      "UpdateCartAddAddress"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // Notiflix.Notify.Success("Order placed successfully")
          handlePayment(cartamount);
          Notiflix.Loading.Remove();
          // window.location.href = "/order_placed";
        }
      })
    );
  };

  // pay to razorpay
  const handlePayment = async (amount) => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    // e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.bechofy.in/rubyfoods-api/payment",
        { amount }
      );
      const { order } = response.data;
      var options = {
        key: process.env.REACT_PAYMENT_KEY || "rzp_live_QhCWtMX98l0gJk", // Enter the Key ID generated from the Dashboard
        amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: process.env.REACT_NAME || "Ruby Foods", //your business name
        description: "Ruby Foods",
        image: logo,
        order_id: order.id, // Pass the `id` obtained in the response of Step 1
        handler: (response) => {
          const body = {
            ...response,
          };

          fetch(
            "https://api.bechofy.in/rubyfoods-api/RazorPayPaymentStatus",
            {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((validateRes) => validateRes.json())
            .then((jsonRes) => {
              axios
                .post(
                  "https://api.bechofy.in/rubyfoods-api/RazorPayResponse",
                  {
                    cartid: cartID,
                    customerid:
                      localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0].fld_customerid,
                    amount: cartamount,
                    productinfo: "",
                    firstname:
                      localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0].fld_name,
                    email:
                      localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0].fld_email,
                    mihpayid: jsonRes.status.id,
                    requestid: jsonRes.status.order_id,
                    txnamount: jsonRes.status.amount,
                    errorcode: jsonRes.status.error_code,
                    paymentsource: jsonRes.status.method,
                    errormsg: jsonRes.status.error_description,
                    shippingaddress: shippingAddress,
                    billingaddress: billingAddress,
                    status: jsonRes.status.status,
                  }
                )
                .then((obj) => {
                  if (obj.data.message === true) {
                    // Redirect to payment success page
                    handleSendNotification(jsonRes.status.order_id)
                    history.push(`/paymentsuccess/${paymentTrxNum}`)
                    // window.location.href = `/paymentsuccess/${paymentTrxNum}`;
                  } else {
                    // Redirect to payment failure page
                    // window.location.href = `/paymentfail/${paymentTrxNum}`;
                  }
                });
              // window.location.href = "/paymentsuccess"
            })
            .catch((error) => {
              console.error("Error fetching payment status:", error);
            });
        },
        prefill: {
          //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
          name:
            localStorage.getItem("CustomerData") == null
              ? null
              : logindetails[0].fld_name, //your customer's name
          email:
            localStorage.getItem("CustomerData") == null
              ? null
              : logindetails[0].fld_email,
          // contact: "9000000000", //Provide the customer's phone number for better conversion rates
        },
        notes: {
          cartid: cartID,
          paymentTrxNum: paymentTrxNum,
        },
        theme: {
          color: "#880c0a",
        },
      };

      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        // Razorpay script is loaded, you can now use window.Razorpay
      };
      document.head.appendChild(script);
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error(error);
    }
  };
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    var screenWidth = window.innerWidth;

    if (scrolled > 100 && screenWidth > 992) {
      setStickyTop(true);
    } else if (scrolled <= 0) {
      setStickyTop(false);
    }
  };

  const handleSendNotification = (orderid) => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    PostApiCall.postRequest(
      {
        orderNumber: orderid,
        customerName: logindetails[0]?.fld_name,
        amount: cartamount,
        // cartData[0]?.currency_code + " " + cartData.length != 0 &&
        //   cartData[0]?.fld_cart_amount_no_shipping != null
        //   ? cartData[0]?.fld_cart_amount_no_shipping.toFixed(2)
        //   : 0.0,
        location: "",
        orderTime: "",
        title: "Hi, you have a new order",
        body: `${logindetails[0]?.fld_name} placed an order`,
      },
      "SendAndroidNotification"
    ).then((result) =>
      result?.json().then((obj3) => {
        if (result.status == 200 || result.status == 201) {
          // addAddress();
          // updateOrderStatus();
          // getCart();
          Notiflix.Notify.Success(
            "Your order has been placed successfully"
          );
          // handlepaymentModalClose();
          // handleClose();
          // navigate('/payment-successful');
        } else {
          Notiflix.Notify.Failure(
            "Oops!",
            "Something went wrong",
            "OK"
          );
        }
      })
    );
  }

  return (
    <>
      <GetSeoData type="Cart" id={null} />
      {/* <section className="inner-banner">
				<div className="main-banner mobileinner-banner">
					<img
						// src={isMobile ? CheckoutMobileBanner : CheckoutBanner}
						alt="inner-banner"
						className="img-responsive"
					/>
				</div>
			</section> */}
      {cart > 0 ? (
        <div className="container-fluid px-lg-4 my-4">
          <div className="row">
            <div className="col-lg-7">
              <Collapse
                className="w-100 cartcheckout-accordion"
                accordion
                items={[
                  {
                    key: "1",
                    label: (
                      <>
                        <div className="d-flex align-items-center"><span className="cart-accordian-icons"><IoMdCart /></span>
                          <h4 className="main-heading me-3 mb-0">My Cart</h4>
                          <p className="mb-0 theme-btn px-2 ms-auto me-3 py-1 mb-0">Total {cart} items</p>
                        </div>

                      </>
                    ),
                    children: (
                      <div className="checkout-bx pb-0">
                        {cartData?.length > 0
                          ? cartData.map((product, index) => (
                            <div className="position-relative">
                              <div className="row position-relative mb-4 py-3 pb-md-0 rounded w-100 m-0">
                                <div className="col-4 col-md-2">
                                  <div className="row">
                                    <div className="col-12">
                                      <a
                                        href={`${process.env.PUBLIC_URL
                                          }/productdetail/${product.fld_itemname.replace(/\s/g, "-")}-${product.fld_variantname.replace(
                                            /\s/g,
                                            "-"
                                          )}/${product.fld_variantid}`}>
                                        <img
                                          src={product.VariantImage}
                                          className="checkout-img mb-lg-3"></img>
                                        <span
                                          className={
                                            product.item_type == null
                                              ? "d-none"
                                              : "veg-nonveg-icon"
                                          }></span>
                                      </a>
                                    </div>
                                    {/* total quantity */}

                                  </div>
                                </div>

                                <div className="col-8 col-md-10">
                                  <div className="row">
                                    <div className="col-md-6 cproduct-name cproduct-card-name">
                                      <span className="d-block">
                                        {product.fld_categoryname}
                                      </span>
                                      <a
                                        className="fw-bold"
                                        href={`${process.env.PUBLIC_URL
                                          }/productdetail/${product.fld_itemname.replace(/\s/g, "-")}-${product.fld_variantname.replace(
                                            /\s/g,
                                            "-"
                                          )}/${product.fld_variantid}`}>
                                        {product.fld_itemname}{"-"}{product.fld_variantname}
                                      </a>
                                      <div className="c-price">
                                        <span className="d-flex mt-2">
                                          <div className="prod-price me-2 mb-0 pb-0">
                                            ₹{" "}
                                            {parseFloat(
                                              product.fld_website_discount_price *
                                              product.fld_quantity
                                            ).toFixed(2)}
                                          </div>
                                          {product.fld_mrp > product.fld_website_discount_price && <s>
                                            {" "}
                                            ₹{" "}
                                            {parseFloat(
                                              product.fld_mrp *
                                              product.fld_quantity
                                            ).toFixed(2)}
                                          </s>}
                                        </span>
                                      </div>
                                      <div className=" d-none d-md-block cquantity mt-md-3">
                                        <AddToCartQty
                                          itemid={product.fld_variantid}
                                          quantity={product.fld_quantity}
                                          cartlineid={product.fld_cart_dtl_id}
                                        />
                                      </div>
                                    </div>

                                    {/* <div className="col-md-1 col-1 delete-cart-card-btn">
                                      <div
                                        onClick={() => {
                                          Notiflix.Loading.Circle();
                                          var login =
                                            localStorage.getItem(
                                              "CustomerData"
                                            );
                                          var logindetails =
                                            JSON.parse(login);

                                          PostApiCall.postRequest(
                                            {
                                              cartID: product.fld_cart_id,
                                              customerid:
                                                localStorage.getItem(
                                                  "CustomerData"
                                                ) == null
                                                  ? null
                                                  : logindetails[0]
                                                    .fld_customerid,
                                              cartlineid:
                                                product.fld_cart_dtl_id,
                                              stateid:
                                                localStorage.getItem(
                                                  "ChangedPincode"
                                                ),
                                            },
                                            "DeleteCart"
                                          ).then((result) =>
                                            result.json().then((obj3) => {
                                              // console.log(obj3.data);

                                              if (
                                                result.status == 200 ||
                                                result.status == 201
                                              ) {
                                                // window.location.reload()

                                                Notiflix.Loading.Remove();
                                                Notiflix.Notify.Success(
                                                  "Product is successfully deleted."
                                                );
                                                if (productCart.length == 0) {
                                                  // console.log('0')
                                                  localStorage.removeItem(
                                                    "OrderData"
                                                  );
                                                  //  this.getCartdetails();
                                                }
                                                getCartdetails();
                                              } else {
                                                Notiflix.Notify.Failure(
                                                  obj3.data
                                                );
                                              }
                                            })
                                          );
                                        }}>
                                        <span className="cursor-pointer fs-24">
                                          <MdDeleteForever />
                                        </span>
                                      </div>
                                    </div> */}
                                    {/* <div className="add-instructions-box col-md-7 d-none d-md-block">
                                      <TextArea
                                        placeholder="Add Instruction"
                                        className="add-instructions-input"
                                        value={
                                          instructions
                                            ? instructions[index] ||
                                            product.fld_instructions
                                            : ""
                                        }
                                        onChange={(e) => {
                                          const instructionsLocal = [
                                            ...instructions,
                                          ];
                                          instructionsLocal[index] =
                                            e.target.value;
                                          setInstructions(instructionsLocal);
                                        }}
                                        autoSize={{
                                          minRows: 4,
                                          maxRows: 16,
                                        }}
                                      />
                                      <div className="mt-2 text-end">
                                        <button
                                          type="button "
                                          className="theme-btn border-0"
                                          // onClick={() => {
                                          // 	UpdateInstructions(
                                          // 		index,
                                          // 		product.fld_cart_dtl_id
                                          // 	);
                                          // }}
                                          data-toggle="tooltip"
                                          data-placement="right"
                                          title="Add Instruction">
                                          <span>Add Instruction</span>
                                        </button>
                                      </div>
                                    </div>
                                    <div className="add-instructions-box add-instructions-box-active w-100 d-block d-md-none text-end">
                                      {!addInstructions && (
                                        <button
                                          type="button "
                                          className="btn add-instructions mb-0"
                                          onClick={() => {
                                            setCartID(
                                              product.fld_cart_dtl_id
                                            );
                                            setAddIstructIndex(index);
                                            setInstructions(
                                              product.fld_instructions
                                            );
                                            // showaddInstructionModal();
                                          }}
                                          data-toggle="tooltip"
                                          data-placement="right"
                                          title="Add Instruction">
                                          <FaPlusCircle />
                                          <span className="ms-2">
                                            Add Instruction
                                          </span>
                                        </button>
                                      )}
                                    </div> */}
                                  </div>
                                </div>
                                <div className="col-12 d-block d-md-none cquantity">
                                  <div className="quantity">
                                    <AddToCartQty
                                      itemid={product.fld_variantid}
                                      quantity={product.fld_quantity}
                                      cartlineid={product.fld_cart_dtl_id}
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))
                          : ""}
                      </div>
                    ),
                  },
                  {
                    key: "2",
                    label:
                      <div className="d-flex align-items-center"><span className="cart-accordian-icons"><IoLocationSharp /></span>
                        <h4 className="main-heading">Shipping Address</h4>
                      </div>,
                    children: (
                      <>
                        <div className="col-12 d-flex justify-content-between align-items-center p-2 ">
                          <div className="w-100 p-2 d-flex justify-content-between">

                            <h4>My Address List</h4>
                            <button
                              type="button"
                              className="theme-btn border-0 py-1"
                              onClick={() => {
                                setShowCanvas(true)
                              }}>
                              Add New Address
                            </button>
                          </div>
                        </div>
                        {/* display the existing addresses*/}
                        <div className="col-12">
                          <div className="row m-0 w-100">
                            {addressData.length > 0 ?
                              <>
                                {addressData.map((data, index) => (
                                  <>
                                    <div className="Col-12">
                                      <div className="boxes">
                                        <div className="d-flex">
                                          <div className="p-2 bg-light text-center gap-2 pe-3 d-flex">

                                            <input
                                              type="radio"
                                              checked={
                                                selectedAddress.fld_address_id ===
                                                  data.fld_address_id
                                                  ? true
                                                  : false
                                              }
                                              onChange={() => {
                                                setSelectedAddress(data);
                                                setShippingAddress(
                                                  data.fld_address_id
                                                );
                                                setTempDelState(data.fld_state)
                                                {
                                                  const det = localStorage.getItem("CustomerData");
                                                  const details = JSON.parse(det);
                                                  PostApiCall.postRequest(
                                                    {
                                                      customerid:
                                                        details[0].fld_customerid,
                                                      orderid: cartID,
                                                      addressid: data.fld_address_id,
                                                    },
                                                    "AddShippingChrg"
                                                  ).then((results) =>
                                                    results.json().then((obj) => {
                                                      if (
                                                        results.status == 200 ||
                                                        results.status == 201
                                                      ) {
                                                        getCartwebsite();
                                                        setCanDeliver(true)
                                                      }
                                                      else {
                                                        // Notiflix.Notify.Failure(obj.data)
                                                        setDeliveryErrorMsg(obj.data)
                                                        setCanDeliver(false)
                                                      }
                                                    })
                                                  );
                                                }
                                              }} />
                                            <div>
                                              {data.fld_address_type == "Home" ?
                                                <span className="cart-address-icons"><FaHome /></span>
                                                : data.fld_address_type == "Office" ?
                                                  <span className="cart-address-icons"><GiSuitcase /></span>
                                                  :
                                                  <span className="cart-address-icons"><FaLocationDot /></span>
                                              }
                                              <p className="mb-0 fs-6">
                                                {data.fld_address_type}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="p-2">

                                            <p>
                                              <b>{data.fld_contact_name}</b>
                                            </p>
                                            <p>
                                              {data.fld_contact_number}

                                            </p>
                                            <p>
                                              {data.fld_address_1},{" "}
                                              {data.fld_address_2},{" "}
                                              ,{data.CityName} {data.fld_landmark},
                                              {data.StateName} - {data.fld_pincode}{" "}
                                              ,{data.CountryName} </p>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </>
                                )
                                )}
                              </> : <p className="text-center">No Address Found</p>}

                          </div>
                        </div>
                      </>
                    ),
                  },
                  {
                    key: "3",
                    label:
                      <div className="d-flex align-items-center"><span className="cart-accordian-icons"><MdEditLocationAlt /></span>
                        <h4 className="main-heading">Billing Address</h4>
                      </div>,
                    children: (
                      <>
                        <div className="row p-2">
                          <div className="col-12">
                            <div className="d-flex justify-content-between p-lg-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <h4>My Address List</h4>
                                  <div className="d-flex">
                                    {addressData &&
                                      <input
                                        type="checkbox"
                                        className="me-2"
                                        checked={check}
                                        onClick={() => {
                                          if (shippingAddress != null) {
                                            if (check == true) {
                                              setCheck(false);
                                              setBillingAddress(shippingAddress);
                                            } else {
                                              setCheck(true);
                                              setBillingAddress(shippingAddress);
                                            }
                                          } else {
                                            Notiflix.Notify.Failure(
                                              "Please select your shipping address"
                                            );
                                          }
                                        }}
                                      />
                                    }
                                    <p className="mb-0 fs-lg-6">
                                      Same as shipping address
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <button
                                  type="button"
                                  className="theme-btn border-0 add-new-address py-1"
                                  onClick={() => {
                                    setShowCanvas(true)
                                  }}>
                                  Add New Address
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {addressData.length > 0 &&
                          <div
                            className="row py-2 px-3"
                            style={{
                              display:
                                check == true
                                  ? "none"
                                  : "flex",
                            }}
                          >
                            {/* Address part */}
                            {addressData.map((data, index) => (

                              <div className="Col-12" style={{
                                display: addressData == null ? "none" : "block",
                              }}>
                                <div className="boxes">
                                  <div className="d-flex">
                                    <div className="p-2 bg-light text-center gap-2 pe-3 d-flex">
                                      <input
                                        type="radio"
                                        checked={
                                          selectedBillingAddress.fld_address_id ===
                                            data.fld_address_id
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          setSelectedBillingAddress(data);
                                          setBillingAddress(data.fld_address_id);
                                        }}></input>
                                      <div>
                                        {data.fld_address_type == "Home" ?
                                          <span className="cart-address-icons"><FaHome /></span>
                                          : data.fld_address_type == "Office" ?
                                            <span className="cart-address-icons"><GiSuitcase /></span>
                                            :
                                            <span className="cart-address-icons"><FaLocationDot /></span>
                                        }
                                        <p className="mb-0 fs-6">
                                          {data.fld_address_type}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="p-2">

                                      <p>
                                        <b>{data.fld_contact_name}</b>
                                      </p>
                                      <p>
                                        {data.fld_contact_number}

                                      </p>
                                      <p>
                                        {data.fld_address_1},{" "}
                                        {data.fld_address_2},{" "}
                                        ,{data.CityName} {data.fld_landmark},
                                        {data.StateName} - {data.fld_pincode}{" "}
                                        ,{data.CountryName} </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            ))}
                          </div>
                        }
                      </>
                    ),
                  },
                  //   {
                  //     key: "4",
                  //     label: (
                  //       <>
                  //         {" "}
                  //         <h4>Delivery Slot</h4>{" "}
                  //         {selectedDate && selectedTimeSlot ? (
                  //           <div className="delivery-slot-prev">
                  //             <span className="me-2">
                  //               <MdWatchLater />
                  //             </span>
                  //             <span>
                  //               {selectedDate} Between {selectedTimeSlot}
                  //             </span>
                  //           </div>
                  //         ) : (
                  //           ""
                  //         )}
                  //       </>
                  //     ),
                  //     children: (
                  //       <>
                  //         <div className="d-flex flex-wrap p-3">
                  //           <h5 className="w-100">Select Date</h5>
                  //           {dates.map((date, index) => (
                  //             <div
                  //               key={index}
                  //               className={
                  //                 selectedDate === date.toDateString().slice(4)
                  //                   ? "border rounded delivery-dates cursor-pointer border-dark"
                  //                   : "border rounded delivery-dates cursor-pointer"
                  //               }
                  //               onClick={() => {
                  //                 setSelectedDate(date.toDateString().slice(4));
                  //               }}>
                  //               {index === 0 && "Today "}
                  //               {index === 1 && "Tomorrow "}
                  //               <p>
                  //                 <b>{date.toDateString().split(" ")[0]}</b>
                  //               </p>
                  //               <p>{date.toDateString().slice(4)}</p>
                  //               {selectedDate ===
                  //                 date.toDateString().slice(4) && (
                  //                 <p className="delivery-dates-check">
                  //                   <TiTick />
                  //                 </p>
                  //               )}
                  //             </div>
                  //           ))}
                  //         </div>
                  //         <hr />
                  //         <div className="d-flex flex-wrap justify-content-between p-3">
                  //           <h5 className="w-100">Select Time</h5>
                  //           {timeSlots?.map((data, index) => {
                  //             return (
                  //               <div
                  //                 className={
                  //                   selectedTimeSlot === data.label
                  //                     ? "d-flex delivery-slots justify-content-between featured-background cursor-pointer border-dark border"
                  //                     : "d-flex delivery-slots justify-content-between featured-background cursor-pointer"
                  //                 }
                  //                 onClick={() => {
                  //                   setSelectedTimeSlot(data.label);
                  //                 }}>
                  //                 <div>{data.label}</div>
                  //                 {selectedTimeSlot === data.label && (
                  //                   <p>
                  //                     <TiTick />
                  //                   </p>
                  //                 )}
                  //               </div>
                  //             );
                  //           })}
                  //         </div>
                  //       </>
                  //     ),
                  //   },
                  {
                    key: "5",
                    label:
                      <div className="d-flex align-items-center"><span className="cart-accordian-icons"><BsCreditCard2BackFill /></span>

                        <h4 className="main-heading">Payment Method</h4>
                      </div>,
                    children: (
                      <div className="row m-0 py-3 w-100">
                        <div className="col-lg-6">
                          <div
                            className="mb-3 mb-lg-0"
                            style={{
                              padding: "10px 20px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              fontSize: "16px",
                            }}>
                            <div className="d-flex align-items-center">
                              <label
                                className="custom-control-label ms-2"
                                for="online-payment">
                                <input
                                  className="checkradio me-2"
                                  type="radio"
                                  name="optradio"
                                  checked={COD == "No" ? true : false}
                                  onChange={() => {
                                    setCOD("No");
                                  }}
                                />
                                Credit Card/ Debit card / Net Banking
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div
                            className="d-flex justify-content-between flex-wrap align-items-center"
                            style={{
                              padding: "10px 20px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              fontSize: "16px",
                            }}>
                            <label
                              className="custom-control-label"
                              for="cash-on-delivery">
                              <input
                                className="checkradio me-2"
                                type="radio"
                                name="optradio"
                                disabled={
                                  clientData[0]?.paymentType != "single"
                                    ? true
                                    : false
                                }
                                checked={COD == "Yes" ? true : false}
                                onChange={() => {
                                  setCOD("Yes");
                                }}
                              />
                              Cash On Delivery
                            </label>
                            {clientData[0]?.paymentType != "single" && (
                              <div className="text-danger"><small>Not Available</small></div>
                            )}
                          </div>

                        </div>
                      </div>
                    ),
                  },
                ]}
                defaultActiveKey={["1"]}
                onChange={() => { }}
              />
            </div>
            <div className="col-lg-5 mb-5 pb-lg-5">
              <div
                className="order-summary sticky-top mt-4 mt-lg-0"
                style={{
                  // transition: "1s",
                  transform: stickyTop ? "translateY(125px)" : "",
                }}>
                <div>
                  <h4 className="main-heading">Order Summary</h4>

                  {cartData.map((product, i) => {
                    if (i === 0) {
                      return (
                        <table className="order-summary-table">
                          {offerData.length > 0 &&
                            <tr>
                              <td className="border-0 w-100" colSpan={3}>
                                <div className="d-flex justify-content-between align-items-center w-100 coupon-card-box">
                                  <div
                                    className="d-flex w-100 align-items-center cursor-pointer"
                                    onClick={() => {
                                      if (shippingAddress != null) {
                                        setCouponCard(true);
                                      } else {
                                        Notiflix.Notify.Failure(
                                          "Please select the shipping Address"
                                        );
                                      }
                                    }}>
                                    <RiCoupon2Fill />
                                    <p className="ms-2 fw-bold mb-0">
                                      {couponCode == null
                                        ? "Apply Coupon"
                                        : couponCode}
                                    </p>
                                  </div>
                                  {couponCode == null ? (
                                    <>
                                      {offerData.length > 0 && (
                                        <p>{offerData.length}  {offerData.length > 1 ? "Coupons" : "Coupon"} Available</p>
                                      )}
                                    </>
                                  ) : (
                                    <p
                                      style={{ cursor: "pointer" }}
                                      className="removeCoupon"
                                      onClick={() => {
                                        removeCoupon(cartID, product.fld_coupon_id);
                                      }}>
                                      {" "}
                                      Remove
                                    </p>
                                  )}
                                </div>
                              </td>
                            </tr>
                          }
                          <tr>
                            <td><CiViewList />
                              Items Total</td>
                            <td className="text-end">
                              {" "}
                              <i
                                className="fa fa-inr"
                                aria-hidden="true"></i>{" "}
                              {parseFloat(product.fld_total_net_amount).toFixed(
                                2
                              )}
                            </td>
                          </tr>
                          {(product.fld_cart_discount_amount || product.fld_cart_discount_amount != 0) &&
                            <tr>
                              <td><BiSolidOffer />
                                Offer Discount </td>
                              <td className="text-end">
                                {" "}
                                <i
                                  className="fa fa-inr"
                                  aria-hidden="true"></i>{" "}
                                {product.fld_cart_discount_amount}{" "}
                              </td>
                            </tr>
                          }
                          {(product.fld_coupon_discount != null && product.fld_coupon_discount != 0) &&
                            <tr>
                              <td><RiCoupon2Line />
                                Coupon Discount </td>
                              <td className="text-end">
                                {" "}
                                <i
                                  className="fa fa-inr"
                                  aria-hidden="true"></i>{" "}
                                {product.fld_coupon_discount}{" "}
                              </td>
                            </tr>}
                          <tr>
                            <td><RiEBike2Line />
                              Delivery Charges </td>
                            <td className="text-end">
                              {" "}

                              {product.fld_shipping_charge == null ||
                                product.fld_shipping_charge == 0
                                ? "Free"
                                :
                                <>
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"></i>{" "}{parseFloat(product.fld_shipping_charge).toFixed(
                                      2
                                    )}</>
                              }{" "}

                            </td>
                          </tr>
                          <tr>
                            <td><HiOutlineBuildingLibrary />
                              GST</td>
                            <td className="text-end">
                              {" "}
                              <i className="fa fa-inr" aria-hidden="true"></i>
                              {parseFloat(product.fld_total_gst).toFixed(2)}
                            </td>
                          </tr>
                          <tr className="grand-total">
                            <td style={{ paddingTop: "0px !important" }}>
                              <p className="fw-bold">Grand Total</p>
                            </td>
                            <td className="text-end fs-6 fw-bold">
                              {" "}
                              <i
                                className="fa fa-inr"
                                aria-hidden="true"></i>{" "}
                              {parseFloat(product.fld_cart_amount).toFixed(2)}{" "}
                              <br />
                              <i>
                                (Inclusive of all Taxes)
                              </i>
                            </td>
                          </tr>
                        </table>
                      );
                    }
                  })}

                  {(deliveryErrorMsg != "" && !canDeliver) &&
                    <p className="text-danger">
                      {deliveryErrorMsg}
                    </p>}
                  {storeStatus == "Active" &&
                    <button
                      className={(cartamount < 100 || !canDeliver) ? 'theme-btn-disabled border-0 w-100 mt-3' : "mt-3 theme-btn border-0 w-100"}
                      onClick={() => cartamount < 100 || !canDeliver ? '' : handleConfirmAddress()}
                      disabled={cartamount < 100 || !canDeliver}
                    >
                      Confirm Order
                    </button>
                  }
                </div>

                {cartamount < 100 &&
                  <div className="order-bottom-card">
                    <>
                      <p className=" mb-0 fw-bold">Add products worth <i className="fa fa-inr" aria-hidden="true"></i> {100 - cartamount} more</p>
                      <p className=" mb-0">Minimum order amount should be <i
                        className="fa fa-inr"
                        aria-hidden="true"></i> 100 and above</p>
                    </>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="blank-section w-100 h-100 d-flex align-items-center justify-content-center flex-column py-5 my-md-5">
          <div className="empty-icon fs-1">
            <IoMdCart />
          </div>
          <p>No Items in available in your cart</p>
          <div>
            <a href="/" className="theme-btn w-auto d-block">
              Shop Now
            </a>
          </div>
        </div>
      )}
      <Modal
        show={couponCard == true ? true : false}
        // show={couponCard}
        centered
        fullscreen="xl"
        size="md"
        scrollable="true"
        className="coupon-modal"
        onHide={handleToggle}>
        <Modal.Header
          closeButton
          // close={
          // 	<button
          // 		className="coupon-close-btn close "
          // 		onClick={() => {
          // 			setCouponCard(false)
          // 			setCouponErrorCodeTextBox(null)
          // 			setOfferErrorMessage("")
          // 			setEnteredCouponCode(null)

          // 		}}>
          // 		<IoClose />
          // 	</button>
          // }
          className="pb-0 border-0"></Modal.Header>
        <Modal.Body className="py-0">
          <div className="row">
            <div className="col-12 text-center mb-4">
              <RiCoupon2Fill className="main-svg" />
              <div className="section-title mt-0">
                <h4 className="section-title-category-section">
                  Coupons and Offers
                  <div className="title-divider"></div>
                </h4>
              </div>
            </div>
            <div className="col-12 mb-2 apply-coupon-input">
              <input
                type="text"
                placeholder="Enter Coupon Code"
                className="form-control mb-0"
                value={enteredCouponCode}
                onChange={(enteredcouponcode) => {
                  setEnteredCouponCode(enteredcouponcode.target.value);
                }}></input>
              <p className="invalid-coupon-code-textbox">
                {couponErrorCodeTextBox}
              </p>
              <p className="invalid-coupon-code-textbox-color">
                {offerErrorMessageTextBox}
              </p>
            </div>
            <div className="col-12 apply-coupon-button">
              <button
                className="theme-btn border-0 my-3 w-100 text-center"
                onClick={() => {
                  var login = localStorage.getItem("CustomerData");
                  var logindetails = JSON.parse(login);

                  if (enteredCouponCode != null) {
                    setCouponErrorCodeTextBox(null);
                    setOfferErrorMessageTextBox("");
                    {
                      PostApiCall.postRequest(
                        {
                          whereClause: `where UPPER(fld_code) = UPPER('${enteredCouponCode}') AND cast(getdate() as date) between cast(fld_startdate as date) and cast(fld_enddate as date)`,
                        },
                        "SearchForOfferCode"
                      ).then((results) =>
                        // const objs = JSON.parse(result._bodyText)
                        results.json().then((obj) => {
                          if (results.status == 200 || results.status == 201) {
                            if (obj.data.length != 0) {
                              PostApiCall.postRequest(
                                {
                                  orderid: cartID,
                                  couponid: obj.data[0].fld_offerid,
                                  stateid:
                                    localStorage.getItem("ChangedPincode") ==
                                      null
                                      ? null
                                      : localStorage.getItem("ChangedPincode"),
                                  customerid:
                                    localStorage.getItem("CustomerData") == null
                                      ? null
                                      : logindetails[0].fld_customerid,
                                  status: "apply",
                                },
                                "ApplyOffer"
                              ).then((result) =>
                                result.json().then((obj3) => {
                                  // //console.log(obj3.data);

                                  if (obj3.data) {
                                    if (obj3.data.length != 0) {
                                      Notiflix.Loading.Remove();
                                      setOfferErrorCode(obj3.data[0].errorcode);
                                      setOfferErrorMessageTextBox(
                                        obj3.data[0].error
                                      );
                                      setAppliedCoupon(enteredCouponCode);

                                      getCartdetails();
                                    } else {
                                      Notiflix.Loading.Remove();
                                    }
                                  } else {
                                    Notiflix.Loading.Remove();
                                    Notiflix.Notify.Failure(
                                      "Soemthing went wrong. Please try again."
                                    );
                                  }
                                })
                              );
                            } else {
                              setOfferErrorMessageTextBox(
                                "Coupon does not exist"
                              );
                            }
                          }
                        })
                      );
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter Coupon Code");
                  }
                }}>
                Apply Coupon
              </button>
            </div>
          </div>
          <div
            className="rgbox-bottom text-center mt-5 mb-5"
            style={{
              display: offerData.length != 0 ? "block" : "none",
            }}>
            <p>
              <label>OR</label>
            </p>
          </div>
          <div className="coupon-main-box">
            {offerData?.map((data, i) => {
              return (
                <div
                  className={
                    showCouponMoreInformation == data.fld_offerid
                      ? "coupon-card coupon-card-active mb-3 d-flex flex-column align-content-between"
                      : "coupon-card mb-3 d-flex flex-column align-content-between"
                  }
                  key={i}>
                  <div className="px-3 pt-3">
                    <p className="text-dark text-center fw-bold pb-1">
                      {data.fld_name}
                    </p>
                    <hr className="mt-2 border-dark" />
                    <p className="coupon-terms-condition">{data.fld_caption}</p>
                    <div className="d-flex justify-content-between align-items-center my-2">
                      <p className="coupon-card-code">{data.fld_code}</p>
                      <span
                        className="view-more cursor-pointer text-dark text-end"
                        style={{
                          display:
                            showCouponMoreInformation == false
                              ? "block"
                              : "none",
                        }}
                        onClick={() => {
                          setShowCouponMoreInformation(data.fld_offerid);
                        }}>
                        View Details <IoIosArrowDown />
                      </span>
                    </div>
                    {/* <p
                            className={appliedCoupon == data.fld_code ? "coupon-terms-condition mb-5" : "d-none"}
                            style={{
                              color:
                                 offerErrorCode == "1"
                                  ? "#fa4a5b"
                                  : "#60b246",
                            }}>
                            {appliedCoupon == data.fld_code
                              ?  offerErrorMessage
                              : ""}
                          </p>  */}
                    {/* <p className="coupon-terms-condition">
                            {data.fld_description}
                          </p> */}

                    <div
                      className="coupon-terms-condition ms-2"
                      style={{
                        display:
                          showCouponMoreInformation == data.fld_offerid
                            ? "block"
                            : "none",
                      }}>
                      <hr className="border-dark" />
                      <p className="text-dark">Terms and Conditions</p>
                      <p
                        className={
                          showCouponMoreInformation == data.fld_offerid
                            ? "terms-show"
                            : ""
                        }>
                        {parse(data.fld_termscondition)}
                      </p>
                      <span
                        className="cursor-pointer mt-3 text-dark d-block text-end"
                        onClick={() => {
                          setShowCouponMoreInformation("");
                        }}>
                        View Less <IoIosArrowUp />
                      </span>
                    </div>
                    {couponCode == data.fld_code && (
                      <p
                        className="text-danger"
                        style={{
                          display:
                            offerErrorMessage !=
                              "Coupon applied successfully" ||
                              offerErrorMessage != ""
                              ? "none"
                              : "block",
                        }}>
                        {offerErrorMessage}
                      </p>
                    )}
                  </div>
                  <div className="text-end apply-coupon-btn mt-2">
                    <button
                      className="btn align-items-center place-order"
                      onClick={() => {
                        var login = localStorage.getItem("CustomerData");
                        var logindetails = JSON.parse(login);
                        Notiflix.Loading.Circle();
                        couponCode == data.fld_code
                          ? removeCoupon(cartID, data.fld_offerid)
                          : PostApiCall.postRequest(
                            {
                              orderid: cartID,
                              couponid: data.fld_offerid,
                              stateid: localStorage.getItem("ChangedPincode"),
                              customerid:
                                localStorage.getItem("CustomerData") == null
                                  ? null
                                  : logindetails[0].fld_customerid,
                              status: "apply",
                            },
                            "ApplyOffer"
                          ).then((result) =>
                            result.json().then((obj3) => {
                              // //console.log(obj3.data);

                              if (obj3.data) {
                                if (obj3.data.length != 0) {
                                  Notiflix.Loading.Remove();
                                  setOfferErrorCode(obj3.data[0].errorcode);
                                  setOfferErrorMessage(obj3.data[0].error);
                                  setAppliedCoupon(data.fld_code);
                                  getCartdetails();
                                } else {
                                  Notiflix.Loading.Remove();
                                }
                              } else {
                                Notiflix.Loading.Remove();
                                Notiflix.Notify.Failure(
                                  "Soemthing went wrong. Please try again."
                                );
                              }
                            })
                          );
                      }}>
                      {/* <span>There are no eligible products for this coupon</span> */}
                      {couponCode == data.fld_code ? (
                        <div className="d-flex justify-content-between align-items-center applied-coupon-btn pb-1 pt-1 text-white">
                          <span>
                            Coupon Applied{" "}
                            <IoCheckmarkDone className="font-small" />
                          </span>
                          <span className="cursor-pointer">Remove</span>
                        </div>
                      ) : (
                        <span className="text-white">Tap to Apply</span>
                      )}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
      <Offcanvas
        show={showCanvas}
        onHide={handleCloseCanvas}
        className="address-offcanvas"
        id="offcanvasNavbar-expand-lg"
        aria-labelledby="offcanvasNavbarLabel-expand-sm"
        placement="end">
        <Offcanvas.Header closeButton>
          <h4 className="section-title-category-section mt-0">
            Add New Address
          </h4>
          {/* <img src={logo} className="img-fluid nav-logo" /> */}
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <AddressBookForm toggleCanvas={handleCloseCanvas} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

import { Skeleton } from "antd";
import React from "react";
import "./ParallaxSection.css";
export default function ParallaxSection({ banners }) {
  return (
    <div className="parallax-section-box">

      {banners ?
        <section className="parallax-section  mb-lg-4" style={{
          // background: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(${banners[0]?.image.split("$")[0]?.split("#")[0]})`
          background: `url(${banners[0]?.image.split("$")[0]?.split("#")[0]})`
        }}>
          {/* <h2>{banners[0]?.heading}</h2>
          <h5>{banners[0]?.title}</h5> */}
          {/* <div className="mt-4 mt-lg-5">
              <a href={banners[0]?.slug} className="theme-btn">
                Explore More
              </a>
            </div> */}
        </section>
        :
        <Skeleton.Image className="w-100 parallax-section mt-5 mb-4" />
      }
    </div>
  );
}


import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import "./AddToCartQty.css";

function AddToCartQty(props) {
	const quantity = props.quantity;
	const itemid = props.itemid;
	const cartlineid = props.cartlineid;
	const { setLoginPopupWebsite } = useContext(store);
	const { setCartItemBeforeLogin } = useContext(store);
	const { setCategory4Data } = useContext(store);
	const { cart, setcart, setCartItems, setCartData, setcartamount, } = useContext(store);
	const GetCartdetails = () => {
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);
		var orderid = 0;
		if (localStorage.getItem("OrderData") !== null) {
			orderid = Orderdetails[0].order_id;
		}
		if (localStorage.getItem("CustomerData") === null) {
			setLoginPopupWebsite(true);
		} else {
			var login = localStorage.getItem("CustomerData");
			var logindetails = JSON.parse(login);
			if (localStorage.getItem("OrderData") !== null) {
				var Order = localStorage.getItem("OrderData");
				var Orderdetails = JSON.parse(Order);
				PostApiCall.postRequest(
					{
						orderid:
							localStorage.getItem("OrderData") == null
								? null
								: Orderdetails[0].order_id,
						id:
							localStorage.getItem("CustomerData") == null
								? null
								: logindetails[0].fld_customerid,
					},
					"GetCartWebsite"
				)
					.then((res) => res.json())
					.then((res1) => {
						if (res1.data) {
							// setProductCart(res1.data);
							setcart(res1.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
							setCartItems(res1.data);
							setCartData(res1.data)
							setcartamount(res1.data[0].fld_cart_amount);
						}
					});
			}
		}
		PostApiCall.postRequest(
			{
				customerid:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
				categoryid: null,
				productid: null,
				variantid: null,
				sectionname: "Category4",
				orderid: orderid,
			},
			"GetWebsiteHomePageItems"
		).then((results) => {
			results.json()?.then((obj) => {
				if (results.status == 200 || results.status == 201) {
					// ;
					setCategory4Data(obj.data);
				}
			});
		});
		PostApiCall.postRequest(
			{
				customerid:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
				categoryid: null,
				productid: null,
				variantid: null,
				sectionname: "New Arrivals",
				orderid: orderid,
				itemchoice: "Random",
			},
			"GetWebsiteHomePageItems"
		).then((results) => {
			results.json()?.then((obj) => {
				if (results.status == 200 || results.status == 201) {
					// ;
					// setNewArrivals(obj.data);
				}
			});
		});
		PostApiCall.postRequest(
			{
				customerid:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
				categoryid: null,
				productid: null,
				variantid: null,
				sectionname: "Best Seller",
				orderid: orderid,
				itemchoice: "Random",
			},
			"GetWebsiteHomePageItems"
		).then((results) => {
			results.json()?.then((obj) => {
				if (results.status == 200 || results.status == 201) {
					// ;
					// setBestSeller(obj.data);
				}
			});
		});
	};
	const deletecart = (props) => {
		var Order = localStorage.getItem("OrderData");
		var login = localStorage.getItem("CustomerData");
		var Orderdetails = JSON.parse(Order);
		var logindetails = JSON.parse(login);
		var orderid = 0;
		if (localStorage.getItem("OrderData") !== null) {
			orderid = Orderdetails[0].order_id;
		}
		PostApiCall.postRequest(
			{
				cartID: orderid,
				customerid:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
				cartlineid: cartlineid,
				stateid: 0,
			},
			"DeleteCart"
		).then((result) =>
			result.json().then((obj3) => {
				if (result.status == 200 || result.status == 201) {
					Notiflix.Notify.Success("Product is successfully deleted.");
					GetCartdetails();
					if (cart == 1) {
						localStorage.removeItem("OrderData");
					}
					GetCartdetails();
				} else {
					Notiflix.Notify.Failure(obj3.data);
				}
			})
		);
	};

	const addtocart = (qty) => {
		Notiflix.Loading.Dots("");
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);
		if (logindetails === null) {
			setLoginPopupWebsite(true);
			setCartItemBeforeLogin(itemid);
		} else {
			// ===========with Login=========
			PostApiCall.postRequest(
				{
					orderdate: moment().format("lll"),
					itemdetails: `
                     [
                     {
                         "Item":${itemid
						},
                         "Quantity": ${qty}
                     }
                     ]
                     `,
					status: "INCART",
					customerid:
						localStorage.getItem("CustomerData") == null
							? null
							: logindetails[0].fld_customerid,
					createdon: moment().format("lll"),
					updatedon: moment().format("lll"),
					orderid:
						localStorage.getItem("OrderData") == null
							? 0
							: Orderdetails[0].order_id,
					updatedby:
						localStorage.getItem("CustomerData") == null
							? null
							: logindetails[0].fld_userid,
				},
				"AddToCartWebsite"
			).then((result) =>
				result.json().then((obj) => {
					if (result.status == 200 || result.status == 201) {
						if (props == 1) {
							Notiflix.Notify.Success("Product added to Cart.");
						}
						if (localStorage.getItem("OrderData") == null) {
							localStorage.setItem("OrderData", JSON.stringify(obj.data));

						}
						GetCartdetails();
						Notiflix.Loading.Remove();
					} else {
						Notiflix.Loading.Remove();
						Notiflix.Notify.Failure(obj.data);
					}
				})
			);
		}
	};
	return (
		<div className="quantity-btn-box">
			<div class="quantity-btn" type="text">
				<button
					class=""
					name="minus"
					type="button"
					onClick={() => {
						addtocart(-1);
					}}>
					<FiMinus />
				</button>
				<input
					className=""
					type="text"
					value={quantity}
					min="0"
					id="Quantity-1"
					data-index="1"
				/>
				<button
					className=""
					name="plus"
					type="button"
					onClick={() => {
						addtocart(1);
					}}>
					<FiPlus />
				</button>
			</div>
			<div className="quantity-delete-btn">
				<cart-remove-button
					id="Remove-1"
					data-index="1"
					className="Remove-1"
					onClick={deletecart}>
					<MdDeleteForever />
				</cart-remove-button>
			</div>
		</div>
	);
}

export default AddToCartQty;

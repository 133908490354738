import { Drawer } from "antd";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import { store } from "../../context/store";
import AddToCartQty from "../AddToCartQty/AddToCartQty";
import AddToCart from "../Helpers/AddToCart";
import "./ProductCard.css";

export default function ProductCard(props) {
  const [variantImage, setVariantImage] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [productId, setProductId] = useState(null);
  const [variantName, setVariantName] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [variantList, setVariantList] = useState(null);
  const [MRP, setMRP] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [variantImagesList, setVariantImagesList] = useState([]);
  const [savePer, setSavePer] = useState(null);
  const [stock, setStock] = useState(null);
  const [weight, setWeight] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [itemType, setItemType] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const { cartItems, storeStatus } = useContext(store);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  // const [modalSide, setModalSide] = useState("")
  function handleClose() {
    setIsOpen(false);
  }
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    try {
      setItemType(props.itemdata.item_type);
      setItemName(props.itemdata.fld_variantname);
      setVariantList(props.itemdata.variant_list);
      setMRP(
        props.itemdata.variant_list.split("#")[0].split("$")[1].split(",")[2]
      );
      setDiscountPrice(
        props.itemdata.variant_list.split("#")[0].split("$")[1].split(",")[3]
      );
      setSavePer(
        props.itemdata.variant_list.split("#")[0].split("$")[1].split(",")[4]
      );

      setStock(
        props.itemdata.variant_list.split("#")[0].split("$")[1].split(",")[6]
      );
      setVariantId(
        props.itemdata.variant_list.split("#")[0].split("$")[1].split(",")[1]
      );
      setVariantName(
        props.itemdata.variant_list.split("#")[0].split("$")[1].split(",")[0]
      );
      setCategoryName(props.itemdata.fld_categoryname);

      setVariantImage(
        props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[7]
          .split("~")[0]
      );
      setProductId(props.itemdata.fld_productid);
    } catch {
      try {
        setItemName(props.itemdata.fld_variantname);
        setVariantList(props.itemdata.variant_list);
        setMRP(props.itemdata.variant_list.split("$")[1].split(",")[2]);
        setDiscountPrice(
          props.itemdata.variant_list.split("$")[1].split(",")[3]
        );
        setSavePer(props.itemdata.variant_list.split("$")[1].split(",")[4]);
        setStock(props.itemdata.variant_list.split("$")[1].split(",")[6]);
        setVariantId(props.itemdata.variant_list.split("$")[1].split(",")[1]);
        setVariantName(props.itemdata.variant_list.split("$")[1].split(",")[0]);
        setWeight(props.itemdata.variant_list.split("$")[1].split(",")[8]);
        setCategoryName(props.itemdata.fld_categoryname);

        setVariantImage(
          props.itemdata.variant_list.split("$")[1].split(",")[7].split("~")[0]
        );
        setProductId(props.itemdata.fld_productid);
      } catch { }
    }
  }, []);
  // function handleToggleModal() {
  //   setIsOpen(false)
  // }

  // const handleChangeModalSide = () => {
  //   setModalSide((prevState) => {
  //     if (prevState == "Reg") {
  //       return 'Log'
  //     } else {
  //       return 'Reg'
  //     }
  //   })
  // }

  function checkCartItem() { }

  return (
    <>
      {/* <LoginModal
        isOpen={isOpen}
        handleToggleModal={handleToggleModal}
        handleChangeModalSide={handleChangeModalSide}
      /> */}
      <div className="item">
        <div className="product-box">
          <div className="p-images blog-images position-relative">
            <a
              href={`${process.env.PUBLIC_URL
                }/productdetail/${props.itemdata.fld_itemname.replace(
                  /\s/g,
                  "-"
                )}-${variantName?.replace(/\s/g, "-")}/${variantId}`}>
              <img
                src={variantImage}
                alt={props.itemdata.fld_variantname?.replace(/\s/g, "-")}
                className="img-fluid"></img>
              {/* {props.itemdata.fld_discount_percent > 0 ?
                  <span className="offer-label">{props.itemdata.fld_discount_percent}%</span>
                  : ""} */}
            </a>
          </div>

          <div className="content-box position-relative text-start mt-3">
            {/* <span style={{ color: "#00000075" }}>{categoryName}</span> */}

            <p className="product-title2">
              <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${props.itemdata.fld_itemname.replace(
                    /\s/g,
                    "-"
                  )}-${variantName?.replace(/\s/g, "-")}/${variantId}`}>
                {props.itemdata.fld_itemname}
              </a>
            </p>
            <p className="cursor-pointer"
              onClick={() => {
                if (props.itemdata.variant_list.split("#").length > 1) {
                  window.innerWidth < 768 ? showDrawer() : setIsOpen(true);
                }
              }}>
              {variantName?.split("(")[0]}
              {variantName?.split("(")[1] ? "(" : ""}
              {variantName?.split("(")[1]}
              {props.itemdata.variant_list.split("#").length > 1 && (
                <span>
                  <IoIosArrowDown />
                </span>
              )}
            </p>
            {storeStatus == "Active" &&
              <div className="d-flex mt-2 justify-content-between align-items-end">
                <p className="price mb-0 fs-5 disc-price">
                  <i className="fas fa-rupee-sign"></i> ₹{" "}
                  {parseFloat(discountPrice).toFixed(0)}
                  {MRP > discountPrice && <del className="ms-2">₹ {MRP}</del>}
                </p>
                {props.isInCart ? (
                  <AddToCartQty
                    itemid={variantId}
                    quantity={props.isInCart.fld_quantity}
                    cartlineid={props.isInCart.fld_cart_dtl_id}
                  />
                ) : (
                  <button className="theme-btn border-0 py-1 px-2">
                    <AddToCart itemid={variantId} />
                  </button>
                )}
              </div>
            }
          </div>
        </div>
      </div>
      {/* Modal to view available options for an item */}
      <Modal show={isOpen} centered onHide={() => handleClose()}>
        <Modal.Header closeButton className="border-0 pb-0">
          <h5 className="mb-0">{itemName}</h5>
        </Modal.Header>
        <Modal.Body className="">
          {props.itemdata.variant_list.split("#")?.map((dat) => {
            const InCart = cartItems.find((item) => {
              if (item.fld_variantid == dat.split("$")[1].split(",")[1]) {
                return item;
              }
            });
            return (
              <div className="variant-modal-card">
                <div className="d-flex align-items-center">
                  <img
                    src={dat.split("$")[1].split(",")[7].split("~")[0]}
                    className="img-fluid"
                  />
                  <p>{dat.split("$")[1].split(",")[0]}</p>
                </div>
                <p className="">
                  ₹&nbsp; {dat.split("$")[1].split(",")[3]}{" "}
                  {dat.split("$")[1].split(",")[2] >
                    dat.split("$")[1].split(",")[3] && (
                      <del>₹&nbsp;{dat.split("$")[1].split(",")[2]}</del>
                    )}
                </p>
                {storeStatus == "Active" &&
                  <>
                    {InCart ? (
                      <AddToCartQty
                        itemid={variantId}
                        quantity={InCart.fld_quantity}
                        cartlineid={InCart.fld_cart_dtl_id}
                      />
                    ) : (
                      <span className="theme-btn cursor-pointer">
                        <AddToCart itemid={dat.split("$")[1].split(",")[1]} />
                      </span>
                    )}
                  </>
                }

              </div>
            );
          })}
        </Modal.Body>
      </Modal>
      {/* mobile drawer to show available options for an item  */}
      <Drawer
        className="variant-drawer-mobile"
        title={itemName}
        onClose={onClose}
        open={open}
        placement="bottom">
        {props.itemdata.variant_list.split("#")?.map((dat) => {
          const InCart = cartItems.find((item) => {
            if (item.fld_variantid == dat.split("$")[1].split(",")[1]) {
              return item;
            }
          });
          return (
            <div className="variant-modal-card">
              <div className="d-flex align-items-center">
                <img
                  src={dat.split("$")[1].split(",")[7].split("~")[0]}
                  className="img-fluid"
                />
                <p>{dat.split("$")[1].split(",")[0]}</p>
              </div>
              <p className="ms-lg-5 ps-lg-4">
                ₹&nbsp;{dat.split("$")[1].split(",")[3]}{" "}
                {dat.split("$")[1].split(",")[2] >
                  dat.split("$")[1].split(",")[3] && (
                    <del>₹&nbsp;{dat.split("$")[1].split(",")[2]}</del>
                  )}
              </p>
              {storeStatus == "Active" &&
                <>
                  {InCart ? (
                    <AddToCartQty
                      itemid={variantId}
                      quantity={InCart.fld_quantity}
                      cartlineid={InCart.fld_cart_dtl_id}
                    />
                  ) : (
                    <span className="theme-btn cursor-pointer">
                      <AddToCart itemid={dat.split("$")[1].split(",")[1]} />
                    </span>
                  )}
                </>
              }
            </div>
          );
        })}
      </Drawer>
    </>
  );
}

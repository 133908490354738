import Parser from "html-react-parser";
import React, { useEffect, useState } from "react";
import PostApiCall from "../../helpers/Api";
// import abtImg from "./assets/img/about-us.png";
// import Footer from "./Footer";
// import Header from "./Header";
import { Skeleton } from "antd";
import GetSeoData from "../../helpers/GetSeoData";

export default function StaticPages({ pagename }) {
  const [staticPages, setStaticPages] = useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // document.title = pagename; // can be used later
    PostApiCall.postRequest(
      {
        whereclause: `where fld_route =  '${window.location.pathname}'`,
      },
      "GetStaticPages"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setStaticPages(obj1.data);
        }
      })
    );
  }, []);
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  let pagesData = window.location.pathname.split("/")[1].replace(/-/g, " ");

  return (
    <div className="blank-section">
      <GetSeoData type={capitalizeWords(pagesData)} id={null} />
      <div className="container-fluid pt-4 pt-md-0 mt-5 mt-lg-0 mb-5">
        {staticPages.length > 0 ? (
          staticPages[0]?.fld_page_name == "About Us" ? (
            <div className="row footer-about-us">
              {staticPages[0]?.fld_content ? (
                <>
                  <div className="section-title">
                    <h1 className="section-title-category-section w-auto mb-0 main-heading text-center">
                      {staticPages[0]?.fld_page_heading}
                    </h1>
                  </div>

                  <div className=" mb-4 mt-md-5 col-md-6 pr-lg-4 pl-lg-4">
                    <div className="privacy-box container-fluid px-2">
                      {Parser(
                        ("<p>" + staticPages[0]?.fld_content + "</p>")
                          .replace(/font-family/g, "")
                          .replace(/<br\/?>/gi, " ")
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>No data</>
              )}
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <div className="section-title col-12 mb-0">
                  <h1 className="section-title-category-section w-auto mb-0 main-heading text-center mt-3">
                    {staticPages[0]?.fld_page_heading}
                  </h1>
                </div>

                <div className="privacy-box container-fluid px-2">
                  {Parser(
                    ("<p>" + staticPages[0]?.fld_content + "</p>")
                      .replace(/font-family/g, "")
                      .replace(/<br\/?>/gi, " ")
                  )}
                </div>
              </div>
            </div>
          )
        ) : (
          <Skeleton className="mt-4" />
        )}
      </div>
      {/* <Footer></Footer> */}
    </div>
  );
}

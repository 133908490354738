import { Skeleton } from "antd";
import { useContext } from "react";
import { store } from "../../context/store";
import ProductCard from "../ProductCard/ProductCard";
import "./BestSeller.css";
export default function BestSeller({ categoryData, className }) {
  const { cartItems } = useContext(store);
  return (
    <div className="px-lg-3">
      <div className="container-fluid">
        <div className="row">
          {categoryData.length > 0 ? (
            categoryData?.map((data) => {
              const isInCart = cartItems.find((item) => {
                if (item.fld_variantid === data.fld_variantid) {
                  return item;
                }
              });
              return (
                <div className={className}>
                  <ProductCard itemdata={data} isInCart={isInCart} />
                </div>
              );
            })
          ) : (
            <>
              <div className={`${className} product-img-skeleton`}>
                <Skeleton.Image className="h-100 w-100" />
                <div className="buttons flex-column detailsbtn mb-0">
                  <h2 className="prod-price">
                    <Skeleton.Button />
                  </h2>
                </div>
                <div className="mt-3">
                  <Skeleton.Input />
                </div>
              </div>
              <div className={`${className} product-img-skeleton`}>
                <Skeleton.Image className="h-100 w-100" />
                <div className="buttons flex-column detailsbtn mb-0">
                  <h2 className="prod-price">
                    <Skeleton.Button />
                  </h2>
                </div>
                <div className="mt-3">
                  <Skeleton.Input />
                </div>
              </div>
              <div className={`${className} product-img-skeleton`}>
                <Skeleton.Image className="h-100 w-100" />
                <div className="buttons flex-column detailsbtn mb-0">
                  <h2 className="prod-price">
                    <Skeleton.Button />
                  </h2>
                </div>
                <div className="mt-3">
                  <Skeleton.Input />
                </div>
              </div>
              <div className={`${className} product-img-skeleton`}>
                <Skeleton.Image className="h-100 w-100" />
                <div className="buttons flex-column detailsbtn mb-0">
                  <h2 className="prod-price">
                    <Skeleton.Button />
                  </h2>
                </div>
                <div className="mt-3">
                  <Skeleton.Input />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

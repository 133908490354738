import React from 'react'
import ContactForm from './ContactForm'

export default function IndustrialVisitContent() {
	return (
		<>
			<h5>Industrial Visit for Students:</h5>
			<p>
				We are excited to offer students the opportunity to gain experience and
				understand the basics of manufacturing, production and packaging through
				guided tours of our facility.
			</p>
			<p>
				If you're interested in scheduling an industrial visit for your students, please
				contact our team to check availability and discuss your specific
				requirements.
			</p>
			<p>
				For inquiries or to schedule an industrial visit, please contact:
			</p>
			<p>
				<a href='mailto:compliance@rubyfood.in'>
					<strong> Email: </strong> compliance@rubyfood.in
				</a>
			</p>
			<p>
				{/* Phone: 91-9629706260 */}
				<a href='tel:9791512484'>
					<strong>Phone: </strong> +91 97915 12484
				</a>
			</p>
			<p>
				We look forward to welcoming you and your students to Ruby for an
				enriching and educational experience!
			</p>
			{/* <ContactForm /> */}
		</>
	)
}

import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { store } from "../../context/store";
import amex from "../../assets/Images/payements-icons/amex.svg";
import bhim from "../../assets/Images/payements-icons/bhim.svg";
import gpay from "../../assets/Images/payements-icons/gpay.svg";
import mastercard from "../../assets/Images/payements-icons/mastercard.svg";
import paytm from "../../assets/Images/payements-icons/paytm.svg";
import phonepe from "../../assets/Images/payements-icons/phonepe.svg";
import rupay from "../../assets/Images/payements-icons/rupay.svg";
import upi from "../../assets/Images/payements-icons/upi.svg";
import visa from "../../assets/Images/payements-icons/visa.svg";
// import christmasten from "../../assets/Images/christmas/christmas-ten.png";
import "./Footer.css";
// import FooterBg from "../../assets/Images/WEBSITE_FOOTER_NEW.jpg";
import GetApiCall from "../../helpers/GetApi";
export default function Footer() {
  const { clientData } = useContext(store);
  const [staticPages, setStaticPages] = useState([]);
  useEffect(() => {
    GetApiCall.getRequest("GetStaticPagesList").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setStaticPages(obj1.data);
        }
      })
    );
  }, []);
  return (
    <>
      <footer className="py-3 px-lg-3 position-relative">
        {/* <img src={christmasten} className="christmas-ten" /> */}
        <div className="container-fluid">
          <div className="row align-items-lg-end">
            {/* <div className="col-md-6 col-lg-2">
              <h3>Get In touch</h3>
              <ul>
                <li>
                  <p className="mb-0 fw-5">{clientData[0]?.name}</p>
                  <a>{clientData[0]?.address}</a>
                </li>
                <li>
                  <a href={`tel:${clientData[0]?.mobile}`} className="fs-3">
                    {clientData[0]?.mobile}
                  </a>
                </li>
                <li>
                  Email:{" "}
                  <a href={`mailto:${clientData[0]?.email}`}>
                    {clientData[0]?.email}
                  </a>
                </li>
              </ul>
            </div> */}
            {/* <div className="col-md-6 col-lg-2">
              <h3 className="mb-0 mb-lg-3">Need Help</h3>
              <ul>
                <li>
                  <Link to={"/faq"}>FAQs</Link>
                </li>
                <li>
                  {staticPages
                    .filter((dat) => {
                      if (
                        dat.fld_status === "Active" &&
                        dat.fld_page_name == "Return & Refund"
                      ) {
                        return dat;
                      }
                    })
                    .map((data) => {
                      return (
                        <li key={data.fld_id}>
                          <Link to={`${data.fld_route}`}>
                            {data.fld_page_name}
                          </Link>
                        </li>
                      );
                    })}
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3 ps-x3-5 mt-3 mt-lg-0">

              <h3 className="mb-1 m0-lg-3">About Us</h3>
              <ul>
                <li>
                  {staticPages
                    .filter((dat) => {
                      if (
                        dat.fld_status === "Active" &&
                        dat.fld_page_name == "Our Story"
                      ) {
                        return dat;
                      }
                    })
                    .map((data) => {
                      return (
                        <li key={data.fld_id}>
                          <Link to={`${data.fld_route}`}>
                            {data.fld_page_name}
                          </Link>
                        </li>
                      );
                    })}
                </li>
                <li>
                  <Link to={"/contact-us"}>Contact Us</Link>
                </li>
              </ul>
            </div> */}
            <div className="col-12">
              <ul className="d-flex footer-conatct-links justify-content-center flex-wrap align-items-end">
                {staticPages.map((link) => (
                  <li key={link.fld_id}>
                    <Link to={link.fld_route}>{link.fld_page_name}</Link>
                  </li>
                ))}
                <li>
                  <Link to="/aboutus">About Us</Link>
                </li>
                <li>
                  <Link to="/distributor">Distributors</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ's</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                {/* <li>
                  <Link to={"/about-us"}>About Us</Link>
                </li>
                <li>
                  <Link to={"/distributor"}>Distributors</Link>
                </li>
                <li>
                  <Link to={"/faq"}>Faq's</Link>
                </li>
                <li>
                  <Link to={"/careers"}>Careers</Link>
                </li>
                <li>
                  <Link to={"/contact-us"}>Contact Us</Link>
                </li>
                <li>
                  <Link to={"/"}>Terms & Conditions</Link>
                </li>
                <li>
                  <Link to={"/"}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={"/"}>Disclaimer</Link>
                </li> */}
              </ul>
              <div className="mt-3">
                <ul className="payments-icons ps-0">
                  <li>
                    <img alt="" src={mastercard} />
                  </li>
                  <li>
                    <img alt="" src={visa} />
                  </li>
                  <li>
                    <img alt="" src={amex} />
                  </li>
                  <li>
                    <img alt="" src={bhim} />
                  </li>
                  <li>
                    <img alt="" src={gpay} />
                  </li>

                  <li>
                    <img alt="" src={paytm} />
                  </li>
                  <li>
                    <img alt="" src={phonepe} />
                  </li>
                  <li>
                    <img alt="" src={rupay} />
                  </li>
                  <li>
                    <img alt="" src={upi} />
                  </li>

                </ul>
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-2 mt-3 mt-lg-0">
              <h3 className="mb-0 mb-lg-3">Follow Us</h3>
              <ul className="mb-2 mb-lg-0 main-nav-menu-sm-links d-flex py-2">
                <SocialIcons
                  link={clientData[0]?.facebook}
                  target="_blank"
                  logo={<FaFacebookF />}
                />
                <SocialIcons
                  link={clientData[0]?.instagram}
                  target="_blank"
                  logo={<AiFillInstagram />}
                />
                <SocialIcons
                  link={clientData[0]?.twitter}
                  target="_blank"
                  logo={<FaXTwitter />}
                />
                <SocialIcons
                  link={clientData[0]?.linkedin}
                  target="_blank"
                  logo={<FaLinkedinIn />}
                />
                <SocialIcons
                  link={clientData[0]?.pintrest}
                  target="_blank"
                  logo={<FaPinterestP />}
                />
                <SocialIcons
                  link={clientData[0]?.youtube}
                  target="_blank"
                  logo={<FaYoutube />}
                />
              </ul>
              <ul>
                <li>
                  {staticPages
                    .filter((dat) => {
                      if (
                        dat.fld_status === "Active" &&
                        dat.fld_page_name != "Return Policy" &&
                        dat.fld_page_name != "Our Story"
                      ) {
                        return dat;
                      }
                    })
                    .map((data) => {
                      return (
                        <li key={data.fld_id}>
                          <Link to={`${data.fld_route}`}>
                            {data.fld_page_name}
                          </Link>
                        </li>
                      );
                    })}
                </li>
              </ul>
            </div> */}
            {/* <div className="col-12 mt-4">
              <p>
                Copyright &copy; {moment().format("YYYY")} Ruby Food. All
                Rights Reserved.
              </p>
            </div> */}
          </div>
        </div>
      </footer>
      <div className="container-fluid px-lg-4 footer-bottom py-2">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-12 col-lg-6 text-lg-start text-center">
            Powered by{" "}
            <a href="https://bechofy.in/" target="blank">
              {" "}
              Bechofy
            </a>{" "}
            - <br className="d-block d-md-none" /> A Product of
            <a href="https://globaltrendz.com/" target="blank">
              &nbsp; Global Trendz
            </a>
          </div>
          <div className="col-12 col-lg-6 text-lg-end text-center">
            <p className="mb-0">
              Copyright &copy; {moment().format("YYYY")} {clientData[0]?.name}.
              All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

import { useContext } from "react";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { store } from "../../context/store";
import "./HomeMainBanner.css";
import { Skeleton } from "antd";
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronLeft />
    </div>
  );
}
const MainHomeSlider = {
  dots: false,
  infinite: true,
  autoplay: true,
  arrows: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};
export default function HomeMainBanner({ banners, rightBanner }) {
  // const [banner, setBanner] = useState([]);
  const { vertical } = useContext(store);
  // useEffect(() => {
  //   GetApiCall.getRequest("GetBannersV1").then((results) =>
  //     results.json().then((obj1) => {
  //       if (results.status == 200 || results.status == 201) {
  //         var filterBanner = obj1.data.filter(
  //           (item) => item.location === "Main"
  //         );
  //         setBanner(filterBanner);
  //       }
  //     })
  //   );
  // }, []);
  return (
    <div className="px-lg-3">
      <div className="container-fluid pt-2">
        <div className="row pt-1">
          <div className="col-lg-8 pe-lg-0">
            {banners.length > 0 ? (
              <Slider {...MainHomeSlider} className="h-100 home-slider">
                {banners?.map((data) => {
                  return (
                    // <Link className="main-banner websitebanner h-100" to={data.slug}>
                    <img
                      src={data.image.split("$")[0].split("#")[0]}
                      className="img-fluid"
                    />
                    // </Link>
                  );
                })}
              </Slider>
            ) : (
              <div className="home-slider-skeleton">
                <Skeleton.Image className="w-100 h-100"></Skeleton.Image>
              </div>
            )}
          </div>
          <div className="col-lg-4">
            {/* <div className="row h-100"> */}
            {rightBanner.length > 0 ? (
              rightBanner?.slice(0, 1).map((data) => {
                return (
                  <div className="h-100">
                  <Link to={`/`} className="main-banner-right">
                    {/* }`} className="col-6 pe-lg-0 mb-2 pb-1 main-banner-right"> */}
                    <img
                      src={data.image.split("$")[0].split("#")[0]}
                      className="img-fluid"
                    />
                  </Link>
                  </div>
                );
              })
            ) : (
              <div className="">
                <Skeleton.Image className="w-100 img-fluid home-slider-skeleton" />
              </div>
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

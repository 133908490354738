import React, { useContext, useEffect, useState } from "react";
// import Menu from "./Header";
// import Footer from "./Footer";
import { AES } from "crypto-js";
import Notiflix from "notiflix";
import PostApiCall from "../../helpers/Api";
// import Payment_Success from "./assets/img/payment-successful.jpg";
// import Order_Success from "./assets/img/CodOrder/CodPaymentSuccessful.png";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import { IoCheckmarkOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { store } from "../../context/store";

export default function PaymentSuccess() {
  const [shippingAddress, setShippingAddress] = useState([]);
  const [billingAddress, setBillingAddress] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [offerData, setOfferData] = useState([]);

  const [numRegex, setNumRegex] = useState(/^0|[0-9]\d*$/);
  const [mobileRegex, setMobileRegex] = useState(/^[0-9]*$/);
  const [alphaNumericRegex, setAlphaNumericRegex] = useState(/^[a-zA-Z0-9]*$/);
  const [specialRegex, setSpecialRegex] = useState(
    /[-!$%^&*()_+|~=`"{}\[\]:\/;<>?,.@#]/
  );
  const [emailRegex, setEmailRegex] = useState(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const [urlRegex, setUrlRegex] = useState(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  );

  const [txnId, setTxnId] = useState("");
  const [merKey, setMerKey] = useState("a6JOJL");
  const [merSalt, setMerSalt] = useState("cri53U9i");
  const [customerLoginData, setCustomerLoginData] = useState([]);
  const [customerHash, setCustomerHash] = useState("");
  const [mainOrder, setMainOrder] = useState([]);
  const [paymentMode, setPaymentMode] = useState("");
  const [orderId, setOrderId] = useState(null);
  const { setcart, clientData } = useContext(store);
  const { txnid } = useParams();

  var mobile = clientData[0]?.mobile;

  useEffect(() => {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });

    const login = localStorage.getItem("CustomerData");
    const logindetails = JSON.parse(login);
    var orderid = 0;
    if (localStorage.getItem("OrderData") !== null) {
      orderid = Orderdetails[0].order_id;
    }

    Notiflix.Loading.Circle();

    PostApiCall.postRequest(
      {
        whereClause: `where  fld_payment_trx_num='${txnid}'`,
      },
      "GetWebsiteOrderDetails"
    ).then((results1) =>
      results1.json().then((obj) => {
        if (results1.status == 200 || results1.status == 201) {
          // Call getcustomerorderform for get client data
          PostApiCall.postRequest(
            {
              trxid: txnid,
              //   orderid: orderid,
              orderid: "1",
            },
            "GetCustomerOrderForm"
          ).then((results) =>
            results.json().then((obj1) => {
              if (results.status == 200 || results.status == 201) {
                console.log(obj1.data);

                //  Items List
                const items = obj1.data.map((item) => ({
                  name: item.item_name,
                  variant: item.variant_name,
                  quantity: item.fld_quantity,
                  price: item.fld_selling_price,
                  image: item.variant_image.replace(/ /g, "%20"),
                }));

                // For client shipping address according last index
                const lastIndex = obj1.data.length - 1;
                const customershippingplace =
                  obj1.data[lastIndex].fld_shipping_address_all;

                const formattedAddresstest = customershippingplace
                  .replace(/<br\s*\/?>/gi, "\n")
                  .replace(/<\/?span[^>]*>|<\/?b>/g, "")
                  .replace(/<[^>]+>/g, "");

                const formattedAddress = formattedAddresstest.replace(
                  /\n/g,
                  "<br />"
                );

                const customerordernumber =
                  obj1.data[lastIndex].fld_orderNumber;

                Notiflix.Loading.Remove();
                setMainOrder(obj.data);
                setPaymentMode(obj.data[0]?.Payment_Mode);
                setOrderId(obj1.data[0]?.fld_order_id);
                const currentOrderId = obj1.data[0]?.fld_order_id;
                const totalOrderAmount = obj1.data[0]?.Total_Inclusive_Tax;
                SendMailers(
                  totalOrderAmount,
                  items,
                  formattedAddress,
                  customerordernumber,
                  currentOrderId
                ); // get the item amount and send it to the mailer
                localStorage.removeItem("OrderData");
              }
            })
          );
        }
      })
    );
  }, [mobile]);

  // send payment success mail
  const SendMailers = (
    amount,
    productsItems,
    formattedAddress,
    customerordernumber,
    currentOrderId
  ) => {
    Notiflix.Loading.Dots();
    var Order = localStorage.getItem("OrderData");
    var login = localStorage.getItem("CustomerData");
    var Orderdetails = JSON.parse(Order);
    var logindetails = JSON.parse(login);
    var orderid = 0;
    if (localStorage.getItem("OrderData") !== null) {
      orderid = Orderdetails[0].order_id;
    }
    PostApiCall.postRequest(
      {
        ordernumber: txnid,
        customername: logindetails[0].fld_name,
        customeremail: logindetails[0].fld_email,
        amount: amount,
        mobile: mobile,
        orderid: currentOrderId,
        // Add new details for mailer
        products: productsItems,
        shippingaddress: formattedAddress,
        customerordernumber: customerordernumber,
      },
      "CustomerOrderSuccessMailer"
    ).then((results2) =>
      results2.json().then((obj2) => {
        if (results2.status == 200 || results2.status == 201) {
          // console.log('Success')
        }
      })
    );
    PostApiCall.postRequest(
      {
        cartID: orderid,
      },
      "PurgeCart"
    ).then((result) =>
      result.json().then((obj3) => {
        if (result.status == 200 || result.status == 201) {
          setcart(0);
        } else {
          Notiflix.Notify.Failure("Not able to process data");
        }
      })
    );
  };

  var Order = localStorage.getItem("OrderData");
  var Orderdetails = JSON.parse(Order);
  const id = txnid;
  const secretKey = "mel#4321!";
  const encryptedID = AES.encrypt(
    JSON.stringify(id.toString()),
    secretKey
  )?.toString();
  const urlSafeBase64 = encryptedID
    .replace(/\//g, "-")
    .toString()
    .replace("?", "bechofy");

  return (
    <div>
      {/* <Menu></Menu> */}
      <div className="container">
        <div className="row blank-section align-items-center">
          <div className="col-md-12">
            <div className="payment-box text-center">
              {/* <img
								// src={
								// 	paymentMode == "COD"
								// 		? Order_Success
								// 		: Payment_Success
								//}
								src={Paymentsuccess}
								className="paymentFail img-fluid"
								alt="payment success"
								height="550px"
								width="550px"
							></img> */}
              <div className="icon">
                <IoCheckmarkOutline />
              </div>
              <h1 className="mb-4">Payment Successful</h1>
              <div className="mt-3 mb-5 d-flex flex-column flex-md-row align-items-center justify-content-center pl-lg-5">
                <Link
                  to={`/view-invoice-form/${orderId}/${urlSafeBase64}`}
                  className="theme-btn"
                >
                  view order
                </Link>
                <button
                  className="border-0 mt-4 mt-md-0 ms-lg-4 theme-btn"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                >
                  Continue Shopping
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer></Footer> */}
    </div>
  );
}

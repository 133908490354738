import { Skeleton } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import bannerImg from "../../../assets/Images/cat-ban.jpg";
import { store } from "../../../context/store";
import PostApiCall from "../../../helpers/Api";
import ProductCard from "../../ProductCard/ProductCard";
// import christmasnine from "../../../assets/Images/christmas/christmas-nine.png"
import "./CategoryWithBanner.css";
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronLeft />
    </div>
  );
}
export default function CategoryWithBanner(props) {
  const [products, setProducts] = useState([]);
  const { cartItems } = useContext(store);
  useEffect(() => {
    PostApiCall.postRequest(
      { sectionname: "Category2" },
      "GetWebsiteHomePageItems"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        setProducts(obj.data);
      })
    );
  }, []);
  const productsliderdemo = {
    dots: false,
    infinite: false,
    autoplay: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <>
      <section className="px-lg-3">
        <div className="container-fluid category-banner-section">
          <div className="row h-100 align-items-center">
            <div className="d-block d-lg-none mb-4 mt-5 text-center">
              <h2 className="main-heading position-relative">Try our Combos </h2>

              <h3 className="mb-0">
                Double the delight: food and drink harmony
              </h3>
            </div>
            <div className="col-md-6 h-100 position-relative category-left-img-box">
              <img
                src={bannerImg}
                // src="https://store.bechofy.in/images/rubyfood_com_user/banners/2150-No_Image_Available.webp"
                className="category-left-img"
              />
              <div className="category-left-content">
                {/* <div className="section-title mb-4">
                  <h4>
                     {this.props.Categoryname}
                    Gifting Ideas
                    Combos
                  </h4>
                </div> */}
                <p>
                  {/* {this.props.categorydescription} */}
                  {/* From its courteous beginnings in 1962, Ruby Foods has grown
                  into a beloved South Indian Beverage brand and is working
                  towards expanding its reach nationally. */}
                </p>
              </div>
            </div>
            <div className="col-md-8 category-banner-slider">
              <div className="w-100 align-items-end justify-content-between mb-3 category-banner-slider-heading d-none d-lg-flex">
                <div>
                  <h2 className="main-heading position-relative">Try our Combos </h2>
                  <h3 className="mb-0">
                    Double the delight: food and drink harmony
                  </h3>
                </div>
                <a href="/ct/combos/1/3" className="theme-btn d-none d-lg-block">View All</a>

                {/* <Link to="ct/combos/1/3" className="theme-btn">View All</Link> */}
              </div>
              <div className="arrow-section product-card-section">
                {products.length > 0 ? (
                  <Slider {...productsliderdemo}>
                    {products.map((item, index) => {
                      const isInCart = cartItems.find((val) => {
                        if (item.fld_variantid === val.fld_variantid) {
                          return val;
                        }
                      });
                      return (
                        <ProductCard
                          itemdata={item}
                          isInCart={isInCart}
                          key={index}
                        />
                      );
                    })}
                  </Slider>
                ) : (
                  <div className="main-slider-box category-banner-skeleton justify-content-between d-flex flex-wrap w-100">
                    <div className="item">
                      <div className="product-box">
                        <Skeleton.Image className="bg-light w-100 h-100" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="product-box">
                        <Skeleton.Image className="bg-light w-100 h-100" />
                      </div>
                    </div>
                    <div className="item d-none d-lg-block">
                      <div className="product-box">
                        <Skeleton.Image className="bg-light w-100 h-100" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-5 text-center d-block d-lg-none">
                <a href="/ct/combos/1/3" className="theme-btn">View All</a>
                {/* <Link to="ct/combos/1/3" className="theme-btn">View All</Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import { useEffect } from "react";
import about from "../../assets/Images/about.jpg";
import vision from "../../assets/Images/vision.png";
import GetSeoData from "../../helpers/GetSeoData";
import smallmission from "../../assets/Images/small-mission.png"
import "./AboutUsPage.css";

export default function AboutUsPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <GetSeoData type="About Us" id={null} />
      <div className="px-lg-3 my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="heading-style mb-4">
                <h1 className="main-heading mb-0">About Us</h1>
                <h3 className="mb-0">
                  At Ruby Foods, we celebrate the real goodness of nature’s best
                  gifts i.e., fruits and nuts.
                </h3>
              </div>
            </div>
            <div className="col-lg-5 mb-4 mb-lg-0">
              <img
                src={about}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-7 pt-lg-4">
              <h2>
                {" "}
                We believe that real goodness requires a really good drink to go
                with it.
              </h2>
              <p>
                It all started in 1962, when Mr. Srinivasan desired to create a
                beverage that he could feel good about serving to people.
                Selling his home-made grape juice at a teeny-tiny local shop in
                Madurai, he made his way up to the leaders in beverage market.
                Today, Ruby basks in its own 5-decade-old glory of being
                renowned in some of the prominent cities of South India.
              </p>
              <p>
                Over the years, we have modified ourselves in multiple ways in
                order to match the consumer requirements; such as adopting the
                latest trends in Processing Technology and Packaging, expanding
                our reach to cities across South India and so much more.
                Nevertheless, one thing remains the same, we stay true to our
                original purpose: Help people taste the best that nature has to
                offer.
              </p>
              <p>
                We do this by simply doing what we do the best: blending the
                finest fruits, nuts, and dairy that Mother Nature offers into
                the tastiest, most nutritious juices one can imagine. We always
                make sure that this happens following the best food standards in
                the most sustainable ways possible.
              </p>
              <p>
                Headquartered in Madurai, the Ruby team is obsessively involved
                with the entire process in the creation of the finest and
                healthiest drinks. From visiting, sourcing the best-tasting
                fruit, to being on-site for every single production run, to
                tasting each and every batch, we love the challenge that comes
                from working with real ingredients and making a product that
                stands out in a crowd.
              </p>
              <h3>We create it, we share it, we love it.</h3>
            </div>
            <div className="col-12"></div>
          </div>
        </div>
      </div>
      {/* <div className="px-lg-3 distributors-section mb-4 pb-4">
        <div class="about-ruby container-fluid position-relative">
          
          <div class="row justify-content-center">
            <div
              class="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
              data-wow-duration="1500ms">
              <div class="about-ruby-item text-center position-relative">
                <span class="serial d-flex justify-content-center align-items-center position-absolute">
                  01
                </span>
                <div class="inner-icon position-relative d-flex justify-content-center align-items-center">
                  <i className="fa fa-phone"></i>
                </div>
                <div class="inner-text headline pera-content">
                  <a href="tel:+91994016014">
                    <h4>Call +91 99401 60145</h4>
                  </a>
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="600ms"
              data-wow-duration="1500ms">
              <div class="about-ruby-item text-center position-relative">
                <span class="serial d-flex justify-content-center align-items-center position-absolute">
                  02
                </span>
                <div class="inner-icon position-relative d-flex justify-content-center align-items-center">
                  <i className="fa fa-users"></i>
                </div>
                <div class="inner-text headline pera-content">
                  <h4>Discuss on the value propositions</h4>
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="800ms"
              data-wow-duration="1500ms">
              <div class="about-ruby-item text-center position-relative">
                <span class="serial d-flex justify-content-center align-items-center position-absolute">
                  03
                </span>
                <div class="inner-icon position-relative d-flex justify-content-center align-items-center">
                  <i className="fa fa-product-hunt"></i>
                </div>
                <div class="inner-text headline pera-content">
                  <h4>Get product samples to taste</h4>
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="800ms"
              data-wow-duration="1500ms">
              <div class="about-ruby-item text-center position-relative">
                <span class="serial d-flex justify-content-center align-items-center position-absolute">
                  03
                </span>
                <div class="inner-icon position-relative d-flex justify-content-center align-items-center">
                  <i className="fa fa-handshake-o"></i>
                </div>
                <div class="inner-text headline pera-content">
                  <h4>Shake hands on becoming the distributor</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="vm-section mt-4">
        <div className="svg-shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none">
            <path
              fill="#fff"
              d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
          </svg>
        </div>
        <div className="px-lg-3 vision-mission-section pb-4">
          <div className="container-fluid pb-4 py-2">
            <div className="row">
              <div className="col-lg-9 text-white">
                <h3 className="mb-3">
                  Our vision is to stand as a synonym of health and wellness
                  nationally and it is what we determine to cater through our
                  line of Ruby Food Products.
                </h3>
                <ul className="vm-divider">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
                <h5 className="mt-4 mt-lg-5">
                  <p>
                    Our mission is to constantly manufacture wholesome drinks
                    from the purest of the available nature’s goods and make
                    them available to all the consumers in extremely
                    cost-effective rates.
                  </p>
                  <i>
                    “Authentic nutrition and freshness are our top priorities,
                    which is why our juices are of the highest quality.”
                  </i>
                </h5>
              </div>
              <div className="col-lg-3">
                <div className="vm-right-img">
                  <img
                    src={smallmission}
                    alt="drink image "
                    className="img-fluid"
                  />
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="vm-card">
                  <div className="icon">
                    <img src={vision} className="img-fluid"></img>
                  </div>
                  <h2 className="mt-3">Vision</h2>

                  <p>
                    Our vision is to stand as a synonym of health and wellness
                    nationally and it is what we determine to cater through our
                    line of Ruby Food Products.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="vm-card mt-lg-0 mt-2">
                  <div className="icon">
                    <img src={mission} className="img-fluid"></img>
                  </div>
                  <h2 className="mt-3">Mission</h2>

                  <p>
                    Our mission is to constantly manufacture wholesome drinks
                    from the purest of the available nature’s goods and make
                    them available to all the consumers in extremely
                    cost-effective rates.
                  </p>
                  <p>
                    “Authentic nutrition and freshness are our top priorities,
                    which is why our juices are of the highest quality.”
                  </p>
                </div>
              </div> */}
              <div className="col-12 vm-bottom-img-section">
                <div className="row">
                  <div className="col-6 col-md-4 col-lg-2">
                    <img
                      src="https://store.bechofy.in/images/rubyfood_com_user/ProductImages/328911-12.webp"
                      alt=""
                      className="img-fluid vm-bottom-img-left"
                    />
                  </div>
                  <div className="col-6 col-md-4 col-lg-2">
                    <img
                      src="https://store.bechofy.in/images/rubyfood_com_user/ProductImages/805243-19-min.webp"
                      alt=""
                      className="img-fluid vm-bottom-img-right"
                    />
                  </div>
                  <div className="col-6 col-md-4 col-lg-2">
                    <img
                      src="https://store.bechofy.in/images/rubyfood_com_user/ProductImages/805243-18-min.webp"
                      alt=""
                      className="img-fluid vm-bottom-img-left"
                    />
                  </div>
                  <div className="col-6 col-md-4 col-lg-2">
                    <img
                      src="https://store.bechofy.in/images/rubyfood_com_user/ProductImages/328911-11.webp"
                      alt=""
                      className="img-fluid vm-bottom-img-right"
                    />
                  </div>
                  <div className="col-6 col-md-4 col-lg-2">
                    <img
                      src="https://store.bechofy.in/images/rubyfood_com_user/ProductImages/328911-13.webp"
                      alt=""
                      className="img-fluid vm-bottom-img-left"
                    />
                  </div>
                  <div className="col-6 col-md-4 col-lg-2">
                    <img
                      src="https://store.bechofy.in/images/rubyfood_com_user/ProductImages/582942-ruby%20foods%20(latest)-2%20(1).webp"
                      alt=""
                      className="img-fluid vm-bottom-img-right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

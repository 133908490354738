import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useState } from "react";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import LoginModal from "../Login/LoginModal";
import "./AddToCart.css";

function AddToCart({ itemid }) {
	const { setcart, setCartItems } = useContext(store)
	const [isOpen, setIsOpen] = useState(false)
	const [modalSide, setModalSide] = useState("Log")

	const handleToggleModal = () => {
		setIsOpen(false)
		setModalSide("Log")
	}

	const handleChangeModalSide = (e) => {
		setModalSide(e)
	}

	const GetCartdetails = () => {
		var Order = localStorage.getItem("OrderData");
		var OrderData = JSON.parse(Order);
		var orderid = 0;
		if (localStorage.getItem("OrderData") !== null) {
			orderid = OrderData[0].order_id
		}
		if (localStorage.getItem("CustomerData") === null) {
		} else {
			var login = localStorage.getItem("CustomerData");
			var logindetails = JSON.parse(login);
			if (localStorage.getItem("OrderData") !== null) {
				var Order = localStorage.getItem("OrderData");
				var OrderData = JSON.parse(Order);
				PostApiCall.postRequest(
					{
						orderid: localStorage.getItem("OrderData") == null
							? null
							: OrderData[0].order_id,
						id: localStorage.getItem("CustomerData") == null
							? null
							: logindetails[0].fld_customerid,
					},
					"GetCartWebsite"
				)
					.then((res) => res.json())
					.then((res1) => {
						if (res1.data) {
							setcart((res1.data.reduce((a, v) => a = a + v.fld_quantity, 0)))
							setCartItems(res1.data)
						}
					});
			}
		}
	};

	const addtocart = () => {
		Notiflix.Loading.Dots("");
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		var Order = localStorage.getItem("OrderData");
		var OrderData = JSON.parse(Order);
		if (logindetails != null) {
			var logindetails = JSON.parse(login); //parse localstorage details
		}
		if (logindetails === null) {
			localStorage.setItem(
				"itemdetails",
				JSON.stringify([
					{
						Item: `${itemid}`,
						Quantity: `${1}`,
					},
				])
			);
			Notiflix.Loading.Remove()
			setIsOpen(true) // open login modal
		} else {
			// ===========with Login=========
			PostApiCall.postRequest(
				{
					orderdate: moment().format("lll"),
					itemdetails: `
                     [
                     {
                         "Item":${itemid
						},
                         "Quantity":${1}
                     }
                     ]
                     `,
					status: "INCART",
					customerid: localStorage.getItem("CustomerData") == null ? null : logindetails[0].fld_customerid,
					createdon: moment().format("lll"),
					updatedon: moment().format("lll"),
					orderid: localStorage.getItem("OrderData") == null ? 0 : OrderData[0].order_id,
					updatedby: localStorage.getItem("CustomerData") == null ? null : logindetails[0].fld_userid,
				},
				"AddToCartWebsite"
			).then((result) =>
				result.json().then((obj) => {
					if (result.status == 200 || result.status == 201) {
						Notiflix.Notify.Success("Product added to Cart.");
						Notiflix.Loading.Remove();
						if (localStorage.getItem("OrderData") == null) {
							localStorage.setItem("OrderData", JSON.stringify(obj.data));
							GetCartdetails();
						} else {
							GetCartdetails();
						}
					} else {
						Notiflix.Loading.Remove();
						Notiflix.Notify.Failure(obj.data);
					}
				})
			);
		}
	}

	return (
		<>
			<LoginModal
				isOpen={isOpen}
				handleToggleModal={handleToggleModal}
				modalSide={modalSide}
				handleChangeModalSide={handleChangeModalSide}
			/>
			<span onClick={addtocart}>Add to Cart</span>
		</>
	)
}
export default AddToCart;
import { createBrowserHistory } from "history";
import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import AboutUsPage from "./components/AboutUsPage/AboutUsPage";
import CareersPage from "./components/CareersPage/CareersPage";
import Cart from "./components/Cart/Cart";
import ContactUs from "./components/ContactUS/ContactUs";
import AddressBookForm from "./components/CustomerDashboard/AddressBookForm";
import CustomerProfilePage from "./components/CustomerDashboard/CustomerProfilePage";
import EditAddress from "./components/CustomerDashboard/EditAddress";
import EditProfile from "./components/CustomerDashboard/EditProfile";
import DistributorsPage from "./components/DistributorsPage/DistributorsPage";
import Footer from "./components/Footer/Footer";
import GalleryPage from "./components/GalleryPage/GalleryPage";
import ViewInvoiceForm from "./components/Invoice/ViewInvoiceForm";
import FixedNavbar from "./components/MainNavabar/FixedNavbar";
import ForgotPassword from "./components/Pages/ForgetPassword";
import PageNotFound from "./components/Pages/PageNotFound";
import PaymentFail from "./components/Pages/PaymentFail";
import PaymentSuccess from "./components/Pages/PaymentSuccess";
import ViewAllOrders from "./components/CustomerDashboard/ViewAllOrders";
import StaticPages from "./components/Pages/StaticPages";
import ProductDetail from "./components/ProductDetails/ProductDetail";
import Productlisting from "./components/ProductListing/ProductListing";
import WriteAReview from "./components/ProductReviews/WriteReview";
import TopBar from "./components/TopBar/TopBar";
import Provider from "./context/store";
import GetApiCall from "./helpers/GetApi";
import "./index.css";
import Faq from "./pages/FAQ/Faq";
import Home from "./pages/Home";
import ProtectedRoutes from "./components/RoutesAuth/ProtectedRoutes";
// import ChatForm from "./components/ChatForm/ChatForm";

const history = createBrowserHistory();

function App() {
  const [staticPages, setStaticPages] = useState([]);
  useEffect(() => {
    if (localStorage.getItem("staticPagesList")) {
      var list = JSON.parse(localStorage.getItem("staticPagesList"));
      setStaticPages(list);
    } else {
      GetApiCall.getRequest("GetStaticPagesList").then((results) =>
        results.json().then((obj1) => {
          if (results.status == 200 || results.status == 201) {
            setStaticPages(obj1.data);
            localStorage.setItem("staticPagesList", JSON.stringify(obj1.data));
          }
        })
      );
    }
  }, []);

  return (
    <Provider>
      <Suspense fallback={<div></div>}>
        <Router history={history}>
          <TopBar />
          <FixedNavbar />
          <Switch>
            {staticPages?.map((dat, index) => {
              return (
                <Route
                  path={[dat.fld_route]}
                  key={index}
                  component={StaticPages}
                ></Route>
              );
            })}

            {/* ******** Redirection Old ruby Pages with New Component *********** */}
            <Route path="/index.aspx" exact>
              <Redirect status={301} to="/"></Redirect>
            </Route>
            <Route path="/about.html" exact>
              <Redirect status={301} to="/aboutus"></Redirect>
            </Route>
            <Route path="/products.html" exact>
              <Redirect status={301} to="/ct/beverages/1/1"></Redirect>
            </Route>
            <Route path="/brand-badam.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Ruby-Badam-180-ml/1"
              ></Redirect>
            </Route>
            <Route path="/rose.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Ruby-Rose-Milk-180-ml/2"
              ></Redirect>
            </Route>
            <Route path="/brand-pista.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Ruby-Pista-180-ml/3"
              ></Redirect>
            </Route>
            <Route path="/cashew-milk.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Ruby-Cashew-180-ml/14"
              ></Redirect>
            </Route>
            <Route path="/jig.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Ruby-Jigarthanda-180-ml/7"
              ></Redirect>
            </Route>
            <Route path="/brand-coffee.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Coffeeshot-180-ml/6"
              ></Redirect>
            </Route>
            <Route path="/brand-mango.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Ruby-Mango-250-ml/8"
              ></Redirect>
            </Route>
            <Route path="/brand-milkybadam.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Milkee-Badam-180-ml/16"
              ></Redirect>
            </Route>
            <Route path="/ruby-mango.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Ruby-Mango-250-ml/8"
              ></Redirect>
            </Route>
            <Route path="/vanilla-milkshake.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Ruby-Vanila-Milkshake-180-ml/4"
              ></Redirect>
            </Route>
            <Route path="/chocolate-milkshake.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Ruby-Choclate-Milkshake-180-ml/5"
              ></Redirect>
            </Route>
            <Route path="/zappy-apple-juice.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Zappy-Apple-Juice-250-ml/9"
              ></Redirect>
            </Route>
            <Route path="/coco-exotic-fusion-lycee.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Mr.-Coco-Exotic-Fusion---Lychee-250-ml/25"
              ></Redirect>
            </Route>
            <Route path="/basicly-lychee.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Bascily-Lychee-250-ml/23"
              ></Redirect>
            </Route>
            <Route path="/strawberry.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Ruby-Strawberry-Milkshake-180-ml/24"
              ></Redirect>
            </Route>
            <Route path="/coco-bliss.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Coco-Bliss-Neera-180-ml/27"
              ></Redirect>
            </Route>
            <Route path="/basicly-blueberry.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Bascily-Blueberry-250-ml/21"
              ></Redirect>
            </Route>
            <Route path="/coco-exotic-fusion-blueberry.html" exact>
              <Redirect status={301} to="/ct/beverages/1/1"></Redirect>
            </Route>
            <Route path="/milkee-vanilla.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Milkee-Vanilla-180-ml/28"
              ></Redirect>
            </Route>
            <Route path="/milkee-choco.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Milkee-Choco-180-ml/29"
              ></Redirect>
            </Route>
            <Route path="/milkee-badam.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Milkee-Badam-180-ml/16"
              ></Redirect>
            </Route>
            <Route path="/muruku.html" exact>
              <Redirect status={301} to="/ct/snacks/1/2"></Redirect>
            </Route>
            <Route path="/moru-moru-garlic-muruku.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Moru-Moru-Garlic-Murukku-140-gm/13"
              ></Redirect>
            </Route>
            <Route path="/moru-moru-manaparai-muruku.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Moru-Moru-Manapaarai-Murukku-140-gm/17"
              ></Redirect>
            </Route>
            <Route path="/moru-moru-thenkulal-muruku.html" exact>
              <Redirect status={301} to="/ct/snacks/1/2"></Redirect>
            </Route>
            <Route path="/moru-moru-seeraga-samba-sev-muruku.html" exact>
              <Redirect
                status={301}
                to="/productdetail/Moru-Moru-Seeraga-Samba-Sev-Murukku-140-gm/20"
              ></Redirect>
            </Route>
            <Route path="/distributor.aspx" exact>
              <Redirect status={301} to="/distributor"></Redirect>
            </Route>
            <Route path="/our-story.html" exact>
              <Redirect status={301} to="/aboutus"></Redirect>
            </Route>
            <Route path="/faq.html" exact>
              <Redirect status={301} to="/faq"></Redirect>
            </Route>
            <Route path="/careers.aspx" exact>
              <Redirect status={301} to="/careers"></Redirect>
            </Route>
            <Route path="/contact.aspx" exact>
              <Redirect status={301} to="/contact-us"></Redirect>
            </Route>
            <Route path="/RubyFoodProducts/" exact>
              <Redirect status={301} to="/ct/beverages/1/1"></Redirect>
            </Route>

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={Home}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/cart`}
              component={Cart}
            ></Route>
            {/* <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/cart`}
              component={Cart}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            ></ProtectedRoutes> */}
            <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/paymentsuccess/:txnid`}
              component={PaymentSuccess}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            ></ProtectedRoutes>
            <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/view-all-orders`}
              component={ViewAllOrders}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            ></ProtectedRoutes>
            <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/paymentfailed`}
              component={PaymentFail}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            ></ProtectedRoutes>
            <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/customerprofile`}
              component={CustomerProfilePage}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            ></ProtectedRoutes>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/productdetail/:productname/:variantid`}
              component={ProductDetail}
            ></Route>
            <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/writereview/:variantid`}
              component={WriteAReview}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            ></ProtectedRoutes>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/forgotpassword`}
              component={ForgotPassword}
            ></Route>
            <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/editprofile`}
              component={EditProfile}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            ></ProtectedRoutes>

            <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/cart`}
              component={Cart}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            ></ProtectedRoutes>
            <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/paymentsuccess`}
              component={PaymentSuccess}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            ></ProtectedRoutes>
            <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/paymentfailed`}
              component={PaymentFail}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            ></ProtectedRoutes>
            <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/addressbookform`}
              component={AddressBookForm}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            ></ProtectedRoutes>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact-us`}
              component={ContactUs}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/faq`}
              component={Faq}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/aboutus`}
              component={AboutUsPage}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/distributor`}
              component={DistributorsPage}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/gallery`}
              component={GalleryPage}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/careers`}
              component={CareersPage}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/ct/:categoryname/:verticalid/:categoryid`}
              component={Productlisting}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/view-invoice-form/:id/:orderid`}
              component={ViewInvoiceForm}
            />

            {/* <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/view-invoice-form/:id/:orderid`}
              component={ViewInvoiceForm}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            /> */}
            <ProtectedRoutes
              exact
              path={`${process.env.PUBLIC_URL}/editaddress`}
              component={EditAddress}
              isAuth={
                localStorage.getItem("CustomerData") == null ? false : true
              }
            />

            <Route component={PageNotFound} />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/PageNotFound`}
              component={PageNotFound}
            />
          </Switch>

          <Footer />

          {/* <ChatForm /> */}
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;

import { Drawer, Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { store } from "../../../context/store";
import AddToCartQty from "../../AddToCartQty/AddToCartQty";
import AddToCart from "../../Helpers/AddToCart";
import "./CategorySectionGrid.css";

export default function CategorySectionGrid({ categoryData, banners }) {
  const [banner, setBanner] = useState([]);
  const { cartItems, storeStatus } = useContext(store);
  const [shuffledImg, setShuffledImg] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [imgDrawer, setImgDrawer] = useState("");
  const [open, setOpen] = useState(false);
  const [tempId, setTempId] = useState(null);
  const [tempData, setTempData] = useState([]);
  const [temp, setTemp] = useState(null);
  const [tempName, setTempName] = useState("");
  const [tempDiscPrice, setTempDiscPrice] = useState("");
  const [tempMrp, setTempMrp] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    var Randomdata = categoryData.sort(() => Math.random() - 0.5);
    setShuffledImg(Randomdata);
  }, [categoryData]);

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="px-lg-3 pb-4">
        <div className="container-fluid pt-2">
          <div className="row pt-1">
            <div className="col-lg-6 pe-lg-0 mb-2 mb-lg-0">
              {/*  big image on the left side */}
              {shuffledImg.length > 0 ?
                shuffledImg?.slice(4, 5)?.map((data) => {
                  const isInCart = cartItems.find((item) => {
                    if (item.fld_variantid === data.fld_variantid) {
                      return item;
                    }
                  });
                  return (
                    <div className="category-section-card category-section-card-left">
                      <Link
                        to={`/productdetail/${data.fld_itemname.replace(
                          /\s/g,
                          "-"
                        )}-${data.fld_variantname?.replace(/\s/g, "-")}/${data.fld_variantid
                          }`}
                        className="col-6 pe-lg-0 mb-2 pb-1 category-section-grid-right">
                        <img
                          src={data.VariantImage}
                          className="img-fluid w-100"
                        />
                      </Link>

                      <div className="content">
                        <p>{data.fld_itemname}</p>
                        <span
                          className="d-none d-lg-block"
                          onClick={() => {
                            if (data.variant_list.split("#").length > 1) {
                              setIsOpen(true);
                            }
                            setTempName(data.fld_variantname);
                            setTempMrp(data.fld_mrp);
                            setTempDiscPrice(data.fld_website_discount_price);
                            setImage(data.VariantImage);
                            setTemp(data.variant_list);
                          }}>
                          {data.fld_variantname1}
                          {data.variant_list.split("#").length > 1 && (
                            <IoIosArrowDown />
                          )}
                        </span>
                        <span
                          onClick={() => {
                            setTemp(data.variant_list);
                            setImage(data.VariantImage);
                            if (data.variant_list.split("#").length > 1) {
                              setOpen(true);
                            }
                          }}
                          className="d-block d-lg-none">
                          {data.fld_variantname1}{" "}
                          {data.variant_list.split("#").length > 1 && (
                            <IoIosArrowDown />
                          )}
                        </span>
                        <hr className="mb-2 mt-1" />
                        <div className="d-flex justify-content-between align-items-end">
                          <p className="disc-price">
                            ₹ {data.fld_website_discount_price}
                            {data.fld_mrp != data.fld_website_discount_price && (
                              <del>₹ {data.fld_mrp}</del>
                            )}
                          </p>
                          {storeStatus == "Active" &&
                            <>
                              {isInCart ? (
                                <AddToCartQty
                                  itemid={data.fld_variantid}
                                  quantity={isInCart.fld_quantity}
                                  cartlineid={isInCart.fld_cart_dtl_id}
                                />
                              ) : (
                                <span className="theme-btn cursor-pointer">
                                  <AddToCart itemid={data.fld_variantid} />
                                </span>
                              )}
                            </>}
                        </div>
                      </div>
                    </div>
                  );
                }) :
                <div className="col-6 pe-lg-0 mb-2 pb-1 category-section-grid-right w-100 h-100">
                  <Skeleton.Image className="img-fluid w-100 h-100" />
                </div>
              }
              {/* {banners?.slice(0, 1).map((data) => {
                return (
                  <Link to={data.slug}>
                    <img
                      src={data.image.split("$")[0].split("#")[0]}
                      className="img-fluid category-section-grid-left"
                    />
                  </Link>
                );
              })} */}
            </div>
            <div className="col-lg-6 pe-lg-4">
              <div className="row category-section-grid-left">
                {/* four small random image grid on the right */}
                {shuffledImg.length > 0 ? shuffledImg?.slice(0, 4)?.map((data) => {
                  const isInCart = cartItems.find((item) => {
                    if (item.fld_variantid === data.fld_variantid) {
                      return item;
                    }
                  });
                  return (
                    <div className="col-6 pe-lg-0 mb-2 pb-1 category-section-grid-right">
                      <div className="category-section-card">
                        <Link
                          to={`/productdetail/${data.fld_itemname.replace(
                            /\s/g,
                            "-"
                          )}-${data.fld_variantname?.replace(/\s/g, "-")}/${data.fld_variantid
                            }`}>
                          <img
                            src={data.VariantImage}
                            className="img-fluid w-100"
                          />
                        </Link>

                        <div className="content">
                          <p>{data.fld_itemname}</p>
                          <span
                            className="d-none d-lg-block"
                            onClick={() => {
                              if (data.variant_list.split("#").length > 1) {
                                setIsOpen(true);
                              }
                              setTemp(data.variant_list);
                              setImage(data.VariantImage);
                              setTempName(data.fld_variantname);
                              setTempDiscPrice(data.fld_website_discount_price);
                              setTempMrp(data.fld_mrp);
                            }}>
                            {data.fld_variantname1}{" "}
                            {data.variant_list.split("#").length > 1 && (
                              <IoIosArrowDown />
                            )}
                          </span>
                          <span
                            onClick={() => {
                              setTemp(data.variant_list);
                              if (data.variant_list.split("#").length > 1) {
                                setOpen(true);
                              }
                            }}
                            className="d-block d-lg-none">
                            {data.fld_variantname1}{" "}
                            {data.variant_list.split("#").length > 1 && (
                              <IoIosArrowDown />
                            )}
                          </span>
                          <hr className="mb-2 mt-1" />
                          <div className="d-lg-flex justify-content-between align-items-end">
                            <p className="disc-price">
                              ₹&nbsp; {data.fld_website_discount_price}
                              {data.fld_mrp !=
                                data.fld_website_discount_price && (
                                  <del>₹&nbsp; {data.fld_mrp}</del>
                                )}
                            </p>
                            {storeStatus == "Active" &&
                              <>
                                {isInCart ? (
                                  <AddToCartQty
                                    itemid={data.fld_variantid}
                                    quantity={isInCart.fld_quantity}
                                    cartlineid={isInCart.fld_cart_dtl_id}
                                  />
                                ) : (
                                  <span className="theme-btn cursor-pointer">
                                    <AddToCart itemid={data.fld_variantid} />
                                  </span>
                                )}
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }) :
                  <div className="col-lg-6 pe-lg-4">
                    <div className="row category-section-grid-left">
                      <div className="col-6 pe-lg-0 mb-2 pb-1 category-section-grid-right">
                        <div className="category-section-card">
                          <Skeleton.Image className="img-fluid w-100 h-100" />
                        </div>
                      </div>
                      <div className="col-6 pe-lg-0 mb-2 pb-1 category-section-grid-right">
                        <div className="category-section-card">
                          <Skeleton.Image className="img-fluid w-100 h-100" />
                        </div>
                      </div>
                      <div className="col-6 pe-lg-0 mb-2 pb-1 category-section-grid-right">
                        <div className="category-section-card">
                          <Skeleton.Image className="img-fluid w-100 h-100" />
                        </div>
                      </div>
                      <div className="col-6 pe-lg-0 mb-2 pb-1 category-section-grid-right">
                        <div className="category-section-card">
                          <Skeleton.Image className="img-fluid w-100 h-100" />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  modal section */}
      <Modal
        show={isOpen}
        centered
        onHide={() => {
          handleClose();
          setTempData([]);
        }}>
        <Modal.Header closeButton className="border-0 pb-0">
          <h5 className="mb-0">{tempName}</h5>
        </Modal.Header>
        <Modal.Body className="">
          {temp?.split("#")?.map((dat) => {
            const InCart = cartItems.find((item) => {
              if (item.fld_variantid == dat.split("$")[1].split(",")[1]) {
                return item;
              }
            });
            return (
              <div className="variant-modal-card">
                <div className="d-flex align-items-center">
                  <img
                    src={dat.split("$")[1].split(",")[7].split("~")[0]}
                    className="img-fluid"
                  />
                  <p>{dat.split("$")[1].split(",")[0]}</p>
                </div>
                <p className="">
                  ₹ {dat.split("$")[1].split(",")[3]}{" "}
                  {dat.split("$")[1].split(",")[2] >
                    dat.split("$")[1].split(",")[3] && (
                      <del>₹&nbsp;{dat.split("$")[1].split(",")[2]}</del>
                    )}
                </p>
                {storeStatus == "Active" &&
                  <>
                    {InCart ? (
                      <AddToCartQty
                        itemid={dat.split("#")[0].split("$")[1].split(",")[1]}
                        quantity={InCart.fld_quantity}
                        cartlineid={InCart.fld_cart_dtl_id}
                      />
                    ) : (
                      <span className="theme-btn cursor-pointer">
                        <AddToCart
                          itemid={dat.split("#")[0].split("$")[1].split(",")[1]}
                        />
                      </span>
                    )}
                  </>
                }
              </div>
            );
          })}
        </Modal.Body>
      </Modal>

      {/* drawer section for mobile */}
      <Drawer
        className="variant-drawer-mobile"
        title={tempName}
        onClose={() => setOpen(false)}
        open={open}
        placement="bottom">
        {temp?.split("#")?.map((dat) => {
          const InCart = cartItems.find((item) => {
            if (item.fld_variantid == dat.split("$")[1].split(",")[1]) {
              return item;
            }
          });
          return (
            <div className="variant-modal-card">
              <div className="d-flex align-items-center">
                <img
                  src={dat.split("$")[1].split(",")[7].split("~")[0]}
                  className="img-fluid"
                />
                <p>{dat.split("$")[1].split(",")[0]}</p>
              </div>
              <p className="ms-lg-5 ps-lg-4">
                ₹&nbsp;{dat.split("$")[1].split(",")[3]}{" "}
                {dat.split("$")[1].split(",")[2] >
                  dat.split("$")[1].split(",")[3] && (
                    <del>₹&nbsp;{dat.split("$")[1].split(",")[2]}</del>
                  )}
              </p>
              {storeStatus == "Active" &&
                <>
                  {InCart ? (
                    <AddToCartQty
                      itemid={dat.split("#")[0].split("$")[1].split(",")[1]}
                      quantity={InCart.fld_quantity}
                      cartlineid={InCart.fld_cart_dtl_id}
                    />
                  ) : (
                    <>
                      <span className="theme-btn cursor-pointer">
                        <AddToCart
                          itemid={dat.split("#")[0].split("$")[1].split(",")[1]}
                        />
                      </span>
                    </>
                  )}
                </>
              }
            </div>
          );
        })}
      </Drawer>
    </>
  );
}

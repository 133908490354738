import React, { useContext, useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { store } from "../../context/store";
import GetSeoData from "../../helpers/GetSeoData";
import ContactTabs from "./ContactTabs";
import "./ContactUs.css";
export default function ContactUs() {
  const { clientData } = useContext(store);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <GetSeoData type="Contact Us" id={null} />
      <main className="main px-lg-3 my-4">
        <div className="container-fluid">
          <div className="contact-box">
            <div className="row">
              <div className="col-12 mb-2">
                <div className="heading-style mb-3">
                  <h1 className="main-heading mb-0">Contact Us</h1>
                </div>
              </div>
              <ContactTabs />

              <div className="col-lg-4 mb-4 mt-lg-4">
                <div className="conatct-link-card">
                  <FaLocationDot />
                  <div className="links">
                    <h5>Address</h5>
                    <p className="mb-0 fw-5">{clientData[0]?.name}</p>
                    <p>{clientData[0]?.address}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mt-lg-4">
                <div className="conatct-link-card">
                  <IoCall />
                  <div className="links">
                    <h5>Call Us</h5>
                    <a href={`tel:${clientData[0]?.mobile}`}>
                      {clientData[0]?.mobile}
                    </a>
                    {/* <a href={`tel:+916385333361`}>+91 63853 33361</a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mt-lg-4">
                <div className="conatct-link-card">
                  <IoMdMail />
                  <div className="links">
                    <h5>Email us</h5>
                    <a href={`mailto:${clientData[0]?.email}`}>
                      {clientData[0]?.email}
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-lg-0 mt-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62878.47424441458!2d78.149363!3d9.941892!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00cf6edb6d0533%3A0x53ab105d49fa5ec8!2sRuby%20Food%20Products%20Pvt%20Ltd%20%7C%20Beverage%20%7C%20Fruit%20Drinks%20%7C%20Flavoured%20Milk!5e0!3m2!1sen!2sin!4v1712915119556!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

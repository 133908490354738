import { Skeleton } from "antd";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import GetSeoData from "../../helpers/GetSeoData";
import ProductCard from "../ProductCard/ProductCard";
import "./ProductListing.css";

export default function Productlisting() {
  const params = useParams();
  const [Items, setItems] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const { cartItems } = useContext(store)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    getItems();
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });
  }, [params.categoryid]);

  const getItems = () => {
    PostApiCall.postRequest(
      {
        categoryid: parseInt(params.categoryid),
        stateid: null,
      },
      "Get_All_Items"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setItems(obj1.data.filter((data) => data.fld_status == "Active"));
          setDataLoading(false);
        }
      })
    );
  };

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return str;
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      <GetSeoData type="Category" id={params.categoryid} />
      <div className="">
        <>
          <section className="page-padding products-inner px-lg-3">
            <div className="container-fluid pt-4">
              <div className="row vertical-product-height mb-5">
                <div className=" col-12">
                  <div className="section-title heading-style text-center position-relative mb-4">
                    <h1 className="section-title-category-section productlisting-heading main-heading mb-1">
                      {capitalizeFirstLetter(params.categoryname)}
                    </h1>
                  </div>
                  <div className="row vertical-section product-card-section product-listing-box mt-4 mt-lg-0">
                    {Items.length > 0
                      ? Items
                        .map((val) => {
                          const isInCart = cartItems.find((item) => {
                            if (item.fld_variantid === val.fld_variantid) {
                              return item
                            }
                          });
                          return (
                            <div
                              key={val.fld_variantid}
                              className="product-listing-card col-6 col-lg-3 mb-4 px-2 px-md-auto">
                              <ProductCard itemdata={val} isInCart={isInCart} />
                            </div>
                          );
                        })
                      : dataLoading === false && (
                        <div className="d-flex justify-content-center align-items-center w-100 listingpage-height blank-section">
                          Items you are searching for are not availble
                        </div>
                      )}
                    {dataLoading === true && (
                      <div className="row blank-section">
                        <div className="item col-6 col-lg-3">
                          <div className="product-box d-flex flex-column">
                            <Skeleton.Image className="w-100" />
                            <Skeleton.Button className="w-75 mt-2" />
                            <Skeleton.Button className="w-100 mt-2" />
                          </div>
                        </div>
                        <div className="item col-6 col-lg-3">
                          <div className="product-box d-flex flex-column">
                            <Skeleton.Image className="w-100" />
                            <Skeleton.Button className="w-75 mt-2" />
                            <Skeleton.Button className="w-100 mt-2" />
                          </div>
                        </div>
                        <div className="item col-lg-3 d-none d-lg-block">
                          <div className="product-box d-flex flex-column">
                            <Skeleton.Image className="w-100" />
                            <Skeleton.Button className="w-75 mt-2" />
                            <Skeleton.Button className="w-100 mt-2" />
                          </div>
                        </div>
                        <div className="item col-lg-3 d-none d-lg-block">
                          <div className="product-box d-flex flex-column">
                            <Skeleton.Image className="w-100" />
                            <Skeleton.Button className="w-75 mt-2" />
                            <Skeleton.Button className="w-100 mt-2" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      </div>
    </>
  );
}

import { useState, useEffect } from "react"
import "./ProductReviews.css"
import Container from "./Container";
import Notiflix from "notiflix";
import PostApiCall from "../../helpers/Api";
import { useParams } from "react-router-dom";

export default function WriteAReview() {

	const [variantImage, setVariantImage] = useState(null)
	const [variantName, setVariantName] = useState(null)
	const [variantId, setVariantId] = useState(null)
	const [selectedVariant, setSelectedVariant] = useState([])
	const [productData, setProductData] = useState([])
	let { variantid } = useParams();


	useEffect(() => {
		getProductByVariant()
	}, [])

	const getProductByVariant = () => {
		Notiflix.Loading.Circle();

		PostApiCall.postRequest(
			{
				id: variantid,
			},
			"GetProductByVariant"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {

					setProductData(obj.data)
					Notiflix.Loading.Remove();
					for (var i = 0; i < Object.keys(obj.data).length; i++) {
						if (obj.data[i].fld_variantid == variantid) {
							setSelectedVariant(obj.data[i])
							setVariantName(obj.data[i].fld_variantname)
							setVariantId(obj.data[i].fld_variantid)
							setVariantImage(obj.data[i].VariantImage)
						}
					}
				}
			})
		);
	}

	return (
		<>
			<Container variantName={variantName} variantImage={variantImage} variantId={variantId} />
		</>
	)
}
